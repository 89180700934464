import React, { useState } from 'react';
import {
    Modal, Box, Card, Grid,
    FormControl, Button,
    // CircularProgress
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from 'notistack';
import PostRequest from '../apiConnections/postRequest';
import Loader from '../../Loader';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

export default function UploadVendorJSON({ open, handleClose, activeUrl, handleSuccess }) {
    const { enqueueSnackbar } = useSnackbar();
    const theme = localStorage.getItem("theme");
    const [loading, setLoading] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState(null)
    const [fileData, setFileData] = useState(null)
    const handleFileChange = async (e) => {
        e.preventDefault();
        setFileData();
        setSelectedFileName(null);
        let files = e.target.files[0];
        if (files) {
            let dataImage = new FormData();
            dataImage.append("user_file", e.target.files[0]);

            const checkImage = e.target.files[0];
            const fileExtension = checkImage.name.replace(/^.*\./, "");
            const fileSize = Math.round(checkImage.size / 1024);
            if (fileExtension === "json") {
                if (fileSize <= 20000) {
                    const fileReader = new FileReader();
                    fileReader.readAsText(checkImage, "UTF-8");
                    fileReader.onload = e => {
                        let result = e.target.result;
                        if (result) {
                            let jsonData = JSON.parse(result)
                            if (jsonData.domain !== activeUrl) {
                                enqueueSnackbar("file does not match to related vendor", {
                                    variant: "error",
                                });
                            } else {
                                setFileData(dataImage);
                                setSelectedFileName(checkImage.name);
                            }
                        }
                    };

                } else {
                    enqueueSnackbar("Please upload less than 2 mb file.", {
                        variant: "error",
                    });
                }
            } else {
                enqueueSnackbar("Please upload json file.", {
                    variant: "error",
                });
            }
        }
    };

    const do_upload = async (e) => {
        e.preventDefault();
        if (!fileData) {
            enqueueSnackbar("Please upload json file.", {
                variant: "error",
            });
            return;
        }
        setLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                let endPoint = process.env.REACT_APP_SAVE_VENDOR_BY_FILE;
                let temp = fileData;
                const uploadJson = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
                    temp,
                    "ImageUpload"
                );
                if (uploadJson && uploadJson.status === 200) {
                    if (uploadJson.data.code === 200) {
                        enqueueSnackbar("File uploaded successfully.", {
                            variant: "success",
                        });
                        setLoading(false);
                        setFileData(null);
                        setSelectedFileName(null);
                        handleSuccess()
                    } else {
                        enqueueSnackbar("File not uploaded, some keys are missing", {
                            variant: "error",
                        });
                        setLoading(false);
                    }
                } else {
                    enqueueSnackbar(uploadJson.data.message, { variant: "error" });
                    setLoading(false);
                }
            }
        } catch (e) {
            enqueueSnackbar("Error in upload file", { variant: "error" });
            setLoading(false);
        }
    };
    const closeModal = () => {
        setFileData(null)
        setSelectedFileName(null)
        handleClose()
    }
    return (
        <>
            <Loader show={loading} />

            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme === "Dark"
                        ? "dark-attack-modal modal-popup"
                        : "light-attack-modal modal-popup"
                }
            >
                <Box sx={style} className="black-bg">
                <ClearIcon className="cross-icon" onClick={closeModal}/>
                    <Card sx={{ width: "100%", height:"100%", display: "flex", alignItems: "center", justifyContent:"center" }} className="attackpart">
                        <Box>
                            <Grid container spacing={3} >
                                <Grid item xs={12} md={12} lg={12} >

                                    <FormControl >
                                        <input
                                            id="file-upload"
                                            type="file"
                                            accept=".json"
                                            onChange={(e) => handleFileChange(e)}
                                            style={{ display: "none" }}
                                        />
                                        <Button
                                            htmlFor="file-upload"
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            component="label"
                                            className="select-jeson"
                                        >
                                            + Select Json File
                                        </Button>
                                        <label className="save-btn-block">
                                            <b>
                                                {selectedFileName !== null ? "File : " : null}
                                            </b>
                                            {selectedFileName !== null
                                                ? selectedFileName
                                                : null}
                                        </label>

                                        {selectedFileName !== null ? (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                component="button"
                                                type="submit"
                                                className="save-file-btn"
                                                onClick={do_upload}
                                            >
                                                Proceed
                                                {/* {loading ? (
                                                    <CircularProgress size={12} sx={{ marginLeft: "15px" }} />
                                                ) : null} */}
                                            </Button>
                                        ) : null}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}
