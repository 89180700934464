import React, { useEffect, useState } from 'react'
import { Button, Card, Grid, Container, TextField, InputAdornment, Box, IconButton, Menu, MenuItem, FormControl, Select, } from '@mui/material';
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import GetRequest from '../components/apiConnections/getRequest';
import PostRequest from '../components/apiConnections/postRequest';
import QuestionnairePreview from './QuestionnairePreview';
import './css/addQuestionnaire.css'
import DeleteQuestionnaire from '../components/modal/deleteQuestionnaire';
import { DataGrid } from '@mui/x-data-grid';
import UploadQuestionnaireSet from '../components/modal/uploadQuestionnaireSet';
import QuestionnaireLogo from '../assets/questionnaire-image.png';
import UploadIcon from '../assets/upload-icon-new.svg';
import Add from '../assets/plus-vendor.svg';
import NewHeader from '../layouts/dashboard/header/NewHeader';
import SearchIcon from '../assets/searchico.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EyeMenu from '../assets/eye-menu.svg';
import Trash from '../assets/trahs.svg';
import moment from 'moment';
import { useSelector } from "react-redux";
import { check } from 'prettier';
import { CustomNoRowsOverlay } from './Assessment';

// Menu component for the three-dot menu
function RowMenu({ onViewClick, onDeleteClick }) {

  const [anchorEl, setAnchorEl] = useState(null);
  let selector = useSelector((state) => state.permissionList.permissionList);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector.includes(value)) return true;
    else return false;
  }

  return (
    <>
      <IconButton onClick={handleClick} className='three-dots-btn'>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className='rowmenu'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {checkPermission("View Questionnaire") ? <MenuItem onClick={onViewClick} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem> : null}

        {checkPermission("Delete Questionnaire") ? <MenuItem onClick={onDeleteClick}> <img src={Trash} /> Remove</MenuItem> : null}
      </Menu>

    </>
  );
}

export default function QuestionanrieLibary() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useState(false)
  const [questionnaireSets, setQuestionnaireSets] = useState([])
  const [page, setPage] = useState(0);
  const [isNotFound, setIsNotFound] = useState(false);
  const [activeQuestionnaireSet, setActiveQuestionnaireSet] = useState({})
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showDeleteQuestionnaireModal, setShowDeleteQuestionnaireModal] = useState(false)
  const [rows, setRows] = useState([])
  const [showQuestionnaireImportModal, setShowQuestionnaireImportModal] = useState(false)
  const [userEmail, setuserEmail] = useState(localStorage.getItem("userEmail"));
  let selector = useSelector((state) => state.permissionList.permissionList);
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows);
  const [pageSize, setPageSize] = useState(5);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const columnsNew = [
    {
      field: 'id',
      headerName: '#',
      flex: 0.5,
      className: 'serial-number',
      disableColumnMenu: true,
    },
    {
      field: 'set',
      headerName: 'Name',
      flex: 1.5,
      minWidth: 170,  // Ensure minimum width
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          {params.row?.logo ? <span className="logo-image-vendor"><img className="attack-surface-list-logo" src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${params.row?.logo}`} onError={(e) => {
            e.target.onerror = null;
            e.target.src = QuestionnaireLogo;
          }} alt='logo' /></span>
            : <span className="logo-image-vendor"><img className="attack-surface-list-logo" src={QuestionnaireLogo} alt='logo' /></span>
          }
          <span className='dots-words-limit' style={{ display: 'flex', gap: '6px' }}>
            {params.value}
          </span>
        </div>
      ),
    },
    {
      field: 'status', headerName: 'Status', flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: () =>
        <span className="onboard-green stroke">Publish</span>
    },
    {
      field: 'createdAt', headerName: 'Date Created',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return moment(params.value).format("DD MMM YYYY");
      }
    },
    {
      field: 'userEmail', headerName: 'Created By',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: () => <>{userEmail}</>
    },
    {
      field: 'totalCategories', headerName: 'Total Categories',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <>{params.value}</>
    },
    {
      field: 'menu1', headerName: '',
      flex: 0.5,
      disableColumnMenu: true,
      align: "right",
      renderCell: (params) => <RowMenu onViewClick={() => configureQuestionnaireSet(params.row)} onDeleteClick={() => configureDeleteQuestionnaireSet(params.row)} />
    },
  ];

  // useEffect(() => {
  //   getQuestionnaireSet(1, )
  // }, [])
  useEffect(() => {
    getQuestionnaireSet(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (searchText !== null) getQuestionnaireSet(1, paginationModel.pageSize);
    }, 500)
    return () => clearTimeout(time)
  }, [searchText])

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filtered = rows.filter((row) =>
      Object.values(row).some((field) =>
        String(field).toLowerCase().includes(value)
      )
    );

    setFilteredRows(filtered);
  };

  const getQuestionnaireSet = async (page, limit) => {
    setShowLoader(true);
    setRows([])
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_CUSTOM_QUESTIONNAIRE_SET_LIST}?page=${page}&limit=${limit}&search=${searchText || ""}`,
          {}
        )
          .then((resChart) => {
            let data = resChart?.data?.data?.result;
            setIsNotFound(!(data.length > 0));
            // if (!data.length) return;

            setQuestionnaireSets([...data?.map((d) => ({ ...d, totalCategories: d.setCategories?.length || 0 }))])
            // enqueueSnackbar('Questionnaire set added successfully', { variant: 'success' });
            setRows([...data?.map((d, i) => ({ ...d, id: i + 1, totalCategories: d.setCategories?.length || 0 }))])
            setShowLoader(false);
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  };

  const handleAddQuestionnaireClick = () => {
    navigate('./add-questionnaire');
  };
  const handleEditClick = () => {
    setShowPreviewModal(false);
  };
  const handleRowClick = (params) => {
    configureQuestionnaireSet(params.row)
  }
  const configureQuestionnaireSet = (set) => {
    let arr = []
    set.setCategories?.forEach((data, i) => {
      let obj = {}
      obj.category = data?.category;
      obj.questions = data?.questions
      arr = [...arr, obj]
    })
    setActiveQuestionnaireSet({ ...set, sections: arr })
    setShowPreviewModal(true)
  };

  const configureDeleteQuestionnaireSet = (set) => {
    setActiveQuestionnaireSet({ ...set })
    setShowDeleteQuestionnaireModal(true)
  };
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector.includes(value)) return true;
    else return false;
  }

  const onFilterChange = (value) => {
    let searchQuery = value.quickFilterValues[0] || ""
    setSearchText(searchQuery)
  }

  return (
    <>
      {/* <Loader show={showLoader} /> */}
      <Helmet>
        <title> Questionnaire | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Questionnaire library" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <Card className="full-coverdesign margin-questionnaire">
              <div className="cover-table">
                {/* <QuestionnaireTable/> */}
                {/*New Table Static*/}
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '6px', left: '8px' }}>
                  <FormControl className='select-entries' variant="outlined" size="small">
                    <Select
                      value={paginationModel.pageSize}
                      onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                  <span className='entries-per'>entries per page</span>
                </div>
                <div className='question-buttons'>

                  {checkPermission("Add Questionnaire") &&
                    <Button
                      sx={{ marginRight: "0px" }}
                      variant="contained"
                      className="add-questionnaire-button"
                      // startIcon={<Iconify icon="material-symbols:upload" />}
                      onClick={() => setShowQuestionnaireImportModal(true)}
                    >
                      <img src={UploadIcon} alt='icon' style={{ marginRight: "13px" }} />  Upload Questionnaire
                    </Button>
                  }

                  {checkPermission("Add Questionnaire") &&
                    <Button
                      variant="contained"
                      className="add-questionnaire-button"
                      // startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={handleAddQuestionnaireClick}
                    >
                      <img src={Add} alt='icon' style={{ marginRight: "13px" }} /> Add Questionnaire
                    </Button>
                  }

                </div>
                <Box
                  sx={{
                    width: '100%',
                    '& .MuiDataGrid-columnHeaders': {
                      minWidth: '100%',
                      overflowX: 'hidden',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      minWidth: '100%',
                      overflowX: 'auto',
                    },
                  }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Search…"
                    value={searchText}
                    onChange={handleSearch}
                    className='searh-text-field'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt='icon' />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <DataGrid
                    loading={showLoader}
                    rows={rows} // Ensure you're using the filtered rows
                    columns={columnsNew}
                    pageSize={pageSize}
                    rowHeight={70}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    onPaginationModelChange={handlePaginationModelChange}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    disableSelectionOnClick
                    autoHeight
                    className='datagrid-table click-hover-datagrid'
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        minWidth: '100%', // Column headers take up full width
                      },
                      '& .MuiDataGrid-virtualScroller': {
                        minWidth: '100%', // Rows take up full width
                      },
                    }}
                    onRowClick={handleRowClick}
                  />
                </Box>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {checkPermission("View Questionnaire Detail") && showPreviewModal ? <QuestionnairePreview
        open={showPreviewModal}
        handleClose={() => setShowPreviewModal(false)}
        onClose={handleEditClick}
        sections={activeQuestionnaireSet.sections}
        description={activeQuestionnaireSet.description}
        title={activeQuestionnaireSet.set}
        showSubmit={false}
      /> : null}
      {checkPermission("Delete Questionnaire") && showDeleteQuestionnaireModal ? <DeleteQuestionnaire
        open={showDeleteQuestionnaireModal}
        handleClose={() => setShowDeleteQuestionnaireModal(false)}
        onClose={() => setShowDeleteQuestionnaireModal(false)}
        setId={activeQuestionnaireSet.setId}
        handleSuccess={() => getQuestionnaireSet(paginationModel.page + 1, paginationModel.pageSize)}
      /> : null}
      {showQuestionnaireImportModal ? <UploadQuestionnaireSet
        open={showQuestionnaireImportModal}
        handleClose={() => setShowQuestionnaireImportModal(false)}
        onClose={() => setShowQuestionnaireImportModal(false)}
        handleSuccess={() => { setShowQuestionnaireImportModal(false); getQuestionnaireSet() }}
      /> : null}
    </>
  )
}
