import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import { DashboardLayout, DashboardLayoutAdmin } from "./layouts/dashboard";

// pages
import ProtectedRoute from "./components/gurad/ProtectedRoute.js";
import AddFilePage from "./pages/AddFilePage";
import AddQuestionnaire from "./pages/AddQuestionnaire .jsx";
import Assessment from "./pages/Assessment.js";
import AttackDetailPage from "./pages/AttackDetailPage";
import AttackLandingAdminPage from "./pages/AttackLandingAdminPage";
import AttackLandingPage from "./pages/AttackLandingPage";
import AttackSurface from "./pages/AttackSurface";
import BlogPage from "./pages/BlogPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import Chat from "./pages/Chat.js";
import ClientChatBoard from "./pages/ClientChatBoard";
import ConfirmAccountPage from "./pages/ConfirmAccountPage";
import CveDetailOverlay from "./pages/CveDetailOverlay.js";
import DashboardAppPage from "./pages/DashboardAppPage";
import ExportDetail from "./pages/ExportDetailsPage";
import ExportExecutive from "./pages/ExportExecutivePage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import LoginPage from "./pages/LoginPage";
import ProductsPage from "./pages/ProductsPage";
import ProfilePage from "./pages/ProfilePage";
import Questionnaire from "./pages/Questionnaire";
import QuestionnaireImport from "./pages/QuestionnaireImport.js";
import QuestionnaireInformation from "./pages/QuestionnaireInformation";
import QuestionnaireLibrary from "./pages/QuestionnaireLibrary";
import QuestionPreview from "./pages/QuestionnairePreview.js";
import QuestionnaireSubmitted from "./pages/QuestionnaireSubmitted";
import RegistrationPage from "./pages/RegistrationPage";
import Remediation from "./pages/Remediation";
import AttackRequestPage from "./pages/RequestsAttackPage";
import RequestsPage from "./pages/RequestsPage";
import RequestUrlPage from "./pages/RequestUrlPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SecurityControlPage from "./pages/SecurityControlPage";
import SubDomainIssuesDetailPage from "./pages/SubDomainIssuesDetailPage";
import SubDomainIssuesListPage from "./pages/SubDomainIssuesListPage";
import TwoFaPage from "./pages/TwoFaPage";
import UpdateQuestionnaire from "./pages/UpdateQuestionnaire.jsx";
import UserPage from "./pages/UserPage";
import UserRemediationInformationPage from "./pages/UserRemediationInformationPage";
import ComplianceCard from "./pages/vendor/ComplianceCard.jsx";
import ComplianceList from "./pages/vendor/ComplianceList.jsx";
import VendorAssessmentsPage from "./pages/vendor/VendorAssessmentsPage.js";
import VendorChatBoard from "./pages/VendorChatBoard";
import VendorDashboard from "./pages/VendorDashboard";
import VendorDetailsPage from "./pages/VendorDetail/VendorDetailsPage.js";
import VendorEditPage from "./pages/VendorEditPage";
import VendorInformation from "./pages/VendorInformation";
import VendorRemediationPage from "./pages/VendorRemediationPage";
import VendorsPage from "./pages/VendorsPage";
import ViewAnswerPage from "./pages/ViewAnswerPage";
import ClientsManagementPage from "./pages/ClientsManagementPage.js";
import AddEditClient from './pages/add-client/index';
import ClientConfirmAccountPage from "./pages/ClientConfirmAccount.jsx";
import ClientDetailsPage from './pages/clientsDetailPage.js';
import AddEditUserPage from "./pages/AddEditUserPage.js";
import GlobalFullSearchInputPage from "./pages/globalFullSearchInputPage.js";
import AddNewVendorByClient from "./pages/AddNewVendorByClient";
import ClientMyAccount from './pages/clientMyAccount.js';
import ProfileViewPage from "./pages/profileView.js";
import UpdateClientProfile from "./pages/clinetUpdateProfile.js";
import VendorDetailAnswer from "./pages/VendorDetail/VendorDetailAnswer.js";
import RemediationDetails from "./pages/VendorDetail/RemediationDetails.js";
import ControlsDetails from "./pages/VendorDetail/ControlsDetails.js";
import ComplianceBarChart from "./pages/VendorDetail/ComplianceBarChart.js";
import Notification from "./pages/notification/Notification.jsx";
import VendorEcosystemPageAdmin from "./pages/VendorEcosystemPageAdmin.js";
import VendorDetailsPageAdmin from "./pages/admin/VendorDetailsPageAdmin.js";
import GenesisAiSinglePage from "./pages/GenesisAiSinglePage.js";

// ----------------------------------------------------------------------

function NotFoundUrl() {
  // 👇️ redirect to external URL
  window.location.replace(`${process.env.REACT_APP_NOT_FOUND_URL}`);

  return null;
}

export default function Router({ toggleTheme, theme, isShowSearch }) {
  let isLoggedIn =
    localStorage.getItem("R1") && localStorage.getItem("roleId") === "1"
      ? true
      : false;
  let isLoggedInAdmin =
    localStorage.getItem("R2") && localStorage.getItem("roleId") === "2"
      ? true
      : false;
  let isLoggedInVendor =
    localStorage.getItem("R3") && localStorage.getItem("roleId") === "3"
      ? true
      : false;

  const routes = useRoutes([
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <DashboardLayout
            toggleTheme={() => toggleTheme()}
            theme={theme}
            isShowSearch={isShowSearch}
          />
        </ProtectedRoute>
      ),
      children: [
        { path: "app", element: <DashboardAppPage /> },
        { path: "vendors", element: <VendorsPage /> },
        { path: "user", element: <UserPage /> },
        // { path: "vendorDetails/:url", element: <VendorDetailsPage /> },
        { path: "requestUrl", element: <RequestUrlPage /> },
        { path: "products", element: <ProductsPage /> },
        { path: "blog", element: <BlogPage /> },
        { path: "addfile", element: <AddFilePage /> },
        { path: "profile", element: <ProfilePage /> },
        { path: "clientChat", element: <ClientChatBoard theme={theme} /> },
        { path: "cve-detail", element: <CveDetailOverlay /> },
        { path: "change-password", element: <ChangePasswordPage /> },
        { path: "attacks", element: <AttackLandingPage /> },
        { path: "chat-app", element: <Chat /> },
        { path: "assessment", element: <Assessment /> },
        { path: "questionnaire-preview", element: <QuestionPreview /> },
        { path: "questionnaire-library", element: <QuestionnaireLibrary /> },
        { path: "genesis-ai", element: <GenesisAiSinglePage/> },
        { path: "global-full-search-input", element: <GlobalFullSearchInputPage /> },
        { path: "add-new-vendor", element: <AddNewVendorByClient /> },
        { path: "compliance-chart", element: <ComplianceBarChart /> },
        { path: "vendor-answer/:id", element: <VendorDetailAnswer /> },
        { path: "remediation-details", element: <RemediationDetails/> },
        { path: "vendorDetails/:url", element: <VendorDetailsPage /> },
        {
          path: "questionnaire-library/add-questionnaire",
          element: <AddQuestionnaire />,
        },
        {
          path: "questionnaire-library/edit-questionnaire",
          element: <UpdateQuestionnaire />,
        },
        {
          path: "questionnaire-information",
          element: <QuestionnaireInformation />,
        },
        { path: "attack-detail/:url", element: <AttackDetailPage /> },
        { path: "vendor-information/:url", element: <VendorInformation /> },
        { path: "editVendorDetails/:id/:url", element: <VendorEditPage /> },
        {
          path: "user-remediation-information/:url",
          element: <UserRemediationInformationPage />,
        },
        {
          path: "questionnaire/:url",
          element: <Questionnaire theme={theme} />,
        },
        { path: "attack-surface", element: <AttackSurface /> },
        {
          path: "export-executive/:url",
          element: <ExportExecutive theme={theme} />,
        },
        { path: "export-detail/:url", element: <ExportDetail theme={theme} /> },
        { path: "all-attack-requests", element: <AttackRequestPage /> },
        {
          path: "sub-domain-issues/:url/:subdomain",
          element: <SubDomainIssuesListPage />,
        },
        {
          path: "sub-domain-issues/:url/:subdomain/:issueid",
          element: <SubDomainIssuesDetailPage />,
        },
        { path: "view-answer-page/:id", element: <ViewAnswerPage /> },
        { path: "security-control/:id", element: <SecurityControlPage /> },
        { path: "my-account", element: <ClientMyAccount /> },
        {
          path: "add-user",
          element: <AddEditUserPage />,
        },
        {
          path: "edit-user/:id",
          element: <AddEditUserPage />,
        },
        {
          path: "profile-detail",
          element: <ProfileViewPage />,
        },
        {
          path: "update-profile",
          element: <UpdateClientProfile/>,
        },
        { path: "failed-control-detail", element: <ControlsDetails /> },
      ],
    },
    {
      path: "/vendor",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedInVendor}>
          <DashboardLayout
            toggleTheme={() => toggleTheme()}
            theme={theme}
            isShowSearch={isShowSearch}
          />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/vendor/attacks" />, index: true },
        { path: "dashboard", element: <VendorDashboard /> },
        { path: "addFile", element: <AddFilePage /> },
        { path: "profile", element: <ProfilePage /> },
        { path: "change-password", element: <ChangePasswordPage /> },
        { path: "attack-detail/:url", element: <AttackDetailPage /> },
        { path: "all-attack-requests", element: <AttackRequestPage /> },
        {
          path: "sub-domain-issues/:url/:subdomain",
          element: <SubDomainIssuesListPage />,
        },
        {
          path: "sub-domain-issues/:url/:subdomain/:issueid",
          element: <SubDomainIssuesDetailPage />,
        },
        { path: "AttackSurface/:url", element: <AttackSurface /> },
        { path: "vendorAssessment", element: <VendorAssessmentsPage /> },
        { path: "vendorRemediation", element: <VendorRemediationPage /> },
        { path: "vendorChat", element: <VendorChatBoard theme={theme} /> },
        { path: "remediation-plan-data/:url", element: <Remediation /> },
        {
          path: "questionnaireSubmitted/:id",
          element: <QuestionnaireSubmitted />,
        },
        { path: "compliance", element: <ComplianceList /> },
        { path: "compliance-card/:id?", element: <ComplianceCard /> },
        { path: "remediation-details", element: <RemediationDetails/> },
      ],
    },
    {
      path: "/admin",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedInAdmin}>
          <DashboardLayoutAdmin
            toggleTheme={() => toggleTheme()}
            theme={theme}
          />
        </ProtectedRoute>
      ),
      children: [
        { path: "addFile", element: <AddFilePage /> },
        { path: "questionnaire-import", element: <QuestionnaireImport /> },
        { path: "vendors", element: <VendorEcosystemPageAdmin /> },
        { path: "requests", element: <RequestsPage /> },
        { path: "vendorDetails/:url", element: <VendorDetailsPageAdmin /> },
        { path: "profile", element: <ProfilePage /> },
        { path: "change-password", element: <ChangePasswordPage /> },
        { path: "attacks", element: <AttackLandingAdminPage /> },
        { path: "attack-detail/:url", element: <AttackDetailPage /> },
        {
          path: "export-executive/:url",
          element: <ExportExecutive theme={theme} />,
        },
        { path: "export-detail/:url", element: <ExportDetail theme={theme} /> },
        { path: "all-attack-requests", element: <AttackRequestPage /> },
        {
          path: "sub-domain-issues/:url/:subdomain",
          element: <SubDomainIssuesListPage />,
        },
        {
          path: "sub-domain-issues/:url/:subdomain/:issueid",
          element: <SubDomainIssuesDetailPage />,
        },
        {
          path: "clients-management",
          element: <ClientsManagementPage />,
        },
        {
          path: "client/add",
          element: <AddEditClient />,
        },
        {
          path: "client/edit/:id",
          element: <AddEditClient />,
        },
        {
          path: "client-details/:id",
          element: <ClientDetailsPage />,
        },
        {
          path: "client-details/add-user",
          element: <AddEditUserPage />,
        },
        {
          path: "client-details/edit-user/:id",
          element: <AddEditUserPage />,
        },
        {
          path: "add-vendor",
          element: <AddNewVendorByClient />,
        },
        {
          path: "notification",
          element: <Notification />,
        }
      ],
    },
    {
      path: "hexsiejsms",
      element: <RegistrationPage toggleTheme={() => toggleTheme()} />,
    },
    { path: "/", element: <LoginPage toggleTheme={() => toggleTheme()} /> },
    { path: "twofa", element: <TwoFaPage /> },
    { path: "forget-password", element: <ForgetPasswordPage /> },
    { path: "confirm-account/:token", element: <ConfirmAccountPage /> },
    { path: "reset-password/:token", element: <ResetPasswordPage /> },
    { path: "active-account/:token", element: <ClientConfirmAccountPage /> },
    { path: '*', element: <NotFoundUrl /> },
  ]);

  return routes;
}
