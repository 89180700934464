import React from 'react'
import Box from '@mui/material/Box';
import './AILoader.css';
import GIF from "./assets/ANIMATION GENESIS.gif";
export default function AILoader({show}) {
  return (
    (show === true) ?
        <div className="loader" show>
         <Box sx={{ display: 'flex' }}>
          <img src={GIF}/>        
        </Box>
        </div>
        : null
  )
}
