import * as React from "react";
import Drawer from "@mui/material/Drawer";
import redirectIcon from "../../assets/redirect-icon.svg";
import {
  Box,
} from "@mui/material";

function IssueSideBar({
  handleClose,
  open,
  issueData
}
) {
  const DrawerList = (
    <Box
      sx={{ width: 800 }}
      className="drawer-padding"
      role="presentation"
    >
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Name</div>
        </div>
        <p>{issueData?.name}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Description</div>
        </div>
        <p>{issueData?.ele?.Description}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Remediation</div>
        </div>
        <p>{issueData?.ele?.Remediation}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>References</div>
        </div>
        {issueData?.ele?.Reference?.map((link, index) => (
          <p key={index} style={{ display: 'flex' }}>
            #{index + 1}.{"    "}
            <a href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '30px', display: 'flex', color: "white", flexDirection: 'row', flexWrap: 'wrap',width:"100%"  }}>
              <div style={{ marginLeft: '30px', display: 'flex', color: "white" ,marginRight: '1%', wordBreak:'break-all'}}>
                <div>{link} <img src={redirectIcon} alt='icon' style={{marginLeft:'8px',position:'relative', top:'7px'}}></img></div>
              </div>
             
            </a>

          </p>
        ))}
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Affected URls  </div>
        </div>
        {issueData?.urls?.map((link, index) => (
          <p key={index} style={{ display: 'flex' }}>
            #{index + 1}.{"    "}
            <a href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '30px', display: 'flex', color: "white", flexDirection: 'row', flexWrap: 'wrap',width:"100%" }}>
              <div style={{ marginLeft: '30px', display: 'flex', color: "white",marginRight:'1%' }}>
                {link}
              </div>
              <img src={redirectIcon} alt='icon'></img>
            </a>
          </p>
        ))}
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleClose}
        ModalProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

export default IssueSideBar;
