import React, { useEffect, useRef, useState } from "react";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconAlert from '../assets/icon-alert.svg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { TableContainer, Table, Button, FormControl, FormControlLabel, TableHead, TableBody, TableRow, TableCell, Box, Checkbox, Tooltip } from '@mui/material';
import DnsImage from '../assets/dns-image.svg';
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import redirectIcon from "../assets/redirect-icon.svg";
import AlertNew from "../assets/alert-new.svg";
import MappedIssuePopup from "./MappedIssuePopup";


function ViewAnswerSidebar({ data, issueMapped, detailedMapping }) {
  // console.log("data" , data)
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(0); // State to track the active tab
  const drawerRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [selectedIssue, setSelectedIssue] = useState({})
  const [openSidebar, setOpenSidebar] = React.useState(false);

  const handleRowClick = (params) => {
    setSelectedIssue(params)
    setOpenSidebar(true)
  };

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue); // Set the new active tab
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setOpen(false); // Close the drawer
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup event listener
    };
  }, [open]);

  const extractInfoFromRemark = (remark) => {
    // console.log("remark" , remark)
    if (!remark) {
      return {
        remark_text: null,
        document_name: null,
        page_number: null,
        reference: null
      };
    }

    const remarkTextMatch = remark.match(/(.*?)(?=Document Name:)/s) || [remark];
    const docNameMatch = remark.match(/Document Name:\s*(.+)\n?/);
    const page_numberMatch = remark.match(/Page number:\s*(\d+)\n?/);
    const referenceMatch = remark.match(/Reference:\s*(.+)/);

    return {
      remark_text: remarkTextMatch ? remarkTextMatch[0].trim() : null,
      document_name: docNameMatch ? docNameMatch[1].trim() : null,
      page_number: page_numberMatch ? page_numberMatch[1].trim() : null,
      reference: referenceMatch ? referenceMatch[1].trim() : null
    };
  };

  const { remark_text, document_name, page_number, reference } = extractInfoFromRemark(data?.remark);

  const filteredDetailedMapping = !detailedMapping
    ? null
    : {
      score: detailedMapping.passed == 0 ? "Failed" : "Passed",
      category: detailedMapping?.category || "",
      ...detailedMapping,  // Retains the existing structure, including `que` and `aiEvidence`
      text: detailedMapping?.que?.text || "",  // Adds specific new properties
      vulnerabilityType: detailedMapping?.que?.vulnerabilityType || "",
      riskMapping: detailedMapping?.que?.riskMapping || "",
      uniqueId: detailedMapping?.que?.uniqueId || "--"
    };

  const handleDetailedMappingClick = () => {
    navigate(`/dashboard/failed-control-detail`, { state: { Data: filteredDetailedMapping, mainTabValue: 4, nestedTabValue: 1 } })
  }
  const DrawerList = (
    <Box className="drawerlist" sx={{ width: 800 }} ref={drawerRef} role="presentation">
      <Box sx={{ width: '100%' }}>
        {/* Tabs */}
        <Box sx={{ marginBottom: "70px" }}>
          <div style={{ display: 'flex' }}>
            <p className="preview-question-heading">Q.1</p>
            <span className="data-text-preview">
              {data?.text}
            </span>
          </div>
          {/* passed icon  */}
          <div className="contradiction-status">
            <div className="flexstatus-side">
              <div className="status-right-lines">
                <p>Status</p>
                {data.ans !== "" && (
                  <Button
                    id={
                      data.passed === 1
                        ? "passed-color-button"
                        : "failed-color-button"
                    }
                    variant="contained"
                    size="small"
                    className="passed-failed-button"
                    style={{ cursor: "default" }}
                  >
                    {data.passed === 1 ? "Passed" : "Failed"}
                  </Button>
                )}
              </div>
              <div>
                <p>Mapped</p>
                <span className="isa-text">
                  <Tooltip title={data?.iso27001ControlDetails?.description} placement="top" followCursor>
                    <span>{data?.iso27001Control}</span>
                  </Tooltip>
                  <Tooltip title={data?.gdprDetails?.articleTitle} placement="top" followCursor>
                    <span> {data?.gdpr}</span>
                  </Tooltip>
                  <Tooltip title={data?.nistDetails?.controlName} placement="top" followCursor>
                    <span> {data?.nist}</span>
                  </Tooltip>
                </span>
              </div>
              <div style={{ display: "flex", gap: "11px", alignItems: "center", cursor: "pointer" }} onClick={handleDetailedMappingClick}>
                <span className="isa-text"> Detailed Mapping </span>
                <img src={redirectIcon} style={{ cursor: "pointer" }} alt='icon' />
              </div>
            </div>
          </div>
        </Box>
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} className="mapping-tab-heading" aria-label="basic tabs example">
            <Tab className="active-color-remove" label="Mapped Issue" />
            <Tab className="active-color-remove" label="Evidence" />
            <Tab className="active-color-remove" label="Contradiction" />
          </Tabs>
        </Box>

        {/* Tab content */}
        <Box className="bordertab-none">
          {/* Content for the first tab*/}
          {value === 0 && (
            <>
              {/* {console.log("data?.issueName" , issueMapped)} */}
              {issueMapped && issueMapped.length > 0 ?
                <>
                  <div className="mapping-issue">
                    <img src={IconAlert} alt="icon" /> Issue Mapped
                  </div>
                  <Box className="table-top-map" sx={{ overflowX: 'auto' }}> {/* To handle horizontal scroll on small screens */}
                    <TableContainer>
                      <Table sx={{ minWidth: '100%' }} aria-label="responsive table">
                        <TableHead className="table-header-mapping">
                          <TableRow>
                            <TableCell>Issue</TableCell>
                            <TableCell align="left">Category</TableCell>
                            <TableCell align="left">Severity</TableCell>
                            <TableCell align="left">Issue</TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {issueMapped && issueMapped.length > 0 ? (
                            issueMapped?.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{row?.Issue}</TableCell>
                                <TableCell>
                                  <div className="dns-box">
                                    <img src={DnsImage} alt="icon" />
                                    {row?.category}
                                  </div>
                                </TableCell>
                                <TableCell align="left" className="button-high-low">
                                  {row?.Severity}
                                </TableCell>
                                <TableCell align="left">{row?.riskScore ?? "- "}</TableCell>
                                <TableCell align="left">
                                  <a href={row?.Data}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div>
                                      <img src={redirectIcon} alt='icon' style={{ cursor: "pointer", marginLeft: '8px', position: 'relative', top: '7px' }} onClick={() => handleRowClick(row)}></img>
                                    </div>
                                  </a>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={5} align="center" style={{ textAlign: 'center', verticalAlign: 'middle', padding: '20px' }}>
                                No issues available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
                :
                <>
                  <span className="no-evidence-available">
                    No Mapped Issue
                  </span>
                </>
              }
            </>
          )}

          {/* Content for the second tab*/}
          {value === 1 && (
            <>
              {/* {(data?.answer && remark_text) ? */}
              {(data?.selectedOptions && remark_text) ?
                <>
                  {/* <div className="question-mapping" style={{ display: 'flex' }}>
                    <p className="preview-question-heading">Q.1</p>
                    <span className="data-text-preview">
                      {data?.text}
                    </span>
                  </div> */}
                  {remark_text &&
                    <>
                      {data?.optionType === "MCQ" ?
                        <FormControl>
                          {data?.options?.map((option, j) => {
                            //  const isChecked = data?.selectedOptions?.some(
                            //   selected => selected.title === option.title
                            // );
                            return (
                              <FormControlLabel
                                value={j + 1}
                                control={
                                  // <Checkbox
                                  //   checked={isChecked} // Checks if the option is in selectedOptions
                                  // />
                                  <Checkbox
                                    checked={data.answer?.includes(
                                      `${j + 1}`
                                    )}
                                  />
                                }
                                label={option.title}
                                disabled
                              // onChange={(e) =>
                              //   questionnaireAnswerConfig(e, i)
                              // }
                              // disabled={submitted ? true : false}
                              />
                            );
                          })}
                        </FormControl>
                        :
                        <>
                          <FormControl className="form-group-radiotype" >
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              defaultValue={data?.answer}
                            // defaultValue={data?.selectedOptions?.[0]?.title} 
                            >
                              {data?.options?.map((option, j) => {
                                return (
                                  <FormControlLabel
                                    value={option.title}
                                    control={<Radio />}
                                    label={option.title}
                                    className="form-control-labels"
                                    // onChange={(e) =>
                                    //   readRadioButton(e, i)
                                    // }
                                    disabled={true}
                                  />
                                );
                              })}
                            </RadioGroup>
                          </FormControl>
                        </>
                      }

                      <div className="evidence-mapped">Evidence Mapped</div>
                      <div className="evidence-map-questions">
                        <div className="question-mapping" style={{ display: 'flex' }}>
                          <p className="preview-question-heading">E.1</p>
                          <span className="data-text-preview">
                            {remark_text || "No remark provided"}
                          </span>
                        </div>
                        <div className="flex-views-btn">
                          <div className="flexpdf-page">
                            {/* <span>{document_name}</span> */}
                            <span>{data?.document_name}</span>

                            <span style={{ marginLeft: "23px" }}>{data?.page_number && (
                              <>
                                Page {data?.page_number}
                              </>
                            )}</span>
                          </div>
                          {/* {console.log(data.documentName, "llllllllllllllllllllllll")} */}
                          {data?.documentName &&
                            <Link
                              to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_OPEN_VENDOR_DOCUMENT}/${data?.documentName || document_name}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button variant="contained" className="viewbutton-map">View </Button>
                            </Link>
                          }
                        </div>
                      </div>
                    </>
                  }
                </>
                :
                <>
                  <span className="no-evidence-available">
                    No Evidence Available
                  </span>
                </>
              }
            </>
          )}

          {value === 2 && (
            <>
              {/* {console.log("data?.issueName" , issueMapped)} */}
              {data && data.aiEvidence ?
                <>
                  <div className="response-belt">
                    Response
                  </div>
                  <div className="full-dataaievidence">
                    <p>{data?.aiEvidence?.vendorResponse}</p>
                  </div>
                  <div className="contradiction-found">
                    <img src={AlertNew} width={25} alt="icon" /> Contradiction Found
                  </div>
                  <div className="contradiction-found-innerbox">
                    <span>
                      {data?.aiEvidence?.identifiedEvidence.replace(/【.*?】/g, '')}<br></br>
                      {data?.aiEvidence?.recommendedAction}

                    </span>
                  </div>
                  {data?.aiEvidence?.documentReferenced &&
                    <div className="flex-views-btn">
                      <div className="flexpdf-page" style={{ marginLeft: "0px", }}>
                        <span style={{ marginLeft: "0px", borderRight: "1px" }}
                        >{data?.aiEvidence?.documentReferenced}</span>
                      </div>
                      {data?.aiEvidence?.documentReferenced?.includes(".pdf") && <Link
                        to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_OPEN_VENDOR_DOCUMENT}/${data?.aiEvidence?.documentReferenced}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="contained" className="viewbutton-map">View </Button>
                      </Link>
                      }
                    </div>
                  }


                </>
                :
                <>
                  <span className="no-evidence-available">
                    No Contradiction
                  </span>
                </>
              }
            </>
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Loader show={showLoader} />
      {DrawerList}
      <MappedIssuePopup
        issueData={selectedIssue}
        handleClose={() => setOpenSidebar(false)}
        open={openSidebar}
      />
    </div>
  );
}

export default ViewAnswerSidebar;
