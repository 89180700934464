import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import "./css/Vendors.css";
import "./css/Attack.css";
// @mui
import {
  Card,
  MenuItem,
  Container,
  Typography,
  Box,
  FormControl,
  Select,
  IconButton,
  Menu,Grid,
} from "@mui/material";
import PostRequest from "../components/apiConnections/postRequest";
import { useSnackbar } from "notistack";
import ModalDialog from "./ModalDialog.js";
import Delete from "../assets/delete.svg";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "./Assessment.js"
import { useNavigate } from "react-router-dom";
import EyeMenu from '../assets/eye-menu.svg';
import Trash from '../assets/trahs.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// ----------------------------------------------------------------------
export default function UserPage() {
  const navigate = useNavigate()
  const [vendorsData, setVendorsData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setshowLoader] = useState(false);
  let roleId = parseInt(window.localStorage.getItem('roleId'));
  const [userRemoveId, setUserRemoveId] = useState(0);
  const [openUser, setOpenUser] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  useEffect(() => {
    setOpenUser((userRemoveId !== 0) ? true : false);
  }, [userRemoveId])

  const handleOpen = (url) => {
    setUserRemoveId(url);
  };
  const handleClose = () => {
    setOpenUser(false)
    setUserRemoveId(0);
  };

  useEffect(() => {
    getVendorDetails();
  }, []);

  useEffect(() => { }, [vendorsData]);

  const getVendorDetails = async () => {
    setshowLoader(true);
    const endpoint = process.env.REACT_APP_GET_ALL_ATTACK_LIST
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setVendorsData(arrResponse);
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }
  };

  const removeData = async (url) => {
    const deleteId = userRemoveId;

    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = process.env.REACT_APP_DELETE_ATTACK;
      const payload = {
        requestAttackUrl: deleteId
      }
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: 'success' });
          setshowLoader(false);
          window.localStorage.removeItem('deleteUrl');
          handleClose();
          setTimeout(() => {
            window.location.reload(true)
          }, 1000)
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: 'error' });
          setshowLoader(false);
        })
    }


  };

  const ModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h2 className="send-assessments">Are you sure want to remove assets ?</h2>
      </Typography>
    );
  };
  // Menu component for the three-dot menu
  function RowMenu({ data, handleRemove, handleView }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={handleClick} className='three-dots-btn'>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className='rowmenu'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        >
          <MenuItem onClick={handleView} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem>
          {(roleId === 2) ?
            <MenuItem onClick={handleRemove}> <img src={Trash} /> Remove</MenuItem> : ''
          }
        </Menu >
      </>
    );
  }
  const filteredRows =
    (!vendorsData || !vendorsData.length) ? [] :
      vendorsData
        ?.map((val, i) => ({
          ...val,
          id: i + 1,
        })
        );

  const columns = [
    {
        field: "id",
        headerName: "#",
        flex: 1,
        className: "serial-number",
        disableColumnMenu: true,
    },
    {
        field: "name",
        headerName: "Name",
        flex: 1,
        disableColumnMenu: true,
    },
    {
        field: "score",
        headerName: "Score",
        flex: 1,
        disableColumnMenu: true,
    },
    {
        field: "action",
        headerName: "",
        flex: 1,
        renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>

            <RowMenu
                data={params.row}
                handleRemove={() => { handleOpen(params.row.name) }}
                handleView={() => {
                    handleRowClick(params);
                }}
          />
          </div>
        ),
    }
];

 
  const handleRowClick = (params) => {
    console.log(params.row.name, "gfdfgdfg")
    navigate(`../attack-detail/${params.row.name}`)
  };
  return (
    <>
     <Helmet>
        <title> Vendors | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Attack Surface" />
        <Grid container spacing={3} className="ipad-space">
        <Grid item xs={12} md={12} lg={12}>
         <Card className="full-coverdesign margin-questionnaire">
          <div className="cover-table">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                top: "4px",
                left: "5px",

              }}
            >
              <FormControl
                className="select-entries"
                variant="outlined"
                size="small"
              >
                <Select
                  value={paginationModel.pageSize}
                  onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <span className="entries-per">entries per page</span>{" "}
            </div>
            <div className="issues-tab-table">
              <Box
                sx={{
                  width: "100%",
                  "& .MuiDataGrid-columnHeaders": {
                    minWidth: "100%",
                    overflowX: "hidden",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minWidth: "100%",
                    overflowX: "auto",
                  },
                }}
              >

                <DataGrid
                  loading={loader}
                  rows={filteredRows}
                  columns={columns}
                  rowHeight={70}
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  components={{
                    NoRowsOverlay: () => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        No data
                      </Box>
                    ),
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [],
                      },
                    },
                  }}
                  autosizeOptions={{
                    columns: ["id", "firstName", "lastName"],
                    includeOutliers: true,
                    includeHeaders: false,
                  }}
                  pageSizeOptions={[5, 10, 25, 50]}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                  paginationMode="client"
                  disableSelectionOnClick
                  autoHeight
                  className="datagrid-table"
                  sx={{
                    "& .MuiDataGrid-columnHeaders": {
                      minWidth: "100%", // Column headers take up full width
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      minWidth: "100%", // Rows take up full width
                    },
                  }}
                  onRowClick={handleRowClick}
                />
              </Box>
            </div>
          </div>
          </Card>
        <ModalDialog
          handleClose={handleClose}
          open={openUser}
          onSubmit={removeData}
          contentData={ModalContent()}
          />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
