import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";

import { Grid, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function ModalDialog({
  handleClose,
  open,
  contentData,
  onSubmit,
  loader
}) {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [content, setContent] = useState(contentData);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    textAlign: "center",
    borderRadius: "15px",
    p: 4,
  };
  useEffect(() => {
    setInterval(() => {
      const themeName =
        localStorage.getItem("theme") !== null
          ? localStorage.getItem("theme")
          : "Dark";
      setTheme(themeName);
    }, 2000);
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          theme === "Dark" ? "dark-modal modal-popup bgmodalpop" : "light-modal modal-popup"
        }
      >
        <Box sx={style}>
          {content}
          <Grid item xs={12} md={12} lg={12} className="center-vendor">
            <Button
              variant="contained"
              className="cancel-popups-btn cancel-as-well"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton loading={loader}
              loadingIndicator={<CircularProgress size={12} sx={{ marginRight: "85px", color: "white" }} />}
              size="large"
              type="submit"
              onClick={() => onSubmit()}
              variant="contained" className="cancel-popups-btn"
              >
              <span>Confirm</span>
            </LoadingButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
