import React, { useState } from "react";
import Chart from "react-apexcharts";

function ComplianceBarChart({fillPercentage,color}) {

  // console.log('color',color)

  return (
    <>
      <div
        style={{
          backgroundColor: "#322671",
          borderRadius: "25px",
          padding: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "max-content",
          height: "8px",
          margin: "10px 0 0",
          position: "relative",
          top: "5px",
        }}
      >
        <span className="chart-scores-num">{fillPercentage}/100</span>
        <Chart
          options={{
            chart: {
              type: "bar",
              height: 45,
              borderRadius: 50,
              sparkline: {
                enabled: true, // Hide axes and gridlines
              },
            },
            plotOptions: {
              bar: {
                horizontal: true,
                borderRadius: 5,
                barHeight: "30%",
                distributed: true,
              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                gradientToColors: [color],
                stops: [0, 100],
              },
            },
            xaxis: {
              categories: [''],
              max: 100,
            },
            fill: {
              colors: [color],
            },
            tooltip: {
              enabled: false,
            },
          }}
          series={[{ data: [fillPercentage] }]}
          type="bar"
          height={31}
        />
      </div>
    </>
  );
}

export default ComplianceBarChart;
