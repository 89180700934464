import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    permissionList: [],
};

const permissionListSlice = createSlice({
    name: "permissionList",
    initialState,
    reducers: {
        addPermissionList: (state, action) => {
            state.permissionList = action.payload;
        },
    },
});

export const { addPermissionList } = permissionListSlice.actions;

export default permissionListSlice.reducer;