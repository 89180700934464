import React from "react";
import {
  Box,
  Button,
  IconButton,
  Grid,
  Container,
  Tabs,
  Tab,
  TableRow,
  TableCell,
  TableContainer,
  Card,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NewHeader from "../../../src/layouts/dashboard/header/NewHeader";
import "../../pages/css/newVendor.css";
import { useEffect, useState } from "react";
import OverviewHome from "./OverviewHome";
import RiskProfile from "./RiskProfile";
import Issues from "./Issues";
import PostRequest from "../../components/apiConnections/postRequest";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useSnackbar } from "notistack";
import { changeEcosystemTab } from "../../redux/activeTabSlice";
import moment from "moment";
import GetRequest from "../../components/apiConnections/getRequest";
import Cve from "./Cve";
import Compliance from "./Compliance";
import ClientRemediation from "./ClientRemediation";
import Loader from "../../Loader";
import AiAssessments from "./AiAssessments";
import FaildeControl from "./_failedControl";
import PassedControl from "./_passedControl";
import VendorDocuments from "./VendorDocuments";
import DocumentAnalysis from "../DocumentAnalysis";
import Breadcrumb from "./Breadcrumb";
import AILoader from "../../AILoader";
import { set } from "lodash";

function VendorDetailsPage() {
  const location = useLocation();
  const [mainTabValue, setMainTabValue] = useState(location.state?.mainTabValue || 0);
  const [nestedTabValue, setNestedTabValue] = useState(0);
  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setNestedTabValue(0); // Reset nested tab when main tab changes
  };

  const handleNestedTabChange = (event, newValue) => {
    setNestedTabValue(newValue);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ecosystemActiveTab = useSelector((state => state.activeTab?.ecosystem));
  const theme = useTheme();
  const modeClass =
    theme.palette.mode === "light" ? "light-mode-class" : "dark-mode-class";
  const data = useParams();
  // const [openAssessment, setOpenAssessment] = React.useState(false);
  // const [openRemediation, setOpenRemediation] = React.useState(false);
  // const [low, setLow] = useState(0);
  const [domainName, setDomainName] = useState("-");
  const [vendorName, setVendorName] = useState("-");
  const [hosting, setHosting] = useState("--");
  const [address, setAddress] = useState("--");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [dnsGrade, setDnsGrade] = useState("-");
  const [breachesGrade, setBreachesGrade] = useState("-");
  const [encryptionGrade, setEncryptionGrade] = useState("-");
  const [misconfigrationGrade, setMisconfigrationGrade] = useState("-");
  const [patchingGrade, setPatchingGrade] = useState("-");
  const [riskScore, setRiskScore] = useState(0);
  const [totalIssues, setTotalIssues] = useState();
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [showLoader, setshowLoader] = useState(false);
  const [urlLinked, setUrlLinked] = useState('');
  const [complianceData, setComplianceData] = useState([]);
  const [complianceRows, setComplianceRows] = useState([]);
  const [reletionShipRisk, setReletionShipRisk] = useState('');
  const [overallGrade, setOverallGrade] = useState("-")
  const [vendorDetailsData, setVendorDetailsData] = useState();
  const [misconfigrationOldScore, setMisconfigrationOldScore] = useState(0);
  const [encryptionOldScore, setEncryptionOldScore] = useState(0);
  const [dnsOldScore, setDnsOldScore] = useState(0);
  const [breachesOldScore, setBreachesOldScore] = useState(0);
  const [patchingOldScore, setPatchingOldScore] = useState(0);
  const [overallOldScore, setOverallOldScore] = useState(0);
  const [overallRiskScore,setOverallRiskScore]=useState(0);
  const [overallRiskLoader,setOverallRiskLoader]=useState(false);

  // const [complianceColumns, setComplianceColumns] = useState([
  //   {
  //     field: "email",
  //     headerName: "Email Id",
  //     editable: false,
  //     minWidth: 200,
  //     headerAlign: "left",
  //     align: "left",
  //   },
  //   {
  //     field: "set",
  //     headerName: "Set",
  //     editable: false,
  //     width: 200,
  //     headerAlign: "left",
  //     align: "left",
  //     flex: 1,
  //   },
  //   {
  //     field: "score",
  //     headerName: "Score",
  //     editable: false,
  //     width: 50,
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //   },
  //   {
  //     field: "startDate",
  //     headerName: "Start Date",
  //     editable: false,
  //     minWidth: 100,
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //   },
  //   {
  //     field: "deadline",
  //     headerName: "Deadline",
  //     editable: false,
  //     minWidth: 100,
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //   },
  //   {
  //     field: "assessmentStatus",
  //     headerName: "Status",
  //     editable: false,
  //     minWidth: 100,
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //     renderCell: (params) => {
  //       return (
  //         <Box
  //           sx={{
  //             display: "flex",
  //             minWidth: "100px",
  //             height: "28px",
  //             alignItems: "center",
  //             justifyContent: "center",
  //             fontSize: "12px",
  //             fontWeight: "500",
  //             color: "white",
  //             borderRadius: "5px",
  //             paddingY: "2px",
  //             paddingX: "6px",
  //             textTransform: "uppercase",
  //             backgroundColor: `${params?.row?.assessmentStatus
  //               ? "green"
  //               : params.row.delayed
  //                 ? "red"
  //                 : "#c3b50d"
  //               }`,
  //           }}
  //         >
  //           {params.row.assessmentStatus}
  //         </Box>
  //       );
  //     },
  //   },
  //   {
  //     field: "action",
  //     headerName: "Action",
  //     width: 150,
  //     sortable: false,
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //     renderCell: (params) => {
  //       return (
  //         <Box>
  //           <IconButton
  //             aria-label="more"
  //             aria-controls="actions-menu"
  //             aria-haspopup="true"
  //             onClick={(event) => handleActionClick(event, params.row)}
  //           >
  //             <MoreVertIcon />
  //           </IconButton>
  //         </Box>
  //       );
  //     },
  //   },
  // ]);
  // const [centralizedRepoColumns, setCentralizedRepoColumns] = useState([
  //   {
  //     field: "documentType",
  //     headerName: "Document Type",
  //     editable: false,
  //     width: 250,
  //     headerAlign: "left",
  //     align: "left",
  //     flex: 2,
  //     renderCell: (params) => params.row.documentType?.name || "N/A", // Safely access the name property
  //   },
  //   {
  //     field: "createdAt",
  //     headerName: "Created At",
  //     editable: false,
  //     width: 50,
  //     headerAlign: "center",
  //     align: "center",
  //     flex: 1,
  //     renderCell: (params) => formatDate(params.value)
  //   },
  //   {
  //     field: "action",
  //     headerName: "Action",
  //     width: 50,
  //     sortable: false,
  //     headerAlign: "right",
  //     align: "right",
  //     flex: 1,
  //     renderCell: (params) => {
  //       return (
  //         <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
  //           <Link
  //             to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_OPEN_VENDOR_DOCUMENT}/${params.row.documentName}`}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //           >
  //             <Button>
  //               <VisibilityIcon />
  //             </Button>
  //           </Link>
  //         </Box>
  //       );
  //     },
  //   }
  // ]);

  // const [vendorDocumentsTypes, setVendorDocumentsTypes] = useState([]);
  // const [vendorAvailableDocuments, setVendorAvailableDocuments] = useState([]); // State to store documents list fetched from the API
  // const [vendorNotAvailableDocuments, setVendorNotAvailableDocuments] = useState([]);
  // const [modelOpen, setModelOpen] = useState(false);
  // const handleModelOpen = () => {
  //   if (vendorNotAvailableDocuments.length === 0) {
  //     enqueueSnackbar("All Documents already Uploaded", { variant: "info" });
  //   }
  //   else {
  //     setModelOpen(true);
  //   }
  // }
  // const handleModelClose = () => setModelOpen(false);
  // const [contactPerson, setcontactPerson] = useState('');
  // const [contactDetails, setContactDetails] = useState();
  // const [formData, setFormData] = useState({
  //   documents: [{ documentType: '', isRequest: false, documentFile: null, documentName: null }],
  // });
  // const [showDocumentEvidence, setShowDocumentEvidence] = useState("Documents");
  // const vendorQuestionnaireEvidenceColumn = [
  //   // { field: 'assessmentId', headerName: 'Assessment ID', width: 150 },
  //   { field: 'category', headerName: 'Category', width: 200 },
  //   { field: 'set', headerName: 'Set', width: 100 },
  //   {
  //     field: 'que', headerName: 'Question', width: 250,
  //     renderCell: (params) => params.row?.que?.text || "N/A", // Safely access the name property
  //   },
  //   { field: 'ans', headerName: 'Answer', width: 150, headerAlign: "center", align: "center" },
  //   { field: 'remark', headerName: 'Remark', width: 200 },
  //   {
  //     field: 'fileName',
  //     headerName: 'Action',
  //     width: 100,
  //     headerAlign: "center",
  //     align: "center",
  //     renderCell: (params) => {
  //       return (
  //         <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
  //           <Link
  //             to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${"evidence"}/${params.row.fileName}`}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //           >
  //             <Button>
  //               <VisibilityIcon />
  //             </Button>
  //           </Link>
  //         </Box>
  //       );
  //     },
  //   },
  // ];
  // const [vendorQuestionnaireEvidenceData, setVendorQuestionnaireEvidenceData] = useState([]);
  const [errors, setErrors] = useState({});

  // const transformVendorEvidenceData = (apiData) => {
  //   if (!apiData) return [];
  //   return apiData.flatMap((item) => {
  //     return Object.entries(item.questionnaires).flatMap(([category, questions]) =>
  //       questions.map((question) => ({
  //         id: `${item._id}-${question.que}`, // Create a unique ID for each row
  //         assessmentId: item.assessmentId,
  //         set: item.set,
  //         category,
  //         que: question.que,
  //         ans: question.ans,
  //         remark: question.remark,
  //         fileName: question.fileName,
  //       }))
  //     );
  //   });
  // };

  const filteredRows = complianceRows
    .filter((row) => row.url === domainName)
    .map((val, i) => ({
      ...val,
      uid: i + 1,
    }));

  // const [selectedRow, setSelectedRow] = useState(null);
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleActionClick = (event, row) => {
  //   setSelectedRow(row);
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleActionClose = () => {
  //   setSelectedRow(null);
  //   setAnchorEl(null);
  // };

  // const handleOpen = (id, text) => {
  //   if (text === "compliance") {
  //     setAssessmentRemoveId(id);
  //   } else if (text === "remediation") {
  //     setRemediationRemoveId(id);
  //   }
  // };

  // const handleAnswerClick = (id, email, event) => {
  //   event.preventDefault();
  //   navigate(`/dashboard/view-answer-page/${id}`, { state: { email: email, prevUrl: `vendorDetails/${vendorUrl}` } });
  // };

  const [remediationData, setRemediationData] = useState({});
  const userEmail = window.localStorage.getItem("userEmail");
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [assessmentRemoveId, setAssessmentRemoveId] = useState(0);
  const [remediationRemoveId, setRemediationRemoveId] = useState(0);
  const [sumScore, setSumScore] = useState("");
  const [complianceLength, setComplianceLength] = useState(0);
  const [complianceCardData, setComplianceCardData] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [vendorTypes, setVendorTypes] = useState([]);
  const [type, setType] = useState("");
  // const [activeIssue, setActiveIssue] = useState({});
  const [error, setError] = useState(false);
  // const [disableDownloadPDFButton, setDisableDownloadPDFButton] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [isPublicUrl, setIsPublicUrl] = useState("");
  const [value, setValue] = useState("1");
  const [documentValue, setDocumentValue] = useState("11");
  const [AIshowLoader, setshowAILoader] = useState(false);
  useEffect(() => {
    if (location.state?.mainTabValue !== undefined) {
      setMainTabValue(location.state.mainTabValue);
    }
    if (location.state?.nestedTabValue !== undefined) {
      setNestedTabValue(location.state.nestedTabValue);
    }
    navigate(location.pathname, { replace: true });
  }, [location.state, navigate]);

  useEffect(() => {
    getVendorDetails();
    getVendorComplianceCard();
    getVendorsType();
    getAssessment();
    getVendorOverAllRiskScore()
    // getVendorDocuments();
    // getVendorDocumentsTypes();
    // getContactPerson();
    // getVendorQuestionnaireEvidenceList();

    if (ecosystemActiveTab === "4") {
      setValue("4")
      dispatch(changeEcosystemTab("1"))
    }
    if (ecosystemActiveTab === "5") {
      setValue("5")
      dispatch(changeEcosystemTab("1"))
    }
  }, []);


  // useEffect(() => {
  //   if (!showLoader) {
  //     setOpenAssessment(assessmentRemoveId !== 0 ? true : false);
  //     setOpenRemediation(remediationRemoveId !== 0 ? true : false);
  //   }
  // }, [assessmentRemoveId, remediationRemoveId, showLoader]);

  // const handleChangeType = (event) => {
  //   setType(event.target.value);
  // };

  // useEffect(() => {
  //   if (vendorDocumentsTypes.length > 0) {
  //     let notAvailableDocuments = [];

  //     if (vendorAvailableDocuments.length === 0) {
  //       notAvailableDocuments = [...vendorDocumentsTypes];
  //     } else {
  //       const availableDocumentIds = vendorAvailableDocuments && vendorAvailableDocuments.length > 0 && vendorAvailableDocuments.map(
  //         (doc) => doc.documentType._id
  //       );
  //       notAvailableDocuments = vendorDocumentsTypes.filter(
  //         (doc) => !availableDocumentIds.includes(doc._id)
  //       );
  //     }
  //     setVendorNotAvailableDocuments(notAvailableDocuments);
  //   }
  // }, [vendorDocumentsTypes, vendorAvailableDocuments]); // Re-run the effect when these dependencies change

  // const handleClose = () => {
  //   setOpenAssessment(false);
  //   setOpenRemediation(false);
  //   setAssessmentRemoveId(0);
  //   setRemediationRemoveId(0);
  // };

  // const addLinked = async (e) => {
  //   e.preventDefault();
  //   setshowLoader(true);

  //   // Check if 'type' is selected
  //   if (!type) {
  //     enqueueSnackbar("Please select a type", { variant: "error" });
  //     setshowLoader(false);
  //     return;
  //   }

  //   try {
  //     const vendorPayload = {
  //       vendor: data.url,
  //       username: userEmail,
  //       vendorType: type,
  //     };

  //     const refreshToken = await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
  //       {},
  //       {},
  //       "refreshToken"
  //     );

  //     if (refreshToken) {
  //       PostRequest(
  //         `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VENDOR_URL_LINK}`,
  //         vendorPayload
  //       )
  //         .then((resLink) => {
  //           setTimeout(() => {
  //             // Reload the page after a successful response
  //             setshowLoader(true);
  //             window.location.reload();
  //           }, 500);
  //           setshowLoader(false);
  //         })
  //         .catch((errLink) => {
  //           enqueueSnackbar(errLink.message, { variant: "error" });
  //           setshowLoader(false);
  //         });
  //     }
  //   } catch (errApi) {
  //     enqueueSnackbar("Requested Url not found", { variant: "error" });
  //     setshowLoader(false);
  //   }
  // };

  const getVendorsType = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_VENDOR_TYPES}`
        )
          .then((name) => {
            const obj = name.data.data;
            setVendorTypes(obj);
          })
          .catch((err) => {
            enqueueSnackbar("Vendor Types not find", { variant: "error" });
            return false;
          });
      }
    } catch (error) {
      enqueueSnackbar("Vendor Types Name not find", { variant: "error" });
      return false;
    }
  };

  const getVendorDetails = async () => {
    setMainLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_SINGLE_VENDOR_DETAILS}`,
          vendorPayload
        )
          .then((res) => {
            var vendorTag = res.data.data?.vendorData?.vendorType;
            setVendorDetailsData(res.data?.data);
            if (!vendorTag) {
              vendorTag = "Vendor";
            }
            setIsPublicUrl(res.data.data?.vendorData?.thirdPartyPublicUrl)
            setVendorType(vendorTag);
            setUrlLinked(res.data.data?.urlLink);
            setDomainName(res.data.data?.vendorData?.vendorInfo?.domain);
            setVendorName(res.data.data?.vendorDetail?.name);
            setReletionShipRisk(res.data.data?.vendorDetail?.relationshipRisk);
            setOverallGrade(res.data.data?.vendorData?.vendorInfo?.grade[0]?.Overall_score);
            setRiskScore(
              res.data.data?.vendorData?.vendorInfo?.score[0]?.Overall_score
            );
            setAddress(res.data.data?.vendorData?.vendorInfo?.ip);
            setHosting(res.data.data?.vendorData?.vendorInfo?.hosting);
            setLogo(
              res.data.data?.vendorData?.vendorInfo?.logo ||
              "/assets/images/avatars/user.png"
            );
            setDns(res.data.data?.vendorData?.vendorInfo?.count[0].Dns);
            setBreaches(
              res.data.data?.vendorData?.vendorInfo?.count[0]?.Breaches
            );
            setEncryption(
              res.data.data?.vendorData?.vendorInfo?.count[0]?.Encryption
            );
            setMisconfigration(
              res.data.data?.vendorData?.vendorInfo?.count[0]?.Misconfiguration
            );
            const vendorInfo = res.data.data?.vendorData?.vendorInfo;

            const dnsUrls = vendorInfo?.dns.flatMap(item => item.URL);
            const misconfigUrls = vendorInfo?.misconfiguration.flatMap(item => item.URL);
            setTotalIssues(dnsUrls.length + misconfigUrls.length);

            const dataMatch = [
              "breaches",
              "dns",
              "encryption",
              "misconfiguration",
              "patching",
            ];
            let totalLow = 0;
            let totalMedium = 0;
            let totalHigh = 0;
            let totalCritical = 0;
            let patchingissues = 0;

            Object.entries(vendorInfo).map((key, valuevendorInfo) => {
              if (dataMatch.includes(key[0])) {
                const dataArr = key[1];
                dataArr.map((value) => {
                  if (key[0] === "patching") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                      patchingissues = patchingissues + 0;
                    } else {
                      const arrPatch = value?.cve;
                      arrPatch.map((ele) => {
                        patchingRows.push({
                          ...issuespatchingList(
                            !ele.CVE ? "-" : ele.CVE,
                            key[0], // ele.Description || ele.Desc,
                            String(ele?.Severity)
                              .replace(/<[^>]*>/g, "")
                              .replaceAll('"', "")
                              .trim()
                              .toLowerCase(),
                            "View",
                            ele,
                            ele.Score
                          ),
                          affectedUrl: value.Host,
                        });
                        patchingissues++;
                        totalLow =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "low"
                            ? totalLow + 1
                            : totalLow;
                        totalMedium =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "medium"
                            ? totalMedium + 1
                            : totalMedium;
                        totalHigh =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "high"
                            ? totalHigh + 1
                            : totalHigh;
                        totalCritical =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "critical"
                            ? totalCritical + 1
                            : totalCritical;
                      });
                    }
                  } else if (key[0] === "dns") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          value?.URL || []
                        )
                      );
                    }
                  } else {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          value?.URL || []
                        )
                      );
                    }
                  }
                });
              }
              if (key[0] === "count") {
                setTotalScore(key[1][0].Overall_count);
              }
            });
            setPatchingRowsData(patchingRows);
            setIssuesListData(issuesRows);
            setTotalLow(totalLow);
            setTotalMedium(totalMedium);
            setTotalHigh(totalHigh);
            setTotalCritical(totalCritical);
            setPatching(res.data.data.vendorData?.vendorInfo?.count[0]?.Patching);
            // Score
            setDnsScore(res.data.data.vendorData?.vendorInfo?.score[0]?.Dns);
            setBreachesScore(
              res.data.data.vendorData?.vendorInfo?.score[0]?.Breaches
            );
            setEncryptionScore(
              res.data.data.vendorData?.vendorInfo?.score[0]?.Encryption
            );
            setMisconfigrationScore(
              res.data.data.vendorData?.vendorInfo?.score[0]?.Misconfiguration
            );
            setPatchingScore(
              res.data.data.vendorData?.vendorInfo?.score[0]?.Patching
            );

            if (res.data.data.vendorData?.vendorInfo?.oldScore) {
              setDnsOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Dns);
              setBreachesOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Breaches);
              setEncryptionOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Encryption);
              setMisconfigrationOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Misconfiguration);
              setPatchingOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Patching);
              setOverallOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Overall_score)
            }

            if (res.data?.data?.vendorData?.vendorInfo?.grade) {
              setDnsGrade(res.data.data?.vendorData?.vendorInfo?.grade[0]?.Dns);
              setBreachesGrade(
                res.data.data.vendorData?.vendorInfo?.grade[0]?.Breaches
              );
              setEncryptionGrade(
                res.data.data.vendorData?.vendorInfo?.grade[0]?.Encryption
              );
              setMisconfigrationGrade(
                res.data.data.vendorData?.vendorInfo?.grade[0]?.Misconfiguration
              );
              setPatchingGrade(
                res.data.data.vendorData?.vendorInfo?.grade[0]?.Patching
              );
            }
            getAssessment();
            setMainLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setError(true);
            setMainLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setError(false);
      setMainLoader(false);
      return false;
    }
  };

  const getAssessment = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const assessmentPayload = {
        createdBy: userEmail,
        url: vendorUrl,
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ASSESSMENT_BY_USER}`,
          assessmentPayload
        )
          .then((resAssessment) => {
            const obj = resAssessment.data.data;
            getRemediation();
            setComplianceLength(Object.keys(obj).length);
            setComplianceData(obj);
            setComplianceRows(
              obj.length
                ? [
                  ...obj?.map((d, i) => ({
                    ...d,
                    id: d._id,
                    score: `${d.score}/100`,
                    startDate: moment.unix(d.startDate).format("DD/MM/YYYY"),
                    deadline: moment.unix(d.deadline).format("DD/MM/YYYY"),
                    status: d.completed[0]?.submitted
                      ? "Completed"
                      : d.delayed
                        ? "Delayed"
                        : "In-Progress",
                  })),
                ]
                : []
            );
            if (Object.keys(obj).length !== 0) {
              let sum = 0;
              let scoredAssessment = obj.filter((data) => typeof data.score === "number" && data.score > 0)
              scoredAssessment?.map((objKey, objVal) => (sum = sum + objKey.score));
              setSumScore(sum ? Math.round(sum / scoredAssessment.length) : 0);
            } else {
              setSumScore(0);
            }
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            // enqueueSnackbar("Assessment not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      // enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      setError(true);
      return false;
    }
  };

  // const deleteAssessment = async () => {
  //   const removeId = assessmentRemoveId;
  //   setshowLoader(true);
  //   try {
  //     const endpoint = process.env.REACT_APP_DELETE_ASSESSMENT;
  //     const assesmentdeletepayload = {
  //       assessmentId: removeId,
  //     };
  //     await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
  //       assesmentdeletepayload
  //     )
  //       .then((resVendors) => {
  //         enqueueSnackbar(resVendors.data.message, { variant: "success" });
  //         setshowLoader(false);
  //         handleClose();
  //         handleActionClose();
  //         getAssessment();
  //       })
  //       .catch((errVendor) => {
  //         enqueueSnackbar(errVendor.message, { variant: "error" });
  //         setshowLoader(false);
  //       });
  //   } catch (e) {
  //     enqueueSnackbar(e.message, { variant: "error" });
  //     setshowLoader(false);
  //   }
  // };

  // const deleteRemediation = async () => {
  //   const removeId = remediationRemoveId;

  //   setshowLoader(true);
  //   const refreshToken = await PostRequest(
  //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
  //     {},
  //     {},
  //     "refreshToken"
  //   );
  //   if (refreshToken) {
  //     const endpoint = process.env.REACT_APP_DELETE_REMEDIATION;
  //     const remddeletepayload = {
  //       remediationId: removeId,
  //     };
  //     await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
  //       remddeletepayload
  //     )
  //       .then((resVendors) => {
  //         enqueueSnackbar(resVendors.data.message, { variant: "success" });
  //         setshowLoader(false);
  //         handleClose();
  //         setTimeout(() => {
  //           getRemediation();
  //         }, 1000);
  //       })
  //       .catch((errVendor) => {
  //         enqueueSnackbar(errVendor.message, { variant: "error" });
  //         setshowLoader(false);
  //       });
  //   }
  // };

  useEffect(() => {
    if (mainTabValue === 3) {
      getRemediation();
    }
  }, [mainTabValue]);

  const getRemediation = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const remediationPayload = {
        createdBy: userEmail,
        url: vendorUrl,
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_REMEDIATION_PLAN_BY_USER}`,
          remediationPayload
        )
          .then((resRemediation) => {
            const obj2 = resRemediation.data.data;
            setRemediationData(obj2);
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Remediation not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Remediation not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getVendorComplianceCard = async () => {
    setshowLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_COMPLIANCE_CARD}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          let arr = [];
          for (let i = 0; i < Math.ceil(obj2.length / 3); i++) {
            let count = i * 3;
            arr.push(obj2.slice(count, count + 3));
          }
          setComplianceCardData(arr);
          setshowLoader(false);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Compliance card not find", { variant: "error" });
          setshowLoader(false);
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Compliance card not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };


  // const getVendorDocuments = async () => {
  //   setshowLoader(true);

  //   try {
  //     const vendorPayload = {
  //       vendorDomain: vendorUrl
  //     };

  //     const refreshToken = await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
  //       {},
  //       {},
  //       "refreshToken"
  //     );

  //     if (refreshToken) {
  //       PostRequest(
  //         `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOCUMENT_BY_VENDOR_DOMAIN}`,
  //         vendorPayload)
  //         .then((documentDetails) => {
  //           setVendorAvailableDocuments(documentDetails?.data?.data);
  //           setshowLoader(false);
  //         })
  //         .catch((errApi) => {
  //           // enqueueSnackbar("Requested Url not found", { variant: "error" });
  //           setshowLoader(false);
  //         })
  //     }
  //   } catch (error) {
  //     // no data
  //     // enqueueSnackbar("Requested Url not find", { variant: "error" });
  //     setshowLoader(false);
  //     setError(true);
  //     return false;
  //   }
  // };

  // const getVendorDocumentsTypes = async () => {
  //   setshowLoader(true);
  //   try {

  //     const refreshToken = await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
  //       {},
  //       {},
  //       "refreshToken"
  //     );

  //     if (refreshToken) {
  //       GetRequest(
  //         `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_DOCUMENT_TYPES}`)
  //         .then((response) => {
  //           setVendorDocumentsTypes(response?.data?.data);
  //           setshowLoader(false);
  //         })
  //         .catch((errApi) => {
  //           enqueueSnackbar("Requested Url not found", { variant: "error" });
  //           setshowLoader(false);
  //         })
  //     }
  //   } catch (error) {
  //     // no data
  //     // enqueueSnackbar("Requested Url not find", { variant: "error" });
  //     setshowLoader(false);
  //     setError(true);
  //     return false;
  //   }
  // };
  // 
  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: "60%",
  //   bgcolor: "solid #0000",
  //   border: '2px solid #000',
  //   boxShadow: 24,
  //   textAlign: "center",
  //   borderRadius: "15px",
  //   p: 4,
  //   overflowY: "auto",
  //   maxHeight: "90%"
  // };
  // 
  // const getContactPerson = async () => {
  //   setshowLoader(true);
  //   try {
  //     const refreshToken = await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
  //       {},
  //       {},
  //       "refreshToken"
  //     );
  //     if (refreshToken) {
  //       GetRequest(
  //         `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${vendorUrl}`,
  //       )
  //         .then((res) => {
  //           const response = res.data.data;
  //           setContactDetails(response);
  //           setshowLoader(false);
  //         })
  //         .catch((err) => {
  //           enqueueSnackbar("Contact person not found", { variant: "error" });
  //           setshowLoader(false);
  //           return false;
  //         });
  //     }
  //   } catch (error) {
  //     enqueueSnackbar("Contact person not found", { variant: "error" });
  //     setshowLoader(false);
  //     return false;
  //   }
  // };
// 
  // const getVendorQuestionnaireEvidenceList = async () => {
  //   setshowLoader(true);

  //   try {
  //     const refreshToken = await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
  //       {},
  //       {},
  //       "refreshToken"
  //     );

  //     if (refreshToken) {
  //       GetRequest(
  //         `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_QUESTIONNAIRE_EVIDENCE_LIST}?vendorDomain=${vendorUrl}`,
  //       )
  //         .then((response) => {
  //           const transformedData = transformVendorEvidenceData(response?.data); // Transform the data
  //           setVendorQuestionnaireEvidenceData(transformedData); // Set the transformed data
  //           setshowLoader(false);
  //         })
  //         .catch((errApi) => {
  //           // enqueueSnackbar("Requested Url not found", { variant: "error" });
  //           setshowLoader(false);
  //         })
  //     }
  //   } catch (error) {
  //     // no data
  //     // enqueueSnackbar("Requested Url not find", { variant: "error" });
  //     setshowLoader(false);
  //     setError(true);
  //     return false;
  //   }
  // };

  // const getVendorGeneratedPDF = async () => {
  //   setshowLoader(true);
  //   setDisableDownloadPDFButton(true);
  //   try {
  //     const refreshToken = await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
  //       {},
  //       {},
  //       "refreshToken"
  //     );
  //     if (refreshToken) {
  //       GetRequest(
  //         `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_GENERATED_PDF}?url=${vendorUrl}&createdBy=${userEmail}`,
  //         {
  //           responseType: "arraybuffer",
  //           headers: {
  //             "Content-Type": "application/pdf",
  //           },
  //         }
  //       )
  //         .then((res) => {
  //           const pdfData = new Blob([res.data], { type: "application/pdf" });
  //           const pdfUrl = URL.createObjectURL(pdfData);
  //           window.open(pdfUrl, "_blank");
  //           setDisableDownloadPDFButton(false);
  //           setshowLoader(false);
  //         })
  //         .catch((err) => {
  //           // no data
  //           enqueueSnackbar("Error while generating PDF", { variant: "error" });
  //           setDisableDownloadPDFButton(false);
  //           setshowLoader(false);
  //           return false;
  //         });
  //     }
  //   } catch (error) {
  //     // no data
  //     setDisableDownloadPDFButton(false);
  //     if (error.message) enqueueSnackbar("", { variant: "error" });
  //     else enqueueSnackbar("Error while generating PDF", { variant: "error" });
  //     setshowLoader(false);
  //     return false;
  //   }
  // };

  // const validateURL = (url) => {
  //   // Regular expression to match the URL format
  //   const urlRegex =
  //     /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]{1,63})\.([a-zA-Z0-9-]{1,63})\.([a-zA-Z0-9]{2,6})(?:\/\S*)?$/;

  //   // Check if the URL matches the regex pattern
  //   const isValidURL = urlRegex.test(url);

  //   if (isValidURL) {
  //     // Split the URL into subdomain, domain, and TLD parts
  //     const [, subdomain, domain, tld] = url.match(urlRegex);

  //     // Check if subdomain, domain, and TLD meet the criteria
  //     if (subdomain && domain && tld) {
  //       // Check if the URL contains http or https protocol
  //       if (!url.startsWith("http://") && !url.startsWith("https://")) {
  //         return true;
  //       } else {
  //         return false; // Reject if the URL contains http or https protocol
  //       }
  //     } else {
  //       return false; // Reject if any part of the URL is missing
  //     }
  //   } else {
  //     return false; // Reject if the URL format doesn't match
  //   }
  // };

  // const visitPublicPage = async () => {
  //   const isExternal = validateURL(isPublicUrl);
  //   if (isExternal) {
  //     const url = `${window.location.protocol}//${isPublicUrl}`; // Example URL
  //     window.open(url, '_blank')
  //   } else {
  //     const url = `${window.location.protocol}//${process.env.REACT_APP_THIRDPARTY_HOSTNAME}/security-center/${isPublicUrl}`
  //     window.open(url, '_blank');
  //   }
  // };

  function createData(category, issue, grade, score, oldScore) {
    return { category, issue, grade, score, oldScore };
  };

  const rows = [
    createData("Breaches", breaches, breachesGrade, breachesScore, breachesOldScore),
    createData("DNS", dns, dnsGrade, dnsScore, dnsOldScore),
    createData("Encryption", encryption, encryptionGrade, encryptionScore, encryptionOldScore),
    createData(
      "Misconfiguration",
      misconfiguration,
      misconfigrationGrade,
      misconfigrationScore,
      misconfigrationOldScore
    ),
    createData("Patching", patching, patchingGrade, patchingScore, patchingOldScore),
  ];

  function issuesList(name, category, severity, view, ele, refs, urls) {
    return { name, category, severity, view, ele, refs, urls };
  };

  function issuespatchingList(name, category, severity, view, ele, score) {
    return { name, category, severity, view, ele, score };
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const handleDocumentRequestChange = (event, newValue) => {
  //   setDocumentValue(newValue);
  // };

  // const ExpandableTableRow = ({
  //   children,
  //   refs,
  //   urls,
  //   cve,
  //   ele,
  //   expandComponent,
  //   ...otherProps
  // }) => {
  //   const [isExpanded, setIsExpanded] = useState(false);

  //   return (
  //     <>
  //       <TableRow {...otherProps}>
  //         <TableCell padding="checkbox">
  //           <IconButton onClick={() => setIsExpanded(!isExpanded)}>
  //             {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  //           </IconButton>
  //         </TableCell>
  //         {children}
  //       </TableRow>
  //       {isExpanded && (
  //         <TableRow>
  //           <TableCell colSpan="4" padding="checkbox">
  //             <TableContainer
  //               sx={{ p: 1 }}
  //               style={{ width: "93%", overflow: "hidden" }}
  //               className="vendordetail"
  //             >
  //               <TableRow>
  //                 <TableCell padding="checkbox" />
  //                 {expandComponent}

  //                 {ele?.Email ? (
  //                   <TableRow
  //                     sx={{
  //                       "& td": {
  //                         borderBottom: "1px solid rgba(81, 81, 81, 1)",
  //                       },
  //                     }}
  //                   >
  //                     <TableCell colSpan="5">
  //                       <b>Affected Emails</b>
  //                       <p>{ele?.Email}</p>
  //                     </TableCell>
  //                   </TableRow>
  //                 ) : null}

  //                 {ele?.Source ? (
  //                   <TableRow sx={{ "& td": { border: 0 } }}>
  //                     <TableCell colSpan="5">
  //                       <b>Source</b>
  //                     </TableCell>
  //                   </TableRow>
  //                 ) : null}

  //                 {ele?.Source && typeof ele?.Source == "object" ? (
  //                   ele?.Source.map((source, index) => (
  //                     <TableRow sx={{ "& td": { borderTop: 0 } }}>
  //                       <TableCell
  //                         colSpan="1"
  //                         sx={{ "& td": { borderBottom: 0, borderTop: 0 } }}
  //                       >
  //                         #{index + 1}
  //                       </TableCell>
  //                       <TableCell colSpan="4">{source}</TableCell>
  //                     </TableRow>
  //                   ))
  //                 ) : (
  //                   <TableRow
  //                     sx={{ "& td": { border: 0 } }}
  //                   >
  //                     <TableCell>{ele?.Source}</TableCell>
  //                   </TableRow>
  //                 )}

  //                 {refs?.length > 0 ? (
  //                   <TableRow
  //                     sx={{
  //                       "& td": {
  //                         border: 0,
  //                       },
  //                     }}
  //                   >
  //                     <TableCell colSpan="5">
  //                       <b>References</b>
  //                     </TableCell>
  //                   </TableRow>
  //                 ) : null}

  //                 {refs?.map((url, index) => (
  //                   <TableRow sx={{ "& td": { border: 0 } }}>
  //                     <TableCell colSpan="1">#{index + 1}</TableCell>
  //                     <TableCell colSpan="4">
  //                       <a href={url} target="_blank" rel="noreferrer">
  //                         {url}
  //                       </a>
  //                     </TableCell>
  //                   </TableRow>
  //                 ))}

  //                 {urls?.length > 0 ? (
  //                   <TableRow
  //                     style={{ border: 0 }}
  //                   >
  //                     <TableCell colSpan="5" style={{ borderBottom: 0 }}>
  //                       <b>Affected URLs</b>
  //                     </TableCell>
  //                   </TableRow>
  //                 ) : null}
  //                 {Array.isArray(urls) ? urls?.map((url, index) =>
  //                   index + 1 === urls?.length ? (
  //                     <TableRow sx={{ "& td": { border: 0 } }}>
  //                       <TableCell colSpan="1">#{index + 1}</TableCell>

  //                       <TableCell colSpan="4">
  //                         <a href={url} target="_blank" rel="noreferrer">
  //                           {url}
  //                         </a>
  //                       </TableCell>
  //                     </TableRow>
  //                   ) : (
  //                     <TableRow sx={{ "& td": { border: 0 } }}>
  //                       <TableCell colSpan="1">#{index + 1}</TableCell>

  //                       <TableCell colSpan="4">
  //                         <a href={url} target="_blank" rel="noreferrer">
  //                           {url}
  //                         </a>
  //                       </TableCell>
  //                     </TableRow>
  //                   )
  //                 ) : null}
  //               </TableRow>
  //             </TableContainer>
  //           </TableCell>
  //         </TableRow>
  //       )}
  //     </>
  //   );
  // };

  // const ExpandableTableRowPatching = ({
  //   children,
  //   cve,
  //   expandComponent,
  //   ...otherProps
  // }) => {
  //   const [isExpanded, setIsExpanded] = useState(false);
  //   return (
  //     <>
  //       <TableRow {...otherProps} hover>
  //         {children}
  //       </TableRow>
  //       {isExpanded && (
  //         <TableRow sx={{ "& td": { borderBottom: 0 } }}>
  //           <TableCell padding="checkbox" />
  //           {expandComponent}
  //         </TableRow>
  //       )}
  //     </>
  //   );
  // };
  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   return `${day}-${month}-${year}`;
  // };
  const getReletionshipTag = (score) => {
    let riskTag;
    if (score && score <= 25) {
      riskTag = "Low";
    } else if (score && score <= 50) {
      riskTag = "Medium";
    } else if (score && score <= 75) {
      riskTag = "High";
    } else if (score && score > 75) {
      riskTag = "Critical";
    }
    return riskTag;
  }
  const breadcrumbs = [
    { label: 'Ecosystem', path: '/dashboard/vendors' },
    { label: 'Security', },
  ];
  const handleTabChange = (tabValue, path) => {
    mainTabValue(tabValue);
    // navigate(path); 
  };
  const toggleLoader = (isLoading) => {
    setshowAILoader(isLoading);
  };

  const getVendorOverAllRiskScore = async () => {
    setOverallRiskLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_OVERALL_RISK_SCORE}?domain=${data?.url}`,

        )
          .then((res) => {
            let result = res?.data?.data;
            // setPercentageChangeOverAllRiskScore(result?.percentageChangeOverAllRiskScore);
            // setLatestScanDate(moment(result?.latestScanDate).format('DD MMM YYYY'));
            const risk=Number(result?.overallRiskScore)
            setOverallRiskScore(risk || 0)
            // setOverallRiskLoader(false);

          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: "error" });
            setOverallRiskLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      // setChartDataLoader(false);
    }
  };

    const getGradeALfabate = (score) => {
      console.log("score 123",score)
    let GradeTag;
    if (score && score <= 25) {
      GradeTag = "D";
    } else if (score && score <= 50) {
      GradeTag = "C";
    } else if (score && score <= 75) {
      GradeTag = "B";
    } else if (score && score > 75) {
      GradeTag = "A";
    }
    return GradeTag;
  }
  return (
    <>
      <Helmet>
        <title>Genesis Platform</title>
      </Helmet>
      <Loader show={mainLoader || showLoader} />
      {AIshowLoader ? (
        <AILoader show={AIshowLoader} />
      ) : null}
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Security Profile" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
                <Breadcrumb crumbs={breadcrumbs} />
                <h3>Vendor Info</h3>
              </div>
              {/* <Button variant="contained" className="genesis-ai-btn">
                Genesis AI
              </Button> */}
              <DocumentAnalysis domain={vendorUrl}></DocumentAnalysis>
            </div>
          </Grid>
          {domainName !== "" && domainName && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <div className="infor-vendor-detail-box">
                  <div className="domain-detail-platte">
                    <div className="logo-sign">
                      <img src={logo} alt="icon" />
                    </div>
                    <div className="line-right">{vendorName}</div>
                    <div className="line-right">{domainName}</div>
                    <div className="line-right">{address}</div>
                    <div className="line-right">
                      <p>Grade: </p>
                      <span className={`gradClass ${getGradeALfabate(overallRiskScore)}`}>
                      {getGradeALfabate(overallRiskScore)}
                      </span>
                    </div>
                    <div className="line-right right-0">
                      {reletionShipRisk ? (
                        <span className={`tagClass ${getReletionshipTag(reletionShipRisk)}`}>
                          {getReletionshipTag(reletionShipRisk)}
                        </span>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Tabs
                  value={mainTabValue}
                  onChange={handleMainTabChange}
                  className="tab-main-heading margin-mui-tabfix"
                  aria-label="basic tabs example"
                >
                  <Tab className="active-color-remove" label="Overview" />
                  <Tab className="active-color-remove" label="Issues" />
                  <Tab className="active-color-remove" label="Compliance" />
                  <Tab className="active-color-remove" label="Remediation" />
                  <Tab className="active-color-remove" label="Controls" />
                  <Tab className="active-color-remove" label="AI Assessments" />
                  <Tab className="active-color-remove" label="Documents" />
                </Tabs>
                <Box>
                  {mainTabValue === 0 && (
                    <div className="first_tab">
                      <Tabs
                        value={nestedTabValue}
                        onChange={handleNestedTabChange}
                        className="tab-main-heading margin-mui-tabfix"
                        aria-label="nested tabs example"
                      >
                        <Tab
                          className="active-color-remove second-mui"
                          label="Home"
                        />
                        <Tab
                          className="active-color-remove second-mui"
                          label="Risk Profile"
                        />
                      </Tabs>
                      <Box>
                        {nestedTabValue === 0 && !showLoader && !mainLoader && <OverviewHome data={vendorDetailsData}
                          sumScore={sumScore}
                          totalLow={totalLow}
                          totalCritical={totalCritical}
                          totalMedium={totalMedium}
                          totalHigh={totalHigh}
                          complianceCardData={complianceCardData}
                          rows={rows}
                          totalScore={totalScore}
                          riskScore={riskScore}
                          complianceLength={complianceLength}
                          overallOldScore={overallOldScore}
                        />}

                        {nestedTabValue === 1 && (
                          <div>
                            <RiskProfile domainName={domainName} />
                          </div>
                        )}
                      </Box>
                    </div>
                  )}
                  {mainTabValue === 1 && (
                    <div className="issues-main-bg first_tab">
                      <div className="tab-issues-flex">
                        <div className="total-issue-three">
                          <h3>Total Issues</h3>
                          {/* <p>{issuesListData && issuesListData?.length || 0} </p> */}
                          <p>{totalIssues|| 0} </p>
                        </div>
                        <div className="total-issue-three">
                          <h3>Total CVE</h3>
                          <p>{patchingRowsData && patchingRowsData?.length || 0} </p>
                        </div>
                        <div className="total-issue-three">
                          <h3>Pending Remediation</h3>
                          <p>{vendorDetailsData?.pendingRemediationCount || 0}</p>
                        </div>
                      </div>
                      <Tabs
                        value={nestedTabValue}
                        onChange={handleNestedTabChange}
                        className="tab-main-heading"
                        aria-label="issues nested tabs example"
                      >
                        <Tab
                          className="active-color-remove second-mui nextmi-selected"
                          label="Issue"
                        />
                        <Tab
                          className="active-color-remove second-mui nextmi-selected"
                          label="CVE"
                        />
                      </Tabs>
                      <Box>
                        {nestedTabValue === 0 && (
                          <div>
                            <Issues
                              issuesListData={issuesListData}
                            />
                          </div>
                        )}
                        {nestedTabValue === 1 &&
                          <div>
                            <Cve
                              patchingRowsData={patchingRowsData}
                            />
                          </div>}
                      </Box>
                    </div>
                  )}
                  {mainTabValue === 2 &&
                    <div>
                      <Box>
                        <Compliance
                          complainceData={filteredRows}
                          getAssessment={getAssessment}
                        />
                      </Box>
                    </div>
                  }
                  {mainTabValue === 3 && <div>
                    {/* Remediation Content */}
                    <ClientRemediation remediationListData={remediationData} />
                  </div>}
                  {mainTabValue === 4 && (
                    <div className="first_tab">
                      <Tabs
                        value={nestedTabValue}
                        onChange={handleNestedTabChange}
                        className="tab-main-heading"
                        aria-label="nested tabs example"
                      >
                        <Tab
                          className="active-color-remove second-mui"
                          label="Passed"
                        />
                        <Tab
                          className="active-color-remove second-mui"
                          label="Failed"
                        />
                      </Tabs>
                      <Box>
                        {nestedTabValue === 0 && <div>
                          <PassedControl />
                        </div>}
                        {nestedTabValue === 1 && (
                          <div>
                            <FaildeControl setLoader={toggleLoader} />
                          </div>
                        )}
                      </Box>
                    </div>
                  )}
                  {mainTabValue === 5 && <div>
                    <AiAssessments 
                    domainName={domainName}
                    />
                  </div>}
                  {mainTabValue === 6 && <div><VendorDocuments /> </div>}
                </Box>
              </Grid>
            </>)}
          {error && domainName === "" && (
            <Box sx={{ display: "flex" }}>
              <Grid>
                <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                  <h4>No Record for this url</h4>
                </Card>
              </Grid>
            </Box>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default VendorDetailsPage;
