import React, { useState, useEffect } from "react";
import {
  Card,
  Box,
  Grid,
  Stack,
  TextField,
  Button,
  Chip,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Container,
} from "@mui/material";
import UserProfile from "./../../assets/user-profile.svg";
import Upload from "./../../assets/Vectorplus.svg";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";
import PostRequest from "../../components/apiConnections/postRequest";

const UpdateProfile = ({ props, stepper }) => {
  const addClientDetails = props.addClientDetails;
  const changeActiveComponent = props.changeActiveComponent;
  const submitData = props.submitData;
  const [formData, setFormData] = useState({
    ...props.client
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [websites, setWebsites] = useState([]);
  const [websiteInput, setWebsiteInput] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setFormData({ ...props.client });
    console.log('client formdata', formData)
  }, [props.client]);

  useEffect(() => {
    const emailParts = formData.email?.split("@");
    if (emailParts?.length === 2) {
      setEmailDomain(emailParts[1]);
    } else {
      setEmailDomain("");
    }
  }, [formData.email]);

  useEffect(() => {
    setPhoneNumber(props.client?.mobile || "");
  }, [props.client?.mobile]);

  const handleImageChange = async (e) => {
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/JPEG",
      "image/JPG",
      "image/PNG",
    ];
    const file = e.target.files[0];
    if (!allowedFileTypes.includes(file.type)) {
      enqueueSnackbar("Please upload only supported formats: JPEG, PNG", {
        variant: "error",
      });
      return;
    }

    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        profilePic: file,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "email") {
      const emailParts = value.split("@");
      if (emailParts?.length === 2) {
        setEmailDomain(emailParts[1]);
      } else {
        setEmailDomain("");
      }
    }
  };

  const handleBlur = () => {
    const trimmedInput = websiteInput.trim();
    if (trimmedInput) {
      const websiteArray = trimmedInput
        .split(",")
        .map((website) => website.trim())
        .filter((website) => website !== "");

      for (let item of websiteArray) {
        if (
          !item.match(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/)
        ) {
          enqueueSnackbar("Please enter valid website", {
            variant: "error",
          });
          return;
        }
      }
      const newWebsites = [...formData.websites, ...websiteArray];
      setWebsites(newWebsites);
      setFormData((prevState) => ({
        ...prevState,
        websites: newWebsites,
      }));
      setWebsiteInput("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleBlur();
    }
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    setFormData((prevState) => ({
      ...prevState,
      mobile: value,
    }));
  };

  const handleChangeTwoFa = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      twoFAMethod: e.target.value,
    }));
  };

  function isValidEmail(email) {
    const pattern = /^[a-zA-Z0-9-]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  const handleEmailDomainChange = (event) => {
    const domain = event.target.value;
    setEmailDomain(domain);
    setFormData((prevState) => ({
      ...prevState,
      email: `${prevState.email.split("@")[0]}@${domain}`,
    }));
  };

  const checkValidation = () => {
    const requiredFields = {
      firstName: "First name is required",
      lastName: "Last name date is required",
      emailId: "Email is required",
      mobile: "Mobile is required",
      twoFAMethod: "2FA is required",
    };
    for (const field of Object.keys(requiredFields)) {
      if (!formData[field]) {
        enqueueSnackbar(requiredFields[field], { variant: "warning" });
        return false;
      }
    }

    // if (!isValidEmail(formData.emailId)) {
    //   enqueueSnackbar("Please enter valid email", { variant: "warning" });
    //   return false;
    // }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault()
    if (checkValidation()) {
      // console.log('clientDetail',clientDetail)
      setShowLoader(true);
      let payload = new FormData();
      for (const key in formData) {
        if (formData.hasOwnProperty(key) && formData[key]) {
          payload.append(key, formData[key]);
        }
      }

      try {
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          {},
          "refreshToken"
        );
        console.log('refress token', refreshToken)
        if (refreshToken) {
          await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_UPDATE_PROFILE}`,
            payload
          )
            .then((resChart) => {
              enqueueSnackbar(resChart.data?.message, { variant: "success" });
              setShowLoader(false);
              setFormData({});
              navigate(-1);
            })
            .catch((errChart) => {
              enqueueSnackbar(errChart.message, { variant: "error" });
              setShowLoader(false);
            });
        } else {
          setShowLoader(false);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
        setShowLoader(false);
      }
    }
  }

  return (
    <>

      <form className="profile-form" autoComplete="off" >
        <Card sx={{ mt: 1, mb: 5, width: "600px" }}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} lg={2} className="center-upload">
                <div>
                  <Box
                    component="img"
                    alt="Profile Image"
                    src={
                      typeof formData.profilePic === "string"
                        ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${formData?.profilePic}`
                        : formData.profilePic
                          ? URL.createObjectURL(formData.profilePic)
                          : UserProfile
                    }
                  />
                </div>
                <Button
                  htmlFor="file-upload"
                  size="small"
                  variant="contained"
                  color="primary"
                  component="label"
                  className="upload-new-plus"
                  title="Select Image"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img src={Upload} alt="icon" />
                    <span>Upload Profile</span>
                  </div>
                </Button>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </Grid>

              <Grid item xs={12} md={10} lg={10}>
                <Stack spacing={2}>
                  <Grid container spacing={2}>


                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <TextField
                        name="firstName"
                        value={formData.firstName || ""}
                        label="First Name"
                        inputProps={{ maxLength: 15 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <TextField
                        name="lastName"
                        label="Last Name"
                        value={formData.lastName || ""}
                        inputProps={{ maxLength: 15 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <FormControl fullWidth>
                        <TextField
                          name="emailId"
                          value={formData.emailId}
                          label="Email"
                          onChange={handleInputChange}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {formData.websites?.length > 0 && (
                                  <Select
                                    value={emailDomain}
                                    onChange={handleEmailDomainChange}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Email Domain",
                                    }}
                                    className="companyInfo-input-adornment"
                                  >
                                    <MenuItem value="">Select Domain</MenuItem>
                                    {formData.websites.map((website, index) => (
                                      <MenuItem key={index} value={website}>
                                        {`@${website}`}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <MuiPhoneNumber
                        InputComponent={(params) => <TextField {...params} />}
                        variant="outlined"
                        label="Phone Number"
                        defaultCountry={"us"}
                        value={phoneNumber}
                        onChange={(value) => {
                          handlePhoneChange(value);
                        }}
                        disableAreaCodes={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          className="gender"
                          sx={{
                            // color: "#242526",
                            fontFamily: "Poppins",
                            width: "250px",
                          }}
                        >
                          2FA Method :{" "}
                        </Box>
                        <FormControl>
                          <RadioGroup
                            row
                            name="2fa-method"
                            value={formData?.twoFAMethod}
                            onChange={handleChangeTwoFa}
                          >
                            <FormControlLabel
                              value="email"
                              control={<Radio />}
                              label="Email"
                            />
                            <FormControlLabel
                              value="sms"
                              control={<Radio />}
                              label="SMS"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
                <br />
                <div style={{ display: "flex", justifyContent: "end", gap: "3%" }}>
                  <Button
                    variant="contained"
                    className="edit-myaccount-button"
                    onClick={() => navigate("../profile-detail")}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="success"
                    className="edit-myaccount-button"
                    onClick={onSubmit}
                  >
                    Save
                  </LoadingButton>

                </div>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </form>
    </>
  );
};

export default UpdateProfile;
