import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import {
  Box,
  Card,
  FormControl,
  NativeSelect,
} from "@mui/material";
// components
import { useChart } from "../../../components/chart";
import CircularProgress from '@mui/material/CircularProgress';
// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({
  loading,
  title,
  subheader,
  chartLabels,
  chartData,
  totalIssuesChange,
  option,
  ...other
}) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "10%" } },
    fill: { type: chartData.map((i) => i.fill), colors: ["#8D75B3"] },
    colors: ["#4A10A4"],
    labels: chartLabels,
    xaxis: {
      // Add this configuration to hide the x-axis line
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      line: {
        show: false,
      },
      grid: {
        show: true, // Ensure grid lines are enabled (if you need other gridline settings)
        borderColor: 'red', // Color of grid lines (optional)
        strokeDashArray: 0, // Solid grid lines (optional)
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
        xaxis: {
          lines: {
            show: false // Hides x-axis grid lines
          }
        },
        yaxis: {
          lines: {
            show: false // Hides y-axis grid lines
          }
        }
      },
    },
    // xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} issues`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <div className="flex-total-issues">
       {/* <div className="d-flex-checks">
         <div className="inline-form-check checkbox-outline">
          <label className="custom-checkbox">
            <input type="checkbox" id="flexCheckDefault1"/>
            <span className="checkmark"></span>
             <span className="assessment-datacheck">Assessment</span>
           </label>
           </div>
        <div className="inline-form-check checkbox-outline second-color-g">
            <label className="custom-checkbox">
            <input type="checkbox" id="flexCheckDefault1"/>
            <span className="checkmark"></span>
            <span className="assessment-datacheck">Score</span>
           </label>
           </div>
        <div className="inline-form-check checkbox-outline third-color-g">
          <label className="custom-checkbox">
            <input type="checkbox" id="flexCheckDefault1"/>
            <span className="checkmark"></span>
            <span className="assessment-datacheck">Issues</span>
            </label>
           </div>
          </div> */}
   
        <div className="total_issues_select_wrap">
          <FormControl sx={{ m: 1.5 }} style={{ width: "150px" }}>
            <NativeSelect
              className="total_issues_select"
              defaultValue={option}
              onChange={totalIssuesChange}
            >
              <option value={30}>Last Month</option >
              <option value={90}>Last 3 Months</option >
              <option value={180}>Last 6 Months</option >
              <option value={365}>Last Year</option >
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "295px" }}>
             <CircularProgress className="loader-custom-class-new" />
          </Box>
        ) : (
          <ReactApexChart
            type="line"
            series={chartData}
            options={chartOptions}
            height={285}
          />
        )}
      </Box>
    </Card>
  );
}
