import React, { useState, useEffect } from 'react';
import {  Button } from "@mui/material";
import HeaderIcon from '../assets/search-icons.svg';
import AIChat from '../assets/ai-chat-icon.svg';
import GenesisAI from '../assets/genesis-logo.svg';
import { useSnackbar } from 'notistack';
import GetRequest from '../components/apiConnections/getRequest';
import './css/globalSearchBar.css';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from 'react-router-dom';
import GlobalSearchData from './GlobalSearchData';
import AILoader from '../AILoader';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "#120A3A",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    height: "80vh",
    overflowY: "auto",
};

export default function GlobalSearchBar({ isFullPage, searchInputData, onSearchChange }) {
    // console.log("isFullPage", isFullPage)
    const [suggestions, setSuggestions] = useState([]);
    const [modalOpen, setModalOpen] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false)
    const [prompt, setPrompt] = useState('');
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();

    const searchOpenAiChatGPT = () => {
        setLoading(true);
        setShowLoader(true);
        console.log("calling searchOpenAiChatGPT")
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SEARCH_OPEN_AI_CHAT_GPT}?prompt=${prompt}`;

        GetRequest(url, {})
            .then((response) => {
                setModalOpen(response?.data.data);
                setLoading(false);
                setShowLoader(false);
            })
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: "error" });
                setLoading(false);
                setShowLoader(false);
            });
    };

    const globalSearchAPI = () => {
        setLoading(true);

        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_GLOBAL_SEARCH}?search=${search}`;

        GetRequest(url, {})
            .then((response) => {

                if (Array.isArray(response.data.data)) {
                    const fetchedSuggestions = response.data.data.map(item => ({
                        matchedSnippet: item.matchedSnippet,
                        matchedFieldValue: item.matchedFieldValue
                    }));
                    // fetchedSuggestions.push({
                    //     matchedSnippet: "Ask to Genesis AI Assistant"
                    // });
                    setSuggestions(fetchedSuggestions);
                    setLoading(false);
                }
            })
            .catch((error) => {
                const fallbackSuggestions = [{
                    matchedSnippet: "Ask to Genesis AI Assistant"
                }];

                setSuggestions(fallbackSuggestions);
                setLoading(false);
            });
    };

    const handleSuggestionClick = (suggestion) => {
        if (!isFullPage) {
            navigate(`/dashboard/global-full-search-input?search=${search}`);
        }
        if (suggestion === "Ask to Genesis" || suggestion.matchedSnippet === "Ask to Genesis AI Assistant") {
            searchOpenAiChatGPT();
            setModalOpen('');
        } else {
            setModalOpen(suggestion.matchedFieldValue);
        }
    };

    const handleCloseButton = () => {
        setModalOpen(null);
    };

    const cleanText = (text) => {
        return text?.replace(/[^a-zA-Z0-9 ]/g, '');
    };

    useEffect(() => {
        const querySearch = searchParams.get('search');
        setModalOpen('');
        if (querySearch) {
            setSearch(querySearch);
            setPrompt(querySearch);
        }
        if (querySearch?.length > 0 || search?.length > 0 || searchInputData?.length > 0) {
            globalSearchAPI(querySearch || search || searchInputData);
        }
    }, [searchParams, search, searchInputData]);

    const handleSearchInputChange = (event) => {
        const inputValue = event.target.value;
        setSearch(inputValue);
        setPrompt(inputValue);
        if (onSearchChange) {
            onSearchChange(inputValue);
        }
    };

    const handleIconClick = () => {
        navigate(`/dashboard/global-full-search-input?search=${search}`);
    }
    const cssValue = isFullPage ? "suggestions-list" : "bar-suggestions-list";
    return (
        <>
            <AILoader show={showLoader} />
            <div className='relative-search sparkle-search-icon'>
                <div className='max-inputai-data'>
                    <input
                        className={`input-header-search ${(search?.length > 0 || prompt?.length > 0) ? 'input-header-search-active' : ''}`}
                        type='text'
                        placeholder='Search'
                        value={search || prompt || searchInputData}
                        onChange={handleSearchInputChange}

                    />
                    {isFullPage ? (
                        <img onClick={handleIconClick} src={HeaderIcon} className='topdetail-searchbar' alt='icon' />
                    ) : null}
                </div>


                {!isFullPage ? (
                    <img onClick={handleIconClick} src={HeaderIcon} alt='icon' />
                ) : null}

                {isFullPage ? (
                    <>
                        <div className='ai-genesis-top-button'>
                            <Button variant="contained" className="ask-genesis-ai" onClick={() => handleSuggestionClick({ matchedSnippet: 'Ask to Genesis AI Assistant' })}> <img src={AIChat} alt='icon' /> Ask Genesis AI </Button>
                        </div>
                        {!modalOpen &&
                            < div className="ask-genesis-help">
                                <h3>Still can't find what you're looking for?</h3>
                                <p>Ask Genesis AI to help you find more specific information or provide further assistance with
                                    your search.</p>
                                <img src={GenesisAI} alt='logo' />
                            </div>
                        }
                    </>
                ) : null}

                {!modalOpen && search?.length > 0 && suggestions?.length > 0 && (

                    <ul className={cssValue}>
                        {/*  <ul className="suggestions-list"> */}
                        {suggestions.map((suggestion, index) => (
                            (suggestion.matchedSnippet || suggestion.matchedFieldValue) && (
                                <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                    {suggestion.matchedSnippet === "Ask to Genesis AI Assistant" ? (
                                        <></>
                                        // <span>
                                        //     <img
                                        //         className="sparkle-icon"
                                        //         width="15px"
                                        //         height="15px"
                                        //         src={SparkleIcon}
                                        //         alt="icon"
                                        //         style={{ verticalAlign: 'middle', marginRight: '4px' }}
                                        //     />
                                        //     <b>{cleanText(suggestion.matchedSnippet?.substring(0, 5)) + '...'}</b>
                                        // </span>
                                    ) : (
                                        <span>
                                            {isFullPage ? (

                                                <>
                                                    <div className='search-heading-ai-text'>{cleanText(suggestion.matchedSnippet?.substring(0, 100)) + '...'}</div>
                                                    <div className='peragraphai-data'>{cleanText(suggestion.matchedFieldValue?.substring(0, 300)) + '...'}</div>
                                                </>
                                            ) : (
                                                <div className='left-listai-suggest'>{cleanText(suggestion.matchedSnippet?.substring(0, 30)) + '...'}</div>
                                            )}

                                        </span>
                                    )}
                                </li>
                            )
                        ))}
                    </ul>

                )
                }
                {/* <p className='select-type-search-text'>Data Life Cycle Managment</p> */}
            </div >
            {modalOpen && <GlobalSearchData
                data={modalOpen}
            />}
            {/* <Modal
                open={modalOpen !== null}
                onClose={() => setModalOpen(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme == "Dark"
                        ? "dark-attack-modal modal-popup"
                        : "light-attack-modal modal-popup"
                }
            >
                <Box sx={style} className="black-bg attackpart">
                    <CloseIcon
                        onClick={handleCloseButton}
                        style={{ position: 'absolute', right: 15, top: 15, cursor: 'pointer' }}
                    />
                    {loading ? (
                        <>
                            <CircularProgress size={12} sx={{ marginLeft: "15px" }} />
                            <span style={{ marginLeft: "8px" }}>Loading...</span>
                        </>
                    ) : (
                        (typeof modalOpen === 'string' || modalOpen) ? (
                            <Markdown value={modalOpen?.data || modalOpen} />
                        ) : null
                    )}

                </Box>
            </Modal> */}
        
        </>
    );
}
