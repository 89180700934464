import React from "react";
import {
  Box,
  Button,
  IconButton,
  Grid,
  Container,
  Tabs,
  Tab,
  TableRow,
  TableCell,
  TableContainer,
  Card,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NewHeader from "../../layouts/dashboard/header/NewHeader";
import "../../pages/css/newVendor.css";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PostRequest from "../../components/apiConnections/postRequest";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useSnackbar } from "notistack";
import { changeEcosystemTab } from "../../redux/activeTabSlice";
import GetRequest from "../../components/apiConnections/getRequest";
import Loader from "../../Loader";
import Breadcrumb from "../VendorDetail/Breadcrumb";
import OverviewHomeAdmin from "./OverviewHomeAdmin";
import IssuesAdmin from "./IssuesAdmin";
import CveAdmin from "./CveAdmin";

function VendorDetailsPageAdmin() {
  const location = useLocation();
  const [mainTabValue, setMainTabValue] = useState(location.state?.mainTabValue || 0);
  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ecosystemActiveTab = useSelector((state => state.activeTab?.ecosystem));
  const theme = useTheme();
  const modeClass =
    theme.palette.mode === "light" ? "light-mode-class" : "dark-mode-class";
  const data = useParams();
  // const [low, setLow] = useState(0);
  const [domainName, setDomainName] = useState("-");
  const [vendorName, setVendorName] = useState("-");
  const [hosting, setHosting] = useState("--");
  const [address, setAddress] = useState("--");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [dnsGrade, setDnsGrade] = useState("-");
  const [breachesGrade, setBreachesGrade] = useState("-");
  const [encryptionGrade, setEncryptionGrade] = useState("-");
  const [misconfigrationGrade, setMisconfigrationGrade] = useState("-");
  const [patchingGrade, setPatchingGrade] = useState("-");
  const [riskScore, setRiskScore] = useState(0);
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [urlLinked, setUrlLinked] = useState('');
  // const [reletionShipRisk, setReletionShipRisk] = useState('');
  const [overallGrade, setOverallGrade] = useState("-")
  const [vendorDetailsData, setVendorDetailsData] = useState();
  const [misconfigrationOldScore, setMisconfigrationOldScore] = useState(0);
  const [encryptionOldScore, setEncryptionOldScore] = useState(0);
  const [dnsOldScore, setDnsOldScore] = useState(0);
  const [breachesOldScore, setBreachesOldScore] = useState(0);
  const [patchingOldScore, setPatchingOldScore] = useState(0);
  const [overallOldScore, setOverallOldScore] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);


  const handleAnswerClick = (id, email, event) => {
    event.preventDefault();
    navigate(`/dashboard/view-answer-page/${id}`, { state: { email: email, prevUrl: `vendorDetails/${vendorUrl}` } });
  };

  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [sumScore, setSumScore] = useState("");
  const [complianceLength, setComplianceLength] = useState(0);
  const [complianceCardData, setComplianceCardData] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [error, setError] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [isPublicUrl, setIsPublicUrl] = useState("");

  useEffect(() => {
    if (location.state?.mainTabValue !== undefined) {
      setMainTabValue(location.state.mainTabValue);
    }
    navigate(location.pathname, { replace: true });
  }, [location.state, navigate]);

  useEffect(() => {
    getVendorDetails();
    getVendorComplianceCard();
  }, []);


  const getVendorDetails = async () => {
    setMainLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          // `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_SINGLE_VENDOR_DETAILS_BY_ADMIN}`,
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"get-single-vendor-detail-by-admin"}?domain=${vendorUrl}`
          // vendorPayload
        )
          .then((res) => {
            res.data.data.vendorData = res?.data?.data;
            var vendorTag = res.data.data?.vendorData?.vendorType;
            setVendorDetailsData(res.data?.data);
            if (!vendorTag) {
              vendorTag = "Vendor";
            }
            setIsPublicUrl(res.data.data?.vendorData?.thirdPartyPublicUrl)
            setVendorType(vendorTag);
            setUrlLinked(res.data.data?.urlLink);
            setDomainName(res.data.data?.vendorData?.vendorInfo?.domain);
            setVendorName(res.data.data?.vendorDetail?.name);
            // setReletionShipRisk(res.data.data?.vendorDetail?.relationshipRisk);
            setOverallGrade(res.data.data?.vendorData?.vendorInfo?.grade[0]?.Overall_score);
            setRiskScore(
              res.data.data?.vendorData?.vendorInfo?.score[0]?.Overall_score
            );
            console.log("Risk Score ", res.data.data?.vendorData?.vendorInfo?.score[0]?.Overall_score)
            setAddress(res.data.data?.vendorData?.vendorInfo?.ip);
            setHosting(res.data.data?.vendorData?.vendorInfo?.hosting);
            setLogo(
              res.data.data?.vendorData?.vendorInfo?.logo ||
              "/assets/images/avatars/user.png"
            );
            setDns(res.data.data?.vendorData?.vendorInfo?.count[0].Dns);
            setBreaches(
              res.data.data?.vendorData?.vendorInfo?.count[0]?.Breaches
            );
            setEncryption(
              res.data.data?.vendorData?.vendorInfo?.count[0]?.Encryption
            );
            setMisconfigration(
              res.data.data?.vendorData?.vendorInfo?.count[0]?.Misconfiguration
            );
            const vendorInfo = res.data.data?.vendorData?.vendorInfo;
            const dataMatch = [
              "breaches",
              "dns",
              "encryption",
              "misconfiguration",
              "patching",
            ];
            let totalLow = 0;
            let totalMedium = 0;
            let totalHigh = 0;
            let totalCritical = 0;
            let patchingissues = 0;

            Object.entries(vendorInfo).map((key, valuevendorInfo) => {
              if (dataMatch.includes(key[0])) {
                const dataArr = key[1];
                dataArr.map((value) => {
                  if (key[0] === "patching") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                      patchingissues = patchingissues + 0;
                    } else {
                      const arrPatch = value?.cve;
                      arrPatch.map((ele) => {
                        patchingRows.push({
                          ...issuespatchingList(
                            !ele.CVE ? "-" : ele.CVE,
                            ele.Description || ele.Desc,
                            String(ele?.Severity)
                              .replace(/<[^>]*>/g, "")
                              .replaceAll('"', "")
                              .trim()
                              .toLowerCase(),
                            "View",
                            ele,
                            ele.Score
                          ),
                          affectedUrl: value.Host,
                        });
                        patchingissues++;
                        totalLow =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "low"
                            ? totalLow + 1
                            : totalLow;
                        totalMedium =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "medium"
                            ? totalMedium + 1
                            : totalMedium;
                        totalHigh =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "high"
                            ? totalHigh + 1
                            : totalHigh;
                        totalCritical =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "critical"
                            ? totalCritical + 1
                            : totalCritical;
                      });
                    }
                  } else if (key[0] === "dns") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          value?.URL || []
                        )
                      );
                    }
                  } else {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          value?.URL || []
                        )
                      );
                    }
                  }
                });
              }
              if (key[0] === "count") {
                setTotalScore(key[1][0].Overall_count);
              }
            });
            setPatchingRowsData(patchingRows);
            setIssuesListData(issuesRows);
            setTotalLow(totalLow);
            setTotalMedium(totalMedium);
            setTotalHigh(totalHigh);
            setTotalCritical(totalCritical);
            setPatching(res.data.data.vendorData?.vendorInfo?.count[0]?.Patching);
            // Score
            setDnsScore(res.data.data.vendorData?.vendorInfo?.score[0]?.Dns);
            setBreachesScore(
              res.data.data.vendorData?.vendorInfo?.score[0]?.Breaches
            );
            setEncryptionScore(
              res.data.data.vendorData?.vendorInfo?.score[0]?.Encryption
            );
            setMisconfigrationScore(
              res.data.data.vendorData?.vendorInfo?.score[0]?.Misconfiguration
            );
            setPatchingScore(
              res.data.data.vendorData?.vendorInfo?.score[0]?.Patching
            );

            if (res.data.data.vendorData?.vendorInfo?.oldScore) {
              setDnsOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Dns);
              setBreachesOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Breaches);
              setEncryptionOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Encryption);
              setMisconfigrationOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Misconfiguration);
              setPatchingOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Patching);
              setOverallOldScore(res.data.data.vendorData?.vendorInfo?.oldScore[0]?.Overall_score)
            }

            if (res.data?.data?.vendorData?.vendorInfo?.grade) {
              setDnsGrade(res.data.data?.vendorData?.vendorInfo?.grade[0]?.Dns);
              setBreachesGrade(
                res.data.data.vendorData?.vendorInfo?.grade[0]?.Breaches
              );
              setEncryptionGrade(
                res.data.data.vendorData?.vendorInfo?.grade[0]?.Encryption
              );
              setMisconfigrationGrade(
                res.data.data.vendorData?.vendorInfo?.grade[0]?.Misconfiguration
              );
              setPatchingGrade(
                res.data.data.vendorData?.vendorInfo?.grade[0]?.Patching
              );
            }
            // getAssessment();
            setMainLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setError(true);
            setMainLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setError(false);
      setMainLoader(false);
      return false;
    }
  };


  const getVendorComplianceCard = async () => {
    setshowLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_COMPLIANCE_CARD}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          let arr = [];
          for (let i = 0; i < Math.ceil(obj2.length / 3); i++) {
            let count = i * 3;
            arr.push(obj2.slice(count, count + 3));
          }
          setComplianceCardData(arr);
          setshowLoader(false);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Compliance card not find", { variant: "error" });
          setshowLoader(false);
          return false;
        });
    } catch (error) {
      // no data
      // enqueueSnackbar("Compliance card not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const validateURL = (url) => {
    // Regular expression to match the URL format
    const urlRegex =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]{1,63})\.([a-zA-Z0-9-]{1,63})\.([a-zA-Z0-9]{2,6})(?:\/\S*)?$/;

    // Check if the URL matches the regex pattern
    const isValidURL = urlRegex.test(url);

    if (isValidURL) {
      // Split the URL into subdomain, domain, and TLD parts
      const [, subdomain, domain, tld] = url.match(urlRegex);

      // Check if subdomain, domain, and TLD meet the criteria
      if (subdomain && domain && tld) {
        // Check if the URL contains http or https protocol
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
          return true;
        } else {
          return false; // Reject if the URL contains http or https protocol
        }
      } else {
        return false; // Reject if any part of the URL is missing
      }
    } else {
      return false; // Reject if the URL format doesn't match
    }
  };

  function createData(category, issue, grade, score, oldScore) {
    return { category, issue, grade, score, oldScore };
  };

  const rows = [
    createData("Breaches", breaches, breachesGrade, breachesScore, breachesOldScore),
    createData("DNS", dns, dnsGrade, dnsScore, dnsOldScore),
    createData("Encryption", encryption, encryptionGrade, encryptionScore, encryptionOldScore),
    createData(
      "Misconfiguration",
      misconfiguration,
      misconfigrationGrade,
      misconfigrationScore,
      misconfigrationOldScore
    ),
    createData("Patching", patching, patchingGrade, patchingScore, patchingOldScore),
  ];

  function issuesList(name, category, severity, view, ele, refs, urls) {
    return { name, category, severity, view, ele, refs, urls };
  };

  function issuespatchingList(name, category, severity, view, ele, score) {
    return { name, category, severity, view, ele, score };
  };

  const breadcrumbs = [
    { label: 'Ecosystem', path: '/admin/vendors' },
    { label: 'Security', },
  ];

  return (
    <>
      <Helmet>
        <title>Genesis Platform</title>
      </Helmet>
      <Loader show={mainLoader} />
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Security Profile" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
                <Breadcrumb crumbs={breadcrumbs} />
                <h3>Vendor Info</h3>
              </div>
            </div>
          </Grid>
          {domainName !== "" && domainName && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <div className="infor-vendor-detail-box">
                  <div className="domain-detail-platte">
                    <div className="logo-sign">
                      <img src={logo} alt="icon" />
                    </div>
                    <div className="line-right">{vendorName}</div>
                    <div className="line-right">{domainName}</div>
                    <div className="line-right">{address}</div>
                    <div className="line-right">
                      <p>Grade: </p>
                      <span className={`gradClass ${overallGrade}`}>
                        {overallGrade}
                      </span>
                    </div>
                    {/* <div className="line-right right-0">
                      {reletionShipRisk ? (
                        <span className={`tagClass ${getReletionshipTag(reletionShipRisk)}`}>
                          {getReletionshipTag(reletionShipRisk)}
                        </span>
                      ) : (
                        "-"
                      )}
                    </div> */}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Tabs
                  value={mainTabValue}
                  onChange={handleMainTabChange}
                  className="tab-main-heading margin-mui-tabfix"
                  aria-label="basic tabs example"
                >
                  <Tab className="active-color-remove" label="Overview" />
                  <Tab className="active-color-remove" label="Issues" />
                  <Tab className="active-color-remove" label="CVE" />
                  {/* <Tab className="active-color-remove" label="Remediation" />
                  <Tab className="active-color-remove" label="Controls" />
                  <Tab className="active-color-remove" label="AI Assessments" />
                  <Tab className="active-color-remove" label="Documents" /> */}
                </Tabs>
                <Box>
                  {mainTabValue === 0 && (
                    <div className="first_tab">
                      <Box>
                        <OverviewHomeAdmin data={vendorDetailsData}
                          // sumScore={sumScore}
                          totalLow={totalLow}
                          totalCritical={totalCritical}
                          totalMedium={totalMedium}
                          totalHigh={totalHigh}
                          complianceCardData={complianceCardData}
                          rows={rows}
                          totalScore={totalScore}
                          riskScore={riskScore}
                        // complianceLength={complianceLength}
                        // overallOldScore={overallOldScore}
                        />

                      </Box>
                    </div>
                  )}
                  {mainTabValue === 1 &&
                    <div>
                      <Box>
                        <IssuesAdmin
                          issuesListData={issuesListData}
                        />
                      </Box>
                    </div>
                  }
                  {mainTabValue === 2 &&
                    <div>
                      <Box>
                        <CveAdmin
                          patchingRowsData={patchingRowsData}
                        />
                      </Box>
                    </div>
                  }
                </Box>
              </Grid>
            </>)}
          {error && domainName === "" && (
            <Box sx={{ display: "flex" }}>
              <Grid>
                <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                  <h4>No Record for this url</h4>
                </Card>
              </Grid>
            </Box>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default VendorDetailsPageAdmin;
