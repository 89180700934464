import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import FemaleRoundedIcon from '@mui/icons-material/FemaleRounded';
import MaleRoundedIcon from '@mui/icons-material/MaleRounded';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useEffect, useState } from "react";
import UserProfile from "../../../assets/user-profile.svg";
import Upload from "../../../assets/Vectorplus.svg";
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card, Grid, Stack,
  TextField
} from "@mui/material";
// components
import GetRequest from "../../../components/apiConnections/getRequest";
import PostRequest from "../../../components/apiConnections/postRequest";

// ----------------------------------------------------------------------
import { useSnackbar } from 'notistack';
import { setLocalStoarge } from "../../../components/common/allFunctions";
import Loader from "../../../Loader";
import { useNavigate } from 'react-router-dom';

export default function Profile() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [alertData, setAlertData] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [twofa, setTwoFA] = useState('');
  const [company, setCompany] = useState('');
  const [profilePicURL, setProfilePicURL] = useState('default.png');
  const [showLoader, setshowLoader] = useState(false);

  const handleChangeToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setGender(newAlignment);
    }
  };

  const handleChangeTwoFa = (event, newAlignment) => {
    if (newAlignment !== null) {
      setTwoFA(newAlignment);
    }
  };



  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (alertData.showalert) {
      setTimeout(() => {
        setAlertData({});
      }, 3000);
    }
  }, []);

  const getProfile = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_PROFILE_ENDPOINT}`,
        )
          .then((res) => {
            setFirstName(res.data.data.firstName);
            setLastName(res.data.data.lastName);
            setEmail(res.data.data.emailId);
            setProfilePicURL((res.data.data.profilePic === null || res.data.data.profilePic === '' || (!res.data.data.profilePic)) ? profilePicURL : res.data.data.profilePic);
            setDob(res.data.data.dob);
            setGender(res.data.data.gender);
            setTwoFA(res.data.data.twoFA);
            setCompany(res.data.data.company)
            setshowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setshowLoader(false);
          })
      }
    }
    catch (e) {
      enqueueSnackbar("User profile not found", { variant: 'error' });
      setshowLoader(false);
    }
  };

  const handleImageChange = async (e) => {
    e.preventDefault();
    // setshowLoader(true);

    let files = e.target.files[0];
    if (files) {
      let dataImage = new FormData();
      dataImage.append("image", e.target.files[0]);
      const checkImage = e.target.files[0];
      const fileExtension = checkImage.name.replace(/^.*\./, '');
      const fileSize = Math.round((checkImage.size / 1024));
      if (fileExtension === 'png' || fileExtension === 'PNG' || fileExtension === 'jpg' || fileExtension === 'JPG' || fileExtension === 'JPEG' || fileExtension === 'jpeg') {
        if (fileSize <= 2048) {
          try {
            const refreshToken = await PostRequest(
              `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
              {},
              {},
              "refreshToken"
            );
            if (refreshToken) {
              const uploadPicture = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_PROFILE_PIC_ENDPOINT}`,
                dataImage,
                "ImageUpload"
              );
              if (uploadPicture && uploadPicture.status === 200) {
                if (uploadPicture.data.code === 200) {
                  setProfilePicURL(uploadPicture.data.data.filename);
                  // setshowLoader(false);

                } else {
                  enqueueSnackbar("Error in upload image.", { variant: 'error' });
                  // setshowLoader(false);
                }
              } else {
                enqueueSnackbar("Error in upload image.", { variant: 'error' });
                // setshowLoader(false);
              }
            }
          }
          catch (e) {
            enqueueSnackbar("Error in upload image.", { variant: 'error' });
            // setshowLoader(false);
          }
        }
        else {
          enqueueSnackbar("Please upload less than 2 mb file.", { variant: 'error' });
          // setshowLoader(false);

        }
      }
    }
  }
  const do_update = async (e) => {
    setshowLoader(true);
    e.preventDefault();
    // if ((!dob) || dob === null || dob === '' || (dob.length !== 10) || ("2008-01-01" < dob) || ("1948-01-01" > dob)) {
    //   enqueueSnackbar("Please select correct date of birth.(15-75 Age)", { variant: 'error' })
    //   setshowLoader(false);
    //   return false;
    // }
    // if ((!gender) || gender === null || gender === '') {
    //   enqueueSnackbar("Please select gender.", { variant: 'error' })
    //   setshowLoader(false);
    //   return false;
    // }
    if ((!twofa) || twofa === null || twofa === '') {
      enqueueSnackbar("Please select two factor authentication.", { variant: 'error' })
      setshowLoader(false);
      return false;
    }
    if ((!firstName) || firstName === null || firstName === '') {
      enqueueSnackbar("Please enter firstName.", { variant: 'error' })
      setshowLoader(false);
      return false;
    }
    if ((!lastName) || lastName === null || lastName === '') {
      enqueueSnackbar("Please enter lastName.", { variant: 'error' })
      setshowLoader(false);
      return false;
    }
    try {
      let payload = {
        firstName,
        lastName,
        gender,
        dob,
        profilePic: profilePicURL,
        twoFA: twofa,
        company: company
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const resultData = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_PROFILE_ENDPOINT}`,
          payload,
        );
        if (resultData && resultData.status === 200) {
          // update msg
          enqueueSnackbar("Profile updated successfully.", { variant: 'success' })
          const obj = {
            "firstName": firstName,
            "lastName": lastName,
            "profilePic": profilePicURL
          }
          //update local storage
          setLocalStoarge('userDetails', JSON.stringify(obj));

          setTimeout(() => {
            setshowLoader(false);
            window.location.reload(true);
          }, 1500)
        } else {
          enqueueSnackbar("Profile detail not found.", { variant: 'error' })
          setshowLoader(false);
        }
      }

    } catch (error) {
      enqueueSnackbar("Profile not updated.", { variant: 'error' })
      setshowLoader(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    }
    if (name === "lastName") {
      setLastName(value);
    }
    if (name === "emailId") {
      setEmail(value);
    }
    if (name === "dob") {
      setDob(value);
    }
    if (name === "company") {
      setCompany(value);
    }
    //  setGender();
  };


  return (
    <>
      <Loader show={showLoader} />
      <form onSubmit={(e) => { do_update(e) }} className='profile-form' >
      <h2 className="heading-main userprofile" style={{textAlign:"left"}}>User Profile</h2>
        <Card sx={{ p: 2, m: 1, width: "600px" }}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} lg={2} className="center-upload">
                <Box
                  component="img"
                  alt="Profile Image"
                  src={process.env.REACT_APP_BACKEND_BASE_URL +
                    process.env.REACT_APP_STATIC_PATH +
                    profilePicURL
                  }
                />

                <Button htmlFor="file-upload" size="small" variant="contained" color="primary" component="label" className="upload-new-plus" title="Select Image">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={Upload} alt="icon" />
                    <span>Upload New</span>
                  </div>
                </Button>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: 'none' }}
                />
              </Grid>

              <Grid item xs={12} md={10} lg={10}>
                <Stack spacing={2}>
                  <Grid container spacing={2} >
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <TextField
                        name="firstName"
                        value={firstName || ""}
                        label="First Name"
                        onChange={(event) => handleChange(event)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <TextField
                        name="lastName"
                        label="Last Name"
                        value={lastName || ""}
                        onChange={(event) => handleChange(event)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <TextField
                        name="emailId"
                        value={email || ""}
                        label="Email Id"
                        onChange={(event) => handleChange(event)}
                        required={true}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <TextField
                        name="dob"
                        label="DOB"
                        InputLabelProps={{ shrink: true, required: true }}
                        type="date"
                        value={dob || ""}
                        onChange={(event) => handleChange(event)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} pb={1}>
                      <TextField
                        name="company"
                        label="Company"
                        InputLabelProps={{ shrink: true, required: true }}
                        value={company || ""}
                        onChange={(event) => handleChange(event)}
                      />
                    </Grid>

                    <Grid item xs={12} md={3} lg={3} pb={1} className="gender-area">
                      <FormLabel className="gender" >Gender  </FormLabel>
                      <ToggleButtonGroup
                        color="primary"
                        value={gender}
                        exclusive
                        onChange={handleChangeToggle}
                        aria-label="Platform"
                      >
                        <ToggleButton value="M" title="Male"><MaleRoundedIcon /></ToggleButton>
                        <ToggleButton value="F" title="Female"><FemaleRoundedIcon /></ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} pb={1} className="gender-area" style={{ display: "flex", justifyContent: "right" }} >
                      <FormLabel className="gender" >Two FA</FormLabel>
                      <ToggleButtonGroup
                        color="primary"
                        value={twofa}
                        exclusive
                        onChange={handleChangeTwoFa}
                        aria-label="Platform"
                      >
                        <ToggleButton value="1" title="Enable"><CheckRoundedIcon /></ToggleButton>
                        <ToggleButton value="0" title="Disable"><ClearRoundedIcon /></ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>

                  </Grid>
                </Stack>
                <br />
                <Box sx={{ display: "flex", justifyContent:"right", gap:"22px" }}>
                  <Button size="medium" variant="contained"  className="edit-myaccount-button" onClick={() => navigate(-1)}>
                    Back
                  </Button>
                  <LoadingButton size="medium" type="submit" variant="contained"  className="edit-myaccount-button">
                    Update
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </form>
    </>
  );
}
