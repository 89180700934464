import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Container,
  Dialog,
} from "@mui/material";
import NewHeader from "../layouts/dashboard/header/NewHeader";
import Breadcrumb from "../pages/VendorDetail/Breadcrumb.js";
import ImageEllipse from "../assets/Ellipse3368.png";
import GIF from "../assets/ANIMATION GENESIS.gif";
import AITyping from "../assets/AITyping.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { useLocation } from 'react-router-dom';
import GetRequest from '../components/apiConnections/getRequest.js';
import Markdown from 'marked-react';

function GenesisAiSinglePage() {
  const [loading, setLoading] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [data, setData] = useState(false);
  const { domain: domain } = location.state;
  const [visibleText, setVisibleText] = useState('');
  const [showAITypring, setShowAITypring] = useState(false);
  const popUpRef = useRef(null);
  const [dataType, setDataType] = useState('');

  useEffect(() => {
    if (!modelOpen) {
      setVisibleText('');
      setData({});
    }
  }, [modelOpen]);

  useEffect(() => {
    let timeouts = [];
    if (modelOpen && loading == false && data.soc2Risk || data.overallRisk) {

      console.log("data 123", data)

      const textToDisplay = data.soc2Risk || data.overallRisk;

      // Remove the text like【1:1†vendorDocumentFiles_1.pdf】 and 【4:4†source】
      let cleanedText = textToDisplay?.replace(/【\d+:\d+†vendorDocumentFiles_\d+\.pdf】/g, '');
      cleanedText = cleanedText?.replace(/【\d+:\d+†[^\s]+】/g, '')?.replace(/{{.*?}}/g, "")?.replace(/【.*?】/g, "");

       // Replace the **Vendor Name** field with the provided vendor name
      let updatedText = cleanedText.replace(/(\*\*(?:Vendor|Organization) Name\*\*:)(.*?)(?=\n)/, `$1 ${domain}`);
      const words = updatedText.split(' ');
      setVisibleText('');

      words.forEach((word, index) => {
        setShowAITypring(true);
        const timeout = setTimeout(() => {
          setVisibleText((prev) => `${prev} ${word}`);

          // Auto-scroll to the bottom of the pop-up
          if (popUpRef.current) {
            popUpRef.current.scrollTop = popUpRef.current.scrollHeight;
          }

          if (index === words.length - 1) {
            setShowAITypring(false);
          }
        }, index * 50);
        timeouts.push(timeout);
      });


    }
    return () => timeouts.forEach((timeout) => clearTimeout(timeout));
  }, [modelOpen, data, loading]);

  const handleClose = () => {
    if(!loading){
      setVisibleText('');
      setData({});
      setLoading(false);
      setModelOpen(false);
      setDataType('');
    }
  };
  const handleOutsideClick = (e) => {
    if (loading) {   
      e.stopPropagation();
      enqueueSnackbar("Kindly wait, AI is generating the report!", { variant: "info" });
    } 
  };
  const breadcrumbs = [
    { label: "Ecosystem", path: "/dashboard/vendors" },
    { label: "Security", path: `/dashboard/vendorDetails/${domain}` },
    { label: "AI Reports" },
  ];
  const getVendorOverallDocAnalysis = () => {
    setDataType('overallRisk');
    setModelOpen(true);
    setLoading(true);
    const minLoadingTime = 5000;
    const startTime = Date.now();
    GetRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_OVERALL_RISK_ASSESSMENT}?domain=${domain}`,
      {}
    )
      .then((response) => {
        setData(response?.data?.data);   
        const remainingTime = Date.now() - startTime;
        if (remainingTime < minLoadingTime) {
          setTimeout(() => {
            setLoading(false);
          }, minLoadingTime - remainingTime);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setModelOpen(false);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  const getSOC2ReportAnalysis = () => {
    setDataType('soc2Risk');
    setModelOpen(true);
    setLoading(true);
    const minLoadingTime = 5000;
    const startTime = Date.now();
    GetRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SOC2_RISK_ASSESSMENT}?domain=${domain}`,
      {}
    )
      .then((response) => {
          setData(response?.data?.data);
        const remainingTime = Date.now() - startTime;
        if (remainingTime < minLoadingTime) {
          setTimeout(() => {
            setLoading(false);
          }, minLoadingTime - remainingTime);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setModelOpen(false);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  }
  return (
    <>
      <Helmet>
        <title> Genesis Ai | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Security Profile" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
                <Breadcrumb crumbs={breadcrumbs} />
                <h3>Vendor Info</h3>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div className="allgenesis-ai-list">
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                  <div className="soc-grid-boxes" onClick={getSOC2ReportAnalysis} >
                    <div className="imgallgenesis">
                      <img src={ImageEllipse} />
                    </div>
                    <h3>SOC 2 Gap Assessment</h3>
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3} onClick={getVendorOverallDocAnalysis}>
                  <div className="soc-grid-boxes">
                    <div className="imgallgenesis">
                      <img src={ImageEllipse} />
                    </div>
                    <h3>Overall Assessment</h3>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Dialog className='gif-boxes' open={modelOpen} onClick={handleOutsideClick}
          onClose={() => setModelOpen(false)}>
          <div className='assessing-overall-ai'>
            <div className="show-complaince-popup remove-line-type" style={{ margin: "0px", padding: "0px" }}>
              <h3></h3>
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </div>
            {loading ? <img src={GIF} width={80} className='text-center-gifimage' /> : (
              <>
                {dataType === "soc2Risk" && data?.soc2Risk && (
                  <div className="typing-effect"
                    ref={popUpRef} style={{
                      maxHeight: '80vh',
                      overflowY: 'auto'
                    }}
                  >
                    <Markdown>{visibleText}</Markdown>
                  </div>
                )}

                {dataType === "overallRisk" && data?.overallRisk && (
                  <div className="typing-effect"
                    ref={popUpRef} style={{
                      maxHeight: '80vh',
                      overflowY: 'auto'
                    }}
                  >
                    <Markdown>{visibleText}</Markdown>
                  </div>
                )}
              </>
            )}

            {showAITypring ? <img src={AITyping} /> : null}
            <p style={{ textAlign: "center", width: "100%" }}>Powered by <span className='color-gradient-aai'>Genesis AI</span></p>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default GenesisAiSinglePage;
