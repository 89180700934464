import React, { useState } from "react";
import {
  Modal,
  Box,
  Card,
  Grid,
  TextField,
  FormControl,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import PostRequest from "../apiConnections/postRequest";
import "../addVendorByClient/addVendorByClient.css";
import { useNavigate } from "react-router-dom";
import CrossPop from "../../assets/pop-cross.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
};

export default function DomainSearch({ open, handleClose }) {
  const theme = localStorage.getItem("theme");
  const navigate = useNavigate();
  const [newDomain, setNewDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [jsonAvailable, setJsonAvailable] = useState(null);
  const [vendorStatus, setVendorStatus] = useState("Onboarding");
  const inputChange = (e) => {
    setNewDomain(e.target.value);
  };
  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    setVendorStatus(selectedValue);
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const search = newDomain;
      if (
        new RegExp(
          "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
        ).test(search)
      ) {
        const payload = {
          requestUrl: search,
        };
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          {},
          "refreshToken"
        );
        if (refreshToken) {
          const searchResult = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_VENDOR_URL}`,
            payload
          );
          setLoading(false);
          const searchData = searchResult.data;
          if (!searchData.data?.vendorDetailAvailable) {
            navigate(`/dashboard/add-new-vendor`, {
              state: {
                domain: search,
                vendorStatus: vendorStatus,
                jsonAvailableData: jsonAvailable,
              },
            });
            setJsonAvailable(searchData.data?.jsonAvailable);
            handleClose();
            setLoading(false);
            return;
          }
          if (searchData.data?.jsonAvailable) {
            const searchURL =
              searchData.data?.requestUrl !== undefined
                ? searchData.data?.requestUrl
                : search;
            navigate(`/dashboard/vendorDetails/${searchURL}`);
            setLoading(false);
          } else {
            navigate(`/dashboard/vendors`);
            setLoading(false);
          }
          handleClose();
        }
      } else {
        enqueueSnackbar("Please enter correct URL.", { variant: "error" });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar("Request URL not find.", { variant: "error" });
      setLoading(false);
    }
    setNewDomain("");
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          theme == "Dark"
            ? "dark-attack-modal modal-popup"
            : "light-attack-modal modal-popup"
        }
      >
        <Box sx={style} className="black-bg">
          <Card sx={{ p: 2, m: 0, width: "100%" }} className="attackpart">
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="add-new-attack-heading">
                    <p>Domain</p>
                    <img
                      onClick={handleClose}
                      src={CrossPop}
                      style={{ cursor: "pointer" }}
                      alt="icon"
                    />
                  </div>
                  <div className="flex-uploadbtn-flex margin-domain-spaces">
                    <Tooltip classes={{ tooltip: 'custom-tooltip' }}
                      title="Select this option to add a completely new vendor who is not currently in our system."
                    >
                      <div
                        className="radio-type-design"
                        onChange={handleRadioChange}
                      >
                        <input
                          type="radio"
                          id="test1"
                          value="Onboarding"
                          name="radio-group"
                          checked={vendorStatus === "Onboarding"}
                        />
                        <label for="test1">New Vendor</label>
                      </div>
                    </Tooltip>
                    <Tooltip
                      classes={{ tooltip: 'custom-tooltip' }}
                      title="Choose this option if the vendor has previously been added to the system, and you want to update or reuse their details."
                      
                    >
                    <div
                      className="radio-type-design"
                      onChange={handleRadioChange}
                    >
                      <input
                        type="radio"
                        id="test2"
                        value="Monitoring"
                        name="radio-group"
                        checked={vendorStatus === "Monitoring"}
                      />
                      <label for="test2">Existing Vendor</label>
                      </div>
                    </Tooltip>
                  </div>

                  <FormControl className="model-field" sx={{ width: "100%" }}>
                    <form onSubmit={formSubmit}>
                      <Box>
                        <label>Enter domain name</label>
                        <Grid xs={12} md={12} lg={12} container>
                          <TextField
                            name="newDomain"
                            value={newDomain || ""}
                            placeholder="xyz.com"
                            onChange={(e) => inputChange(e)}
                            required={true}
                            margin="normal"
                            id="full-width-text-field"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </Box>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        component="button"
                        type="submit"
                        className="submit-domain-btn"
                      >
                        Next
                        {loading ? (
                          <CircularProgress
                            size={12}
                            sx={{ marginLeft: "15px" }}
                          />
                        ) : null}
                      </Button>
                    </form>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      </Modal>
    </>
  );
}
