import React from "react";
import CircularProgressBar from "./CircularProgressBar";

export default function CircularProgress({ percent, title, attackTittle }) {
  // console.log(percent);
  const getTagBasedOnPer = (percent) => {
    return percent >= 0 && percent < 33
      ? "Critical"
      : percent >= 33 && percent < 66
        ? "Medium"
        : percent >= 66 && percent <= 100
          ? "Low"
          : "-"
  }
  return (
    <>
      {(attackTittle) ? (
        <div className="attack-box">
          <h2 className="attack-tittle">{attackTittle}</h2>
          <CircularProgressBar percent={percent} />
        </div>
      ) : (
        <div className="box-Security-control">
          {/* <div className="fit-bottom">
            <h2>{title}</h2>
          </div> */}
          <div className="progress-part">
            <CircularProgressBar percent={percent} />
          </div>
          {title && <div style={{ width: '100%' }}>
            <p><span className={`tagClass ${getTagBasedOnPer(percent)}`}>
              {getTagBasedOnPer(percent)}
            </span></p>
          </div>}
        </div>
      )}

    </>
  );
}
