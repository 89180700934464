import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box, Button, Card, Checkbox, CircularProgress, Container,
  Grid, IconButton, InputAdornment, Menu, MenuItem, Tab, Tabs, TextField, Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UserProfile from "../assets/user-profile.svg";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Iconify from "../components/iconify"
import { textFieldStyles } from "./add-client/subscriptions";
import { useSnackbar } from "notistack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTheme } from "@emotion/react";
import ModalDialog from "./ModalDialog";
import Delete from "../assets/delete.svg";
import { CustomNoRowsOverlay } from "./ClientsManagementPage";
import VendorUploadModal from "./VendorUploadModal";
import GetRequest from "../components/apiConnections/getRequest";
import PostRequest from "../components/apiConnections/postRequest";
import PutRequest from '../components/apiConnections/putRequest';
import ClientUserResetPassword from '../components/modal/ClientUserResetPassword';
import { getLocalStoarge } from "../components/common/allFunctions";
import NewHeader from "../layouts/dashboard/header/NewHeader";
import { Helmet } from "react-helmet-async";
import Chart from "react-apexcharts";
import "../pages/css/guageGraph.css";
import SearchIcon from '../assets/searchico.svg';
import Trash from '../assets/trahs.svg';
import EditIcon from '../assets/edit-icon-myaccount.svg';
import ResetIcon from '../assets/reset-icon.svg';
import Deactivate from '../assets/deactived.svg';

 
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function DataGridLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        overflow: "hidden",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

const ClientMyAccount = () => {
  const [totalAssessment, setTotalAssessments] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [userRows, setUserRows] = useState([])
  const [userColumns, setUserColumns] = useState([
    { field: "id", headerName: "No.", editable: false, maxWidth: 30 },
    {
      field: "logo",
      headerName: "Profile",
      maxWidth: 80,
      flex: 1,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
            <img
              src={
                params.row?.profilePic
                  ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${params.row?.profilePic}`
                  : UserProfile
              }
              // src={UserProfile}
              alt="logo"
              onError={(e) => e.target.src = UserProfile}
            />
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      minWidth: 230,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      editable: false,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "twoFAMethod",
      headerName: "2FA Method",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: `${status === 0 ? "#DC0000" : "#20FF29"}`,
              borderRadius: "3px",
              fontSize:"14px",
              paddingY: "5px",
              paddingX: "15px",
              width: " 112px",
              height: "25px",
              padding: "7px 15px",
              lineHeight:"12px",
              boxShadow: "4px 4px 4px -20px #00000040",
              backgroundColor: `${status === 0 ? "#DC00004D" : "#22FF2B33"}`,
            }}
          >
            {params.row.status === 1 ? "Active" : "In-Active"}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 20,
      sortable: false,
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={(event) => handleUserActionClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ])
  const [vendorRows, setVendorRows] = useState([
    {
      id: 1,
      name: "Google",
      target: "google.com",
      score: 50,
      type: "Vendor",
      status: 0,
    }
  ])
  const [vendorColumns, setVendorColumns] = useState([
    { field: "id", headerName: "No.", editable: false, maxWidth: 60 },
    {
      field: "name",
      headerName: "Name",
      editable: false,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "target",
      headerName: "Target",
      editable: false,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "risk",
      headerName: "Risk",
      editable: false,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const score = params.row.score;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              borderRadius: "3px",
              paddingY: "5px",
              paddingX: "15px",
              backgroundColor: `${score <= 25
                ? "red"
                : score <= 50
                  ? "#ff7300"
                  : score <= 75
                    ? "#f9a825"
                    : "green"
                }`,
            }}
          >
            {score <= 25
              ? "Critical"
              : score <= 50
                ? "High"
                : score <= 75
                  ? "Medium"
                  : "Low"}
          </Box>
        );
      },
    },
    {
      field: "score",
      headerName: "Score",
      editable: false,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const score = params.row.score;
        return <Box>{score}</Box>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      editable: false,
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              borderRadius: "3px",
              paddingY: "5px",
              paddingX: "15px",
              backgroundColor: `${status === 0 ? "#f9a825" : "green"}`,
            }}
          >
            {params.row.status === 1 ? "Fulfilled" : "In-Progress"}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 20,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={(event) =>
                handleVendorActionClick(event, params.row)
              }
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ])
  const [subscriptionsData, setSubscriptionsData] = useState({
    numberOfVendors: 0,
    numberOfUsers: 0,
    numberOfWebsites: 0,
    isQuestionniareEnabled: 0,
    numberOfQuestionniare: 0,
    isAttackRequestEnabled: 0,
    numberOfAttackRequests: 0,
    isAssessmentEnabled: 0,
    numberOfAssessments: 0,
  });
  const [selectedUserRow, setSelectedUserRow] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedVendorRow, setSelectedVendorRow] = useState(null);
  const [anchorElVendor, setAnchorElVendor] = useState(null);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showDeactivateAccountModal, setShowDeactivateAccountModal] = useState(false);
  const [showVendorUploadModal, setShowVendorUploadModal] = useState(false);
  const [clientDetail, setClientDetail] = useState({
    company: "",
    name: "",
    logo: null,
    clientId:""
  })
  const [userLoader, setUserLoader] = useState(false)
  const [userSearch, setUserSearch] = useState(null)
  const [userPaginationModel, setUserPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const [userRowCount, setUserRowCount] = useState(0);
  const [changeUserStatusLoader, SetChangeUserStatusLoader] = useState(false)
  const [showUserResetPasswordModal, setShowUserResetPasswordModal] = useState(false)
  const [userResetPasswordLoader, setShowResetPasswordLoader] = useState(false)

  

  const [chartData] = useState({
    series: [
      {
        data: [70], // Progress percentage
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 100,
        sparkline: {
          enabled: true, // Hides axes and other chart elements
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${val}%`;
        },
        style: {
          fontSize: "20px",
          colors: ["#fff"],
        },
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: ["Progress"],
        max: 100,
      },
      fill: {
        colors: ["#00E396"],
      },
    },
  });

  const [progressData,setProgressData] = useState([]);

  useEffect(() => {
    getClientData();
    getClientSubscriptionDetail();
  }, [])

  useEffect(() => {
    getClientUsers(userPaginationModel.page + 1, userPaginationModel.pageSize);
  }, [userPaginationModel])

  useEffect(() => {
    let time = setTimeout(() => {
      if (userSearch !== null) getClientUsers(1, userPaginationModel.pageSize);
    }, 500)
    return () => clearTimeout(time)
  }, [userSearch])

  const getClientSubscriptionDetail = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_SUBSCRIPTION_DETAIL}`
        )
          .then((resChart) => {
            let userDetail = resChart.data?.data;
            setProgressData(userDetail)      
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
            // setShowLoader(false);
          });
      } else {
        // setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      // setShowLoader(false);
    }
  };


  const getClientData = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_DATA}?clientId=${getLocalStoarge('userId')}`
        )
          .then((resChart) => {
            let userDetail = resChart.data?.data;
            // setShowLoader(false);
            if (userDetail?._id) {
              let obj = {
                company: userDetail.company,
                name: `${userDetail.firstName} ${userDetail.lastName}`,
                logo: userDetail.profilePic,
                email: userDetail.emailId,
                clientId: userDetail.clientIdString,
              };
              if (userDetail.clientDetail) {
                setSubscriptionsData({ ...userDetail.clientDetail })
              }
              setClientDetail({ ...obj });
            }
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
            // setShowLoader(false);
          });
      } else {
        // setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      // setShowLoader(false);
    }
  };

  const getClientUsers = async (page, limit) => {
    setUserLoader(true);
    setUserRows([])
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_USERS
          }?clientId=${getLocalStoarge('userId')}&page=${page}&limit=${limit}&search=${userSearch || ""}`
        )
          .then((resChart) => {
            let data = resChart.data?.data?.data;

            console.log('dattttt',resChart.data.data)
            setUserRowCount(resChart.data.data?.total);
            setUserRows(
              data.length
                ? [
                  ...data?.map((d, i) => ({
                    ...d,
                    id: i + 1,
                    name: `${d.firstName} ${d.lastName}`,
                    contact: d.mobile || "---",
                    email: d.emailId,
                  })),
                ]
                : []
            );
            setUserLoader(false);
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart?.message || errChart.data?.message, { variant: "error" });
            setUserLoader(false);
          });
      } else {
        setUserLoader(false);
      }
    } catch (error) {
      console.log(error)
      enqueueSnackbar(error.message || "something went wrong", { variant: "error" });
      setUserLoader(false);
    }
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserActionClick = (event, row) => {
    setSelectedUserRow(row);
    setAnchorElUser(event.currentTarget);
  };
  const handleVendorActionClick = (event, row) => {
    setSelectedVendorRow(row);
    setAnchorElVendor(event.currentTarget);
  };

  const handleUserActionClose = () => {
    setSelectedUserRow(null);
    setAnchorElUser(null);
  };

  const handleVendorActionClose = () => {
    setSelectedVendorRow(null);
    setAnchorElVendor(null);
  };

  const handleDecrease = (name) => {
    setSubscriptionsData((prevState) => ({
      ...prevState,
      [name]: Math.max((parseInt(prevState[name]) || 0) - 1, 0),
    }));
  };

  const handleIncrease = (name) => {
    setSubscriptionsData((prevState) => ({
      ...prevState,
      [name]: (parseInt(prevState[name]) || 0) + 1,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSubscriptionsData((prevState) => ({
      ...prevState,
      [name]: checked ? 1 : 0,
    }));
  };

  const changeUserStatusAPI = async (type) => {
    SetChangeUserStatusLoader(true)
    const payload = {
      clientUserId: selectedUserRow._id,
    };
    if (type === "status") payload.status = selectedUserRow.status ? 0 : 1;
    else if (type === "delete") payload.deletedAt = 1
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PutRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_CLIENT_USER_STATUS}`,
          payload,
          {}
        )
          .then((resChart) => {
            SetChangeUserStatusLoader(false);
            setShowDeactivateAccountModal(false);
            setShowDeleteAccountModal(false);
            enqueueSnackbar(resChart.data?.message, { variant: "success" });
            getClientUsers(userPaginationModel.page + 1, userPaginationModel.pageSize);
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message || errChart.data?.message, { variant: "error" });
            SetChangeUserStatusLoader(false);
          });
      } else {
        SetChangeUserStatusLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      SetChangeUserStatusLoader(false);
    }
  };

  const ModalContent = (content) => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <h1>Are you sure? </h1>
        <h3 className="send-assessments">
          {content}
        </h3>
      </Typography>
    );
  };
  const handleUserPaginationModelChange = (newPaginationModel) => {
    setUserPaginationModel(newPaginationModel);
  }
  const onUserFilterChange = (value) => {
    console.log("value", value)
    let searchQuery = value.quickFilterValues[0] || ""
    setUserSearch(searchQuery)
  }
  return (
    <>
      <Helmet>
        <title> My Account | Genesis Platform</title>
      </Helmet>
      {/* company Details */}
      <Container maxWidth={false}>
       <Grid container spacing={2} sx={{ mt: 0 }}>
        <NewHeader titleName="My Account" />
         <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className="d-flex-company-info">
            <div className="d-flex-company-logo">
              <img
                src={
                  typeof clientDetail.logo === "string"
                    ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${clientDetail?.logo}`
                    : UserProfile
                }
                alt="login"
                onError={(e) => e.target.src = UserProfile}
                style={{
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                }}
              />
            </div>
            <div className="align-peragraph-p">
              <h3 className="company">{clientDetail.company}</h3>
              <p>
                <PermIdentityIcon />
                {clientDetail.name}
              </p>
            </div>
          </div>
         </Grid>
         <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
        >
          {tabValue === 0 && (
            <Button
              variant="contained"
              className="edit-myaccount-button"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => navigate("../add-user",{state:clientDetail})}
            >
              Add User
            </Button>
          )}

          {/* {tabValue === 1 && (
            <Button
              variant="contained"
              className="add-user"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setShowVendorUploadModal(true)}
            >
              Add Vendor
            </Button>
          )} */}
        </Grid>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12} md={12} lg={12}>
        <Box >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
              aria-label="basic tabs example"
              className="tab-main-heading margin-mui-tabfix"
          >
            <Tab className="active-color-remove" label={`Users (${userRowCount})`} {...a11yProps(0)} />
            {/* <Tab label={`Vendors (5)`} {...a11yProps(1)} /> */}
            <Tab className="active-color-remove" label={`Subscriptions`} {...a11yProps(2)} />
          </Tabs>
        </Box>
      </Grid>

      {/* Card */}
      <div className="client-users-form">
          <Container maxWidth={false}>
            <Grid item xs={12} md={12} lg={12}>
               <CustomTabPanel value={tabValue} index={0} sx={{ padding: 0 }}>
                 <div className="cover-table" style={{ width: "100%" }}>
                 <Box
                sx={{
                  width: '100%',
                  '& .MuiDataGrid-columnHeaders': {
                    minWidth: '100%',
                    overflowX: 'hidden',
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    minWidth: '100%',
                    overflowX: 'auto',
                  },
                }}
              >
                   <TextField
                    variant="outlined"
                    placeholder="Search…"
                    // value={filterSearch}
                    // onChange={handleSearch}
                    className='searh-text-field'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt='icon' />
                        </InputAdornment>
                      ),
                    }}
                  />
                    <DataGrid
                    rowHeight={70}
                    rows={userRows}
                    columns={userColumns}
                    autosizeOptions={{
                      // columns: ["id", "firstName", "lastName"],
                      includeOutliers: true,
                      includeHeaders: false,
                    }}
                    paginationMode="server"
                    pageSizeOptions={[5, 10]}
                    onPaginationModelChange={handleUserPaginationModelChange}
                    paginationModel={userPaginationModel}
                    rowCount={userRowCount}
                    slots={{
                      toolbar: GridToolbar,
                      noRowsOverlay: CustomNoRowsOverlay,
                      loadingOverlay: DataGridLoader,
                    }}
                    loading={userLoader}
                    filterMode="server"
                    onFilterModelChange={onUserFilterChange}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: false,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                      },
                    }}
                    disableRowSelectionOnClick
                    autoHeight
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                      disableColumnMenu
                      className='datagrid-table'
                      sx={{
                        '& .MuiDataGrid-columnHeaders': {
                          minWidth: '100%', // Column headers take up full width
                        },
                        '& .MuiDataGrid-virtualScroller': {
                          minWidth: '100%', // Rows take up full width
                        },
                      }}
                  />

                  {selectedUserRow && (
                    <Menu
                      id="actions-menu"
                      className='rowmenu'
                      anchorEl={anchorElUser}
                      open={Boolean(anchorElUser)}
                      onClose={handleUserActionClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    >
                        <MenuItem
                          onClick={() =>
                            navigate(`../edit-user/${selectedUserRow._id}` ,{state:clientDetail})
                          }
                          className={
                            theme.palette.mode === "dark"
                              ? "dark-menu-item-btn"
                              : "light-menu-item-btn"
                          }
                        >
                      <img src={EditIcon} alt="icon"/>  Edit User
                      </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // setActiveUser(selectedRow.id);
                            setShowUserResetPasswordModal(true);
                            setAnchorElUser(null);
                          }}
                          className={
                            theme.palette.mode === "dark"
                              ? "dark-menu-item-btn"
                              : "light-menu-item-btn"
                          }
                        >
                       <img src={ResetIcon} alt="icon"/> Reset Password
                      </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setShowDeactivateAccountModal(true);
                            setAnchorElUser(null);
                          }}
                          className={
                            theme.palette.mode === "dark"
                              ? "dark-menu-item-btn"
                              : "light-menu-item-btn"
                          }>
                         <img src={Deactivate} alt="icon"/>  {selectedUserRow.status ? "Deactivate Account" : "Activate Account"}
                      </MenuItem>
                      <MenuItem     onClick={() => {
                            // setActiveUser(selectedRow.id);
                            setShowDeleteAccountModal(true);
                            setAnchorElUser(null);
                          }}
                          className={
                            theme.palette.mode === "dark"
                              ? "dark-menu-item-btn"
                              : "light-menu-item-btn"
                          }>
                        <img src={Trash} alt="icon"/>  Remove Account
                      </MenuItem>
                    </Menu>
                      )}
                      </Box>
                </div>
              </CustomTabPanel>
            </Grid>

            <CustomTabPanel value={tabValue} index={1} className="padding-progressdata">
                  <Card sx={{p:3}}>
                     {progressData.length ? progressData.map((item, index) => (
                      <div key={index}>
                        <h4 className="name-chart-item">{item.name}</h4>
                         <div className="chart-bar-subcription" >
                           <Chart
                              options={{
                              chart: {
                              type: "bar",
                              height: 50,
                              borderRadius: 50,
                              sparkline: {
                                enabled: true, // Hide axes and gridlines
                              },
                            },
                              plotOptions: {
                                bar: {
                                  horizontal: true,
                                  borderRadius: 5,
                                  barHeight: "30%",
                                  distributed: true,
                                },
                              },
                              xaxis: {
                                categories: [''],
                                max: 100,
                              },
                              fill: {
                                colors: [item.color],
                              },
                              tooltip: {
                                enabled: false,
                              },
                            }}
                            series={[{ data: [item.percentage] }]}
                            type="bar"
                            height={39}
                          />
                        </div>
                      </div>
                    )):<h3>No Record Found!</h3>}
                    </Card>
           </CustomTabPanel>

            <CustomTabPanel value={tabValue} index={2}>
              <Grid container spacing={2} sx={{ marginTop: "40px" }}>
                {/* Number Of Vendors */}
                <Grid item xs={12} md={12} lg={5.5}>
                  <Grid container spacing={2} className="align-middle-parts">
                    <Grid item xs={6} md={9} lg={9}>
                      <h5 className="sub-heading-vendor">Number Of Vendors</h5>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        type="number"
                        size="small"
                        name="numberOfVendors"
                        value={subscriptionsData.numberOfVendors}
                        className="quantity-field-input"
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                className="border-left-right"
                              >
                                <IconButton
                                  size="small"
                                  className="quantity-btn border-bottom-btns"
                                  onClick={() =>
                                    handleIncrease("numberOfVendors")
                                  }
                                >
                                  <ArrowDropUpIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  className="quantity-btn"
                                  onClick={() =>
                                    handleDecrease("numberOfVendors")
                                  }
                                >
                                  <ArrowDropDownIcon />
                                </IconButton>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        sx={textFieldStyles}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={1}></Grid>

                {/* Number Of Attack Requests */}
                <Grid item xs={12} md={12} lg={5.5}>
                  <Grid container spacing={2} className="align-middle-parts">
                    <Grid item xs={6} md={9} lg={9}>
                      <h5 className="sub-heading-vendor">
                        Number Of Attack Requests
                      </h5>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <div className="d-flex-checkbox">
                        <Checkbox
                          checked={!!subscriptionsData.isAttackRequestEnabled}
                          onChange={handleCheckboxChange}
                          name="isAttackRequestEnabled"
                          color="primary"
                          className="absolute-checkbox"
                        />
                        <TextField
                          type="number"
                          size="small"
                          name="numberOfAttackRequests"
                          className="quantity-field-input"
                          value={subscriptionsData.numberOfAttackRequests}
                          onChange={handleInputChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="border-left-right"
                                >
                                  <IconButton
                                    size="small"
                                    className="quantity-btn border-bottom-btns"
                                    onClick={() =>
                                      handleIncrease("numberOfAttackRequests")
                                    }
                                  >
                                    <ArrowDropUpIcon />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    className="quantity-btn"
                                    onClick={() =>
                                      handleDecrease("numberOfAttackRequests")
                                    }
                                  >
                                    <ArrowDropDownIcon />
                                  </IconButton>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          sx={textFieldStyles}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Number Of Websites */}
                <Grid item xs={12} md={12} lg={5.5}>
                  <Grid container spacing={2} className="align-middle-parts">
                    <Grid item xs={6} md={9} lg={9}>
                      <h5 className="sub-heading-vendor">Number Of Websites</h5>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        type="number"
                        size="small"
                        name="numberOfWebsites"
                        value={subscriptionsData.numberOfWebsites}
                        className="quantity-field-input"
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                className="border-left-right"
                              >
                                <IconButton
                                  size="small"
                                  className="quantity-btn border-bottom-btns"
                                  onClick={() =>
                                    handleIncrease("numberOfWebsites")
                                  }
                                >
                                  <ArrowDropUpIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  className="quantity-btn"
                                  onClick={() =>
                                    handleDecrease("numberOfWebsites")
                                  }
                                >
                                  <ArrowDropDownIcon />
                                </IconButton>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        sx={textFieldStyles}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={1}></Grid>

                {/* Number Of Questionnaires */}
                <Grid item xs={12} md={12} lg={5.5}>
                  <Grid container spacing={2} className="align-middle-parts">
                    <Grid item xs={6} md={9} lg={9}>
                      <h5 className="sub-heading-vendor">
                        Number Of Questionnaires
                      </h5>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <div className="d-flex-checkbox">
                        <Checkbox
                          checked={!!subscriptionsData.isQuestionniareEnabled}
                          onChange={handleCheckboxChange}
                          name="isQuestionniareEnabled"
                          color="primary"
                          className="absolute-checkbox"
                        />
                        <TextField
                          type="number"
                          size="small"
                          name="numberOfQuestionniare"
                          className="quantity-field-input"
                          value={subscriptionsData.numberOfQuestionniare}
                          onChange={handleInputChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="border-left-right"
                                >
                                  <IconButton
                                    size="small"
                                    className="quantity-btn border-bottom-btns"
                                    onClick={() =>
                                      handleIncrease("numberOfQuestionniare")
                                    }
                                  >
                                    <ArrowDropUpIcon />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    className="quantity-btn"
                                    onClick={() =>
                                      handleDecrease("numberOfQuestionniare")
                                    }
                                  >
                                    <ArrowDropDownIcon />
                                  </IconButton>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          sx={textFieldStyles}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Number Of Users */}
                <Grid item xs={12} md={12} lg={5.5}>
                  <Grid container spacing={2} className="align-middle-parts">
                    <Grid item xs={6} md={9} lg={9}>
                      <h5 className="sub-heading-vendor">Number Of Users</h5>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <TextField
                        type="number"
                        size="small"
                        name="numberOfUsers"
                        value={subscriptionsData.numberOfUsers}
                        className="quantity-field-input"
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                className="border-left-right"
                              >
                                <IconButton
                                  size="small"
                                  className="quantity-btn border-bottom-btns"
                                  onClick={() =>
                                    handleIncrease("numberOfUsers")
                                  }
                                >
                                  <ArrowDropUpIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  className="quantity-btn"
                                  onClick={() =>
                                    handleDecrease("numberOfUsers")
                                  }
                                >
                                  <ArrowDropDownIcon />
                                </IconButton>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        sx={textFieldStyles}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={1}></Grid>

                {/* Number Of Assessments */}
                <Grid item xs={12} md={12} lg={5.5}>
                  <Grid container spacing={2} className="align-middle-parts">
                    <Grid item xs={6} md={9} lg={9}>
                      <h5 className="sub-heading-vendor">
                        Number Of Assessments
                      </h5>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                      <div className="d-flex-checkbox">
                        <Checkbox
                          checked={!!subscriptionsData.isAssessmentEnabled}
                          onChange={handleCheckboxChange}
                          name="isAssessmentEnabled"
                          color="primary"
                          className="absolute-checkbox"
                        />
                        <TextField
                          type="number"
                          size="small"
                          name="numberOfAssessments"
                          className="quantity-field-input"
                          value={subscriptionsData.numberOfAssessments}
                          onChange={handleInputChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="border-left-right"
                                >
                                  <IconButton
                                    size="small"
                                    className="quantity-btn border-bottom-btns"
                                    onClick={() =>
                                      handleIncrease("numberOfAssessments")
                                    }
                                  >
                                    <ArrowDropUpIcon />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    className="quantity-btn"
                                    onClick={() =>
                                      handleDecrease("numberOfAssessments")
                                    }
                                  >
                                    <ArrowDropDownIcon />
                                  </IconButton>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          sx={textFieldStyles}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomTabPanel>
          </Container>
      </div>
      </Container>
      <ModalDialog
        handleClose={() => setShowDeleteAccountModal(false)}
        open={showDeleteAccountModal}
        loader={changeUserStatusLoader}
        onSubmit={() => changeUserStatusAPI("delete")}
        contentData={ModalContent("Are you sure you want to remove this item ? This action can't be undone.")}
      />

      <ModalDialog
        handleClose={() => setShowDeactivateAccountModal(false)}
        open={showDeactivateAccountModal}
        loader={changeUserStatusLoader}
        onSubmit={() => changeUserStatusAPI("status")}
        contentData={ModalContent("Are you sure you want to change the status of this item ?")}
      />
      <ClientUserResetPassword
        handleClose={() => setShowUserResetPasswordModal(false)}
        open={showUserResetPasswordModal}
        userDetail={selectedUserRow}
        handleSuccess={() => {
          setShowUserResetPasswordModal(false); getClientUsers(userPaginationModel.page + 1, userPaginationModel.pageSize);
        }}
      />
      <VendorUploadModal
        handleClose={() => setShowVendorUploadModal(false)}
        open={showVendorUploadModal}
        // loader={deleteLoader}
        onSubmit={() => console.log("call API")}
      />
    </>
  );
};

export default ClientMyAccount;
