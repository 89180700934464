import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CrossSelect from '../../assets/cross-select.svg';
import { Grid, Typography } from "@mui/material";
export default function CustomTagModel({
    handleClose,
    open,
    contentData
}) {
    const [theme, setTheme] = useState(localStorage.getItem("theme"));
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        borderRadius: "10px",
        p: 4,
        height:"50%",
        overflowY:"scroll"
    }; 
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme === "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
                }
            >
                <Box sx={style} className="select-vendor-popup">
                    <div className='select-assessment-heading'>
                        <Typography>
                            All Tags
                        </Typography>
                        <img src={CrossSelect} onClick={handleClose} alt='icon' />
                    </div>
                    <div className='padding-form-assessment topspace-extend' style={{marginTop:"0px",}}>
                        <Typography id="modal-modal-description">
                            <Grid container spacing={2}>
                                {contentData.map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                            {index + 1}. {item}
                                    </Grid>
                                ))}
                            </Grid>
                        </Typography>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
