import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
  TextField,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Label from "../components/label";
import { sentenceCase } from "change-case";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostRequest from "../components/apiConnections/postRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { SnackbarProvider, useSnackbar } from "notistack";
import CircularProgressBar from "./CircularProgressBar.js";
import CircularProgress from "./CircularProgress.js";
import CircularProgressSimple from "./CircularProgressSimple.js";
import { getLocalStoarge } from "../components/common/allFunctions";
import CircularProgressDividedIcon from "./CircularProgressDividedIcon.js";

import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
// import CircularProgress from '@mui/material/CircularProgress';
// components
import Iconify from "../components/iconify";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppOverallScore,
  AppRiskScore,
  AppOverallAttackScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Attack.css";
import "./css/Vendors.css";
import SubDomain from '../assets/subdomain.svg';
import ipv4 from "../assets/www.svg";
import jwt_decode from "jwt-decode";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';


import { Route, Link, BrowserRouter } from "react-router-dom";

// ----------------------------------------------------------------------
export default function VendorDetailsPage() {
  const theme = useTheme();
  const data = useParams();
  const [open, setOpen] = React.useState(false);
  const [low, setLow] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [hosting, setHosting] = useState("");
  const [address, setAddress] = useState("");
  const [companyImage, setCompanyImage] = useState("dummy.png");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [riskScore, setRiskScore] = useState("");
  const [alertData, setAlertData] = useState({});
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [showLoader, setshowLoader] = useState(false);

  const [domainIssues, setDomainIssues] = useState("");
  const [attackInfoData, setattackInfoData] = useState("");
  const [attackScoreInfo, setAttackScoreInfo] = useState("");
  const [attackSeverityInfo, setAttackSeverityInfo] = useState("");
  // const [attackSeverityLength, setAttackSeverityLength] = useState(0);

  const [attackCountInfo, setAttackCountInfoInfo] = useState("");
  const [securityGrade, setSecurityGrade] = useState(0);
  const [securityGradeTitle, setSecurityGradeTitle] = useState("");
  useEffect(() => {
    getVendorDetails();
  }, []);
  let token = (!(localStorage.getItem("R1")) ? localStorage.getItem("R2") : localStorage.getItem("R1"));
  let tokenData = JSON.parse(token).accessToken;
  let userData = jwt_decode(tokenData);
  let clientName = userData.firstName +" "+ userData.lastName;  
  let attackSeverityLength = 0;
  const getVendorDetails = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
        roleId: roleId
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SINGLE_ATTACK_DETAIL}`,
          vendorPayload
        )
          .then((res) => {
            setattackInfoData(res.data.data.attackInfo);
            setDomainName(res.data.data?.attackInfo.domain || "");
            setDomainIssues(res.data.data?.attackInfo.Issues);
            setAttackScoreInfo(res.data.data?.attackInfo.score);
            setAttackSeverityInfo(res.data.data?.attackInfo.severity);
            setAttackCountInfoInfo(res.data.data?.attackInfo.count[0]);
            setSecurityGrade(res.data.data.attackInfo.score[0].Overall_score)
            setSecurityGradeTitle(res.data.data.attackInfo.score[0].Grade)
            setLogo((res.data.data.attackInfo.logo!=="" || res.data.data.attackInfo.logo!=null) ? res.data.data.attackInfo.logo : logo);
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });

        let endpoint = "attack-details-day-wise";
        const daysPayload = {
          selectedDays: 30,
          domainName: data.url
        };
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          daysPayload
        )
          .then((res) => {
            let date = [];
            let issuesData = [];
            res.data.data.map((item, i) => {
              let element = item.attackInfo;
              date.push(
                moment(new Date(element.time * 1000)).format("MM/DD/YYYY")
              );
              issuesData.push(element.count[0].total_issue);
            });
            setGraphDates(date);
            setGraphValues(issuesData);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const [value, setValue] = React.useState("1");
  const [graphDates, setGraphDates] = useState(["02/28/2023"]);
  const [graphValues, setGraphValues] = useState([36]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>   
      
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          <span className="heading-main">Assets</span>
        </Typography>

        {showLoader === true ? (
          <Loader show={showLoader} />
        ) : domainName !== "" && domainName ? (
          <TabContext value={value}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="tabspart"
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile
                aria-label="lab API tabs example"
              >
                <Tab label="Attack Surface" value="1" />
                <Tab label="Assets" value="2" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <Grid container spacing={1} pb={2}>
                <Grid item xs={4} sm={2} md={1} lg={1}>
                  <img
                    src={logo}
                    alt="login"
                    style={{
                      width: "70px",
                      height: "70px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <div className="domain-part">
                    <h3 className="domain-name">{domainName}</h3>
                    <p className="add-top">{attackInfoData.IP}</p>
                  </div>
                </Grid>
               
                <Grid item xs={6} sm={7} md={7} lg={7} sx={{ mt: 2 }}> 
                  <div className="inbutton">
                  <Link to= {`../export-executive/${data.url}`} className="right-15">
                   <Button variant="contained" className="addnew send-assessment Executive">Executive Report</Button>                        
                  </Link>   

                  <Link  to= {`../export-detail/${data.url}`}>
                  <Button variant="contained" className="addnew send-assessment Executive">Detailed Report</Button>
                 </Link>
                 </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4} className="flx-property AttackScore">
                  <AppOverallAttackScore
                    title="Score over time"
                    style={{}}
                    riskScore="10"
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={8} className="flx-property">
                  <div className="SubDomains">
                    <h3 className="attack-tittle">Security Grade </h3>
                    <Grid container spacing={2} className="fit-tomid">
                      <Grid item xs={12} md={6} lg={6} className="alin-mid">
                      <CircularProgressSimple percent={(securityGradeTitle=="D") ? 22 : (securityGradeTitle=="C") ? 44 : (securityGradeTitle=="B") ? 70 : 100} title={securityGradeTitle} />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <div className="Total-SubDomains">
                            <div className="sub-saoce-between">
                              <div>
                                <span>{attackCountInfo?.total_subdomains}</span>
                                <p> Total SubDomains</p>
                                </div>
                               <img src={SubDomain} alt="icon" />
                           </div>
                        </div>
                          <div className="Total-SubDomains">
                            <div className="sub-saoce-between">
                              <div>
                               <span>{attackCountInfo?.total_ip}</span>
                                <p>Total IPv4</p>
                               </div>
                              <img src={ipv4} alt="icon" />
                           </div>
                         </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <AppWebsiteVisits
                    title="Total Issues"
                    // subheader="Last 30 Days"
                    chartLabels={graphDates}
                    chartData={[
                      {
                        name: "Total Issues",
                        type: "area",
                        fill: "gradient",
                        data: graphValues,
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6} className="flx-property">
                  <div className="issue-severity">
                    <h3 className="attack-tittle">Issue Severity</h3>
                    {
                      Object.entries(attackSeverityInfo[0]).map((key, val)=>{
                        attackSeverityLength = attackSeverityLength + key[1];
                      })        
                    }
               
                    <div className="alin-mids">
                      <Stack
                        sx={{ width: "80%", color: "grey.500" }}
                        spacing={2}
                        className="linearprocess"
                      >
                        <p className="m0">Critical ( {attackSeverityInfo[0].Critical}/{attackSeverityLength} )</p>
                        <LinearProgress
                         sx={{
                          backgroundColor: 'rgb(255 161 161)',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: 'rgb(211, 47, 47)',
                          }
                        }}
                          variant="determinate"
                          className="VolumeBar"
                          value={(attackSeverityInfo[0].Critical/attackSeverityLength)*100}
                        />
                        <p>High ( {attackSeverityInfo[0].High}/{attackSeverityLength} )</p>
                        <LinearProgress  sx={{
                          backgroundColor: '#ffe5c8',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: 'rgb(237, 108, 2)',
                          }
                        }}
                          variant="determinate"
                          className="VolumeBar"
                          value={(attackSeverityInfo[0].High/attackSeverityLength)*100}
                        />
                        <p>Medium ( {attackSeverityInfo[0].Medium}/{attackSeverityLength} )</p>
                          <LinearProgress sx={{
                               backgroundColor: 'rgb(209 232 255)',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: 'rgb(25, 118, 210)',
                              }
                            }}
                            variant="determinate"
                            className="VolumeBar"
                            value={(attackSeverityInfo[0].Medium/attackSeverityLength)*100}

                        />
                        <p>Low ( {attackSeverityInfo[0].Low}/{attackSeverityLength} )</p>
                          <LinearProgress
                            sx={{
                              backgroundColor: 'rgb(216 255 218)',
                             '& .MuiLinearProgress-bar': {
                               backgroundColor: 'rgb(46, 125, 50)',
                             }
                           }}
                            variant="determinate"
                            className="VolumeBar"
                          value={(attackSeverityInfo[0].Low/attackSeverityLength)*100}

                        />
                      </Stack>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6} className="flx-property">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="issues-box">
                        <div>
                          <h3>{attackCountInfo.total_issue}</h3>
                          <p>Issues</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="issues-box">
                        <div>
                          <h3>{attackCountInfo.total_cve}</h3>
                          <p>CVE</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="issues-box">
                        <div>
                          <h3>{attackCountInfo.total_ip}</h3>
                          <p>Open Ports</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="issues-box">
                        <div>
                          <h3>{attackCountInfo.total_breaches}</h3>
                          <p>Breaches</p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <h2 className="security-text Security-Monitor">
                    Security Monitor
                  </h2>
                </Grid>
                <Grid item xs={12} md={4} lg={4} className="flex-align">
                  <CircularProgressDividedIcon
                    percent={attackScoreInfo[0].Dns}
                    title={`DNS`}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                    percent={attackScoreInfo[0].Application}
                    title={`Misconfiguration`}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                    percent={attackScoreInfo[0].Encryption}
                    title={`Encryption`}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                    percent={attackScoreInfo[0].Breaches}
                    title={`Breach`}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                    percent={attackScoreInfo[0].Network}
                    title={`Network`}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4} className="flex-align">
                    <CircularProgressDividedIcon
                    percent={attackScoreInfo[0].Leak}
                    title={`Leak`}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value="2">
              <Box
                sx={{ width: "100%", typography: "body1" }}
                className="ipad-space"
              >
                <h2 className="heading-main" style={{textAlign:"left"}}>Sub-Domain</h2>
                <TableContainer
                  component={Paper}
                  sx={{ p: 1 }}
                  className="vendordetail"
                >
                  <Table sx={{ minWidth: 100 }} aria-label="simple table" className="table-attacks">
                    <TableHead>
                      <CardHeader title={""} subheader={""} />
                      <TableRow>
                        <TableCell>Domain Name</TableCell>
                        <TableCell>Issues</TableCell>
                        <TableCell>View</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {domainIssues.map((row) => (
                        <TableRow
                          key={row.category}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{row.Target}</TableCell>
                          <TableCell>{row.Issue.length}</TableCell>
                          <TableCell>
                            <a
                              href={`/${roleId==2 ? 'admin':'dashboard'}/sub-domain-issues/${data.url}/${row.Target}`}
                              style={{
                                textDecoration: "none",
                                color: "white",
                                backgroundColor: "grey",
                                padding: "3px 5px",
                                borderRadius: "5px",
                              }}
                            >
                              View
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </TabPanel>
          </TabContext>
        ) : (
          <Box sx={{ display: "flex" }}>
            <Grid>
              <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                <h4>No Record for this url</h4>
              </Card>
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
}
