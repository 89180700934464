import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Box, CircularProgress
} from "@mui/material";
import ArrowRight from "../../assets/arrow-right-profilerisk.svg";
import ImpactImges from "../../assets/impact-imges.svg";
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import GetRequest from "../../components/apiConnections/getRequest";


function RiskProfile({ domainName }) {
  const [loader, setLoader] = useState(false);
  const [inherentRisk, setInherentRisk] = useState(0);
  const [residualRisk, setResidualRisk] = useState(0);
  const [acceptableRisk, setAcceptableRisk] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [gdprTotalQuestions, setGdprTotalQuestions] = useState(0);
  const [iso27001ControlTotalQuestions, setIso27001ControlTotalQuestions] = useState(0);
  const [nistTotalQuestions, setNistTotalQuestions] = useState(0);
  const [gdprFailedQuestions, setGdprFailedQuestions] = useState(0);
  const [iso27001ControlFailedQuestions, setIso27001ControlFailedQuestions] = useState(0);
  const [nistFailedQuestions, setNistFailedQuestions] = useState(0);
  const [gdprPassedQuestions, setGdprPassedQuestions] = useState(0);
  const [iso27001ControlPassedQuestions, setIso27001ControlPassedQuestions] = useState(0);
  const [nistPassedQuestions, setNistPassedQuestions] = useState(0);

  const [gdpr, setGdpr] = useState();
  const [iso27001Control, setIso27001Control] = useState();
  const [nist, setNist] = useState();


  // console.log("setGdpr", gdpr, "setIso27001Control", iso27001Control, "setNist", nist)

  const [impactsOnVendor, setImpactsOnVendor] = useState({});
  const [impactsOnClient, setImpactsOnClient] = useState({});

  useEffect(() => {
    getBusinessRiskRatio();
    getCompplianceMapping();
    getResidualInheritanceAcceptableRisk();
    getImpactAnalysis();
  }, []);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'radar',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 1,
      colors: ['rgba(130, 82, 181, 0.8)', 'rgba(130, 82, 181, 0.4)', 'rgba(130, 82, 181, 0)'],
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 1,
        gradientToColors: ['#883CF0', '#684CE8', '#5358E2'], // The second color in the gradient
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [0, 80], // Ensures the gradient covers the full range
      },
    },
    dropShadow: {
      enabled: true,
      blur: 15,
      color: '#000',
      opacity: 0.5,
      left: 2,
      top: 2,
    },
    markers: {
      size: 4,
      colors: ['#ADE1FF'],
      strokeColors: '#6E12D0',
      strokeWidth: 2,
    },
    xaxis: {
      categories: [
        'Compliance', 'Cybersecurity', 'Operational', 'Reputational', 'Financial'
      ],
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Mona-Sans',
          colors: ['#fff', '#fff', '#fff', '#fff', '#fff'],
        },
      },
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: '#fff',
        },
      },
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColors: 'rgba(255, 255, 255, 0.2)',
          strokeWidth: 1,
          connectorColors: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: 'dark', // This sets the tooltip to a light theme
      background: '#241471',
      style: {
        fontSize: '14px',
        fontFamily: 'Mona-Sans',
        color: '#fff',
        backGround: '#241471',
        opacity: 1,
      },
      fillSeriesColor: false, // This ensures that the text color doesn't change with the series color
    },
  });


  const [chartSeries, setChartSeries] = useState([
    {
      name: 'Series',
      data: [0, 0, 0, 0, 0],
    },
  ]);



  // Define options for the concentric radial bars

  const options1 = {
    chart: {
      type: 'radialBar',
      height: 350,
      background: 'transparent',
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '60%',
          background: 'transparent', // Make hollow background transparent
          strokeCap: 'round',
          rounded: true,
        },
        track: {
          background: 'transparent', // Make track background transparent
          strokeWidth: '97%',
          margin: 6,
          strokeCap: 'round',
        },
        dataLabels: {
          total: {
            show: true,
            label: 'Total',
            fontFamily: 'Mona-Sans',
            color: '#fff', // Ensure label text is white
            offsetY: -10,
            formatter: (w) => {
              // console.log("gdpr", gdpr);
              return gdpr;
            }
          },
          value: {
            fontSize: '30px',
            color: '#fff', // Ensure value text is white
            fontFamily: 'Mona-Sans',
          },
          name: {
            fontSize: '20px',
            color: '#fff', // Ensure name text is white
            fontFamily: 'Mona-Sans',
          },
        },
        rounded: true,
      }
    },
    labels: ['Passed', 'Failed Controls', 'Mapping',],
    colors: ['#009420', '#E22222', '#0090FF'], // Customize colors for each radial bar
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ['#009420', '#E22222', '#0090FF'], // Set custom marker colors
      },
      labels: {
        colors: '#fff',  // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  var options2 = {
    chart: {
      type: 'radialBar',
      height: 350,
      background: 'transparent',
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '60%',
          background: 'transparent', // Make hollow background transparent
          strokeCap: 'round',
          rounded: true,
        },
        track: {
          background: 'transparent', // Make track background transparent
          strokeWidth: '97%',
          margin: 6,
          strokeCap: 'round',
        },
        dataLabels: {
          total: {
            show: true,
            label: 'Total',
            fontFamily: 'Mona-Sans',
            color: '#fff', // Ensure label text is white
            offsetY: -10,
            formatter: function (w) {
              // console.log("iso27001Control", iso27001Control);
              return iso27001Control;
            }
          },
          value: {
            fontSize: '30px',
            color: '#fff', // Ensure value text is white
            fontFamily: 'Mona-Sans',
          },
          name: {
            fontSize: '20px',
            color: '#fff', // Ensure name text is white
            fontFamily: 'Mona-Sans',
          },
        },
        rounded: true,
      }
    },
    labels: ['Passed', 'Failed Controls', 'Mapping',],
    colors: ['#009420', '#E22222', '#0090FF'], // Customize colors for each radial bar
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ['#009420', '#E22222', '#0090FF'], // Set custom marker colors
      },
      labels: {
        colors: '#fff',  // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  var options3 = {
    chart: {
      type: 'radialBar',
      height: 350,
      background: 'transparent',
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '60%',
          background: 'transparent', // Make hollow background transparent
          strokeCap: 'round',
          rounded: true,
        },
        track: {
          background: 'transparent', // Make track background transparent
          strokeWidth: '97%',
          margin: 6,
          strokeCap: 'round',
        },
        dataLabels: {
          total: {
            show: true,
            label: 'Total',
            fontFamily: 'Mona-Sans',
            color: '#fff', // Ensure label text is white
            offsetY: -10,
            formatter: function () {
              // console.log("nist", nist);
              return nist;
            }
          },
          value: {
            fontSize: '30px',
            color: '#fff', // Ensure value text is white
            fontFamily: 'Mona-Sans',
          },
          name: {
            fontSize: '20px',
            color: '#fff', // Ensure name text is white
            fontFamily: 'Mona-Sans',
          },
        },
        rounded: true,
      }
    },
    labels: ['Passed', 'Failed Controls', 'Mapping',],
    colors: ['#009420', '#E22222', '#0090FF'], // Customize colors for each radial bar
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ['#009420', '#E22222', '#0090FF'], // Set custom marker colors
      },
      labels: {
        colors: '#fff',  // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  // Series data for each concentric radial bar
  const gdprseries = [gdprPassedQuestions, gdprFailedQuestions, gdprTotalQuestions];
  const nistSeries = [nistPassedQuestions, nistFailedQuestions, nistTotalQuestions];
  const ISOseries = [iso27001ControlPassedQuestions, iso27001ControlFailedQuestions, iso27001ControlTotalQuestions];

  const getImpactAnalysis = () => {
    setLoader(true);

    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_IMPACT_ANALYSIS}?domain=${domainName}`
      ).then((res) => {
        // console.log("res impact analysis", res);
        setImpactsOnVendor(res.data.impactsOnVendorPercentages);
        setImpactsOnClient(res.data.impactsOnClientPercentages);
      })
    } catch (error) {
      setLoader(false);
      return false
    }
  }

  const getBusinessRiskRatio = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_BUSSINESS_RISK_RATIO}?domain=${domainName}`
      )
        .then((res) => {
          const businessRiskData = res.data.getBusinessRisk;
          delete businessRiskData.totalQuestion;

          let categories = Object.keys(businessRiskData)
          let categoryValue = Object.values(businessRiskData)

          setChartOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: {
              ...prevOptions.xaxis,
              categories: categories, // Set the new categories
            },
          }));

          setChartSeries((prevSeries) =>
            prevSeries.map((series) => ({
              ...series,
              data: categoryValue, // Set the new data
            }))
          );
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  const getCompplianceMapping = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPLIANCE_MAPPING}?domain=${domainName}`
      )
        .then((res) => {
          const result = res.data.result;
          setTotalQuestions(result.totalQuestions || 0);

          setGdprTotalQuestions(result.gdprTotalQuestionsPercentage || 0);
          setIso27001ControlTotalQuestions(result.iso27001ControlTotalQuestionsPercentage || 0);
          setNistTotalQuestions(result.nistTotalQuestionsPercentage || 0);

          setGdprFailedQuestions(result.gdprFailedQuestionsPercentage || 0);
          setIso27001ControlFailedQuestions(result.iso27001ControlFailedQuestionsPercentage || 0)
          setNistFailedQuestions(result.nistFailedQuestionsPercentage || 0);

          setGdprPassedQuestions(result.gdprPassedQuestionsPercentage || 0);
          setIso27001ControlPassedQuestions(result.iso27001ControlPassedQuestionsPercentage || 0);
          setNistPassedQuestions(result.nistPassedQuestionsPercentage || 0);

          setGdpr(result.gdprTotalQuestions || 0);
          setIso27001Control(result.iso27001ControlTotalQuestions || 0);
          setNist(result.nistTotalQuestions || 0);

          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  const getResidualInheritanceAcceptableRisk = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_INHERENT_RESIDUAL_ACCEPTABLE_RISK}?domain=${domainName}`
      )
        .then((res) => {
          setAcceptableRisk(res.data.acceptableRisk);
          setResidualRisk(res.data.residualRisk);
          setInherentRisk(res.data.inherentRisk);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  const getReletionshipTag = (score) => {
    let riskTag;
    if (score && score <= 25) {
      riskTag = "Critical";
    } else if (score && score <= 50) {
      riskTag = "High";
    } else if (score && score <= 75) {
      riskTag = "Medium";
    } else if (score && score > 75) {
      riskTag = "Low";
    }
    return riskTag;
  }
  return (
    <Container maxWidth={false} className="max-w-left">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <div className="risk-profile-content">
            <h3>Risk Profile</h3>
            <p>Figma is a collaborative interface design tool that allows designers to create, prototype, and share their work in real-time. It's widely used for its seamless collaboration features, enabling teams to work together efficiently across different projects. </p>
            <h4>Risk Assessment</h4>
          </div>
          <div className="inherent-risk-full-bacground">
            <div className="top-3-box-risk">
              <h3>Inherent Risk</h3>
              <h4>{Math.round(inherentRisk) || 0}</h4>
              {inherentRisk ? (
                <span className={`tagClass ${getReletionshipTag(Math.round(inherentRisk))}`}>
                  {getReletionshipTag(Math.round(inherentRisk))}
                </span>
              ) : (
                "-"
              )}
            </div>
            <img src={ArrowRight} alt="arrow" />
            <div className="top-3-box-risk">
              <h3>Residual Risk</h3>
              <h4>{Math.round(residualRisk) || 0}</h4>
              {residualRisk ? (
                <span className={`tagClass ${getReletionshipTag(Math.round(residualRisk))}`}>
                  {getReletionshipTag(Math.round(residualRisk))}
                </span>
              ) : (
                "-"
              )}
            </div>
            <div className="top-3-box-risk">
              <h3>Acceptable Risk</h3>
              <h4>{acceptableRisk || 0}</h4>
              {acceptableRisk ? (
                <span className={`tagClass ${getReletionshipTag(Math.round(acceptableRisk))}`}>
                  {getReletionshipTag(Math.round(acceptableRisk))}
                </span>
              ) : (
                "-"
              )}
            </div>
          </div>
        </Grid>

        {/* <Grid item xs={12} md={12} lg={12}>
          <div className="impact-analysis-section">
            <div className="risk-profile-content">
             <h3>Impact Analysis</h3>
              <p>Figma is a collaborative interface design tool that allows designers to create, prototype, and share their work in real-time. It's widely used for its seamless collaboration features, enabling teams to work together efficiently across different projects. </p>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <p>Impact on Vendor</p>
                {impactsOnVendor ? (
                  Object.keys(impactsOnVendor).map((key, value) => (
                    console.log("key", key),
                    console.log("value", value),
                    <div className="impact-analysis-box">
                      <p>{key}</p>
                      <p>{impactsOnVendor[key]}</p>
                      <Chart
                          options={{
                            chart: {
                              type: "bar",
                              height: 50,
                              sparkline: {
                                enabled: true, // Hide axes and gridlines
                              },
                            },
                            plotOptions: {
                              bar: {
                                horizontal: true,
                                barHeight: "30%",
                                distributed: true,
                              },
                            },
                            xaxis: {
                              categories: [key],
                              max: 100,
                            },
                            fill: {
                              colors: ["#DF03CF"],
                            },
                            tooltip: {
                              enabled: false,
                            },
                          }}
                          series={[{ data: [impactsOnVendor[key]] }]}
                          type="bar"
                          height={50}
                        />
                    </div>
                  ))
                ) : (
                  <div>
                    No data found
                  </div>
                )
                }
              </div>
              <div>
              <p>Impact on Client</p>
                {impactsOnClient ? (
                  Object.keys(impactsOnClient).map((key, value) => (
                    console.log("key", key),
                    console.log("value", value),
                    <div className="impact-analysis-box">
                      <p>{key}</p>
                      <p>{impactsOnClient[key]}</p>
                      <Chart
                          options={{
                            chart: {
                              type: "bar",
                              height: 50,
                              sparkline: {
                                enabled: true, // Hide axes and gridlines
                              },
                            },
                            plotOptions: {
                              bar: {
                                horizontal: true,
                                barHeight: "30%",
                                distributed: true,
                              },
                            },
                            xaxis: {
                              categories: [key],
                              max: 100,
                            },
                            fill: {
                              colors: ["#DF03CF"],
                            },
                            tooltip: {
                              enabled: false,
                            },
                          }}
                          series={[{ data: [impactsOnClient[key]] }]}
                          type="bar"
                          height={50}
                        />
                    </div>
                  ))
                ) : (
                  <div>
                    No data found
                  </div>
                )
                }
              </div>
            </div>
          </div>
        </Grid> */}
        <Grid item xs={12} md={12} lg={12}>
          <div className="risk-profile-content">
            <h3>Impact Analysis</h3>
            <p>Figma is a collaborative interface design tool that allows designers to create, prototype, and share their work in real-time. It's widely used for its seamless collaboration features, enabling teams to work together efficiently across different projects. </p>
          </div>
          <div className="impact-analysis-section">
            <img src={ImpactImges} alt="img" />
          </div>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <div className="risk-profile-content">
            <h3>Business Risk Ratio</h3>
            <p>Figma is a collaborative interface design tool that allows designers to create, prototype, and share their work in real-time. It's widely used for its seamless collaboration features, enabling teams to work together efficiently across different projects. </p>
          </div>
          <div className="bussiness-risk">
            <h2>Business Risk Ratio</h2>
            <Chart options={chartOptions} series={chartSeries} type="radar" height={500} />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="risk-profile-content">
            <h3>Compliance Mapping</h3>
            <p>Figma is a collaborative interface design tool that allows designers to create, prototype, and share their work in real-time. It's widely used for its seamless collaboration features, enabling teams to work together efficiently across different projects. </p>
          </div>
          <div className="bussiness-risk">
            <h2>Compliance Mapping</h2>
            <div className="mapping-bussiness-left">
              <div className="flex-compliance-mapping">
                <div className="gdpr-box">
                  <h3>GDPR</h3>
                  {gdpr ?
                    <ReactApexChart options={options1} series={gdprseries} type="radialBar" height={350} />
                    :
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "190px", width: "100%" }}>
                      <CircularProgress className="loader-custom-class-new" />
                    </Box>
                  }    </div>
                <div className="gdpr-box">
                  <h3>ISO 27001</h3>
                  {
                    iso27001Control ?
                      <ReactApexChart options={options2} series={ISOseries} type="radialBar" height={350} />
                      : <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "190px", width: "100%" }}>
                        <CircularProgress className="loader-custom-class-new" />
                      </Box>
                  }
                </div>
                <div className="gdpr-box">
                  <h3>NIST</h3>
                  {
                    nist ?
                      <ReactApexChart options={options3} series={nistSeries} type="radialBar" height={350} />
                      : <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "190px", width: "100%" }}>
                        <CircularProgress className="loader-custom-class-new"/>
                      </Box>
                  }
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
export default RiskProfile;
