import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box, CircularProgress
} from "@mui/material";
import ReactApexChart from 'react-apexcharts';
import { useSnackbar } from "notistack";
import GetRequest from "../../components/apiConnections/getRequest";

function ComplianceMappingPage({ domainName, assessmentId }) {
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [gdprTotalQuestions, setGdprTotalQuestions] = useState(0);
  const [iso27001ControlTotalQuestions, setIso27001ControlTotalQuestions] = useState(0);
  const [nistTotalQuestions, setNistTotalQuestions] = useState(0);
  const [gdprFailedQuestions, setGdprFailedQuestions] = useState(0);
  const [iso27001ControlFailedQuestions, setIso27001ControlFailedQuestions] = useState(0);
  const [nistFailedQuestions, setNistFailedQuestions] = useState(0);
  const [gdprPassedQuestions, setGdprPassedQuestions] = useState(0);
  const [iso27001ControlPassedQuestions, setIso27001ControlPassedQuestions] = useState(0);
  const [nistPassedQuestions, setNistPassedQuestions] = useState(0);

  const [gdpr, setGdpr] = useState();
  const [iso27001Control, setIso27001Control] = useState();
  const [nist, setNist] = useState();


  useEffect(() => {
    getCompplianceMapping();
  }, []);

  // Define options for the concentric radial bars
  const options1 = {
    chart: {
      type: 'radialBar',
      height: 350,
      background: 'transparent', // Set chart background to transparent
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '60%',
          background: 'transparent', // Make hollow background transparent
          strokeCap: 'round',
          rounded: true,
        },
        track: {
          background: 'transparent', // Make track background transparent
          strokeWidth: '97%',
          margin: 6,
          strokeCap: 'round',
        },
        dataLabels: {
          total: {
            show: true,
            label: 'Total',
            fontFamily: 'Mona-Sans',
            color: '#fff', // Ensure label text is white
            offsetY: -10,
            formatter: function (w) {
              return gdpr
            }
          },
          value: {
            fontSize: '30px',
            color: '#fff', // Ensure value text is white
            fontFamily: 'Mona-Sans',
          },
          name: {
            fontSize: '20px',
            color: '#fff', // Ensure name text is white
            fontFamily: 'Mona-Sans',
          },
        },
        rounded: true,
      }
    },
    labels: ['Passed', 'Failed Controls', 'Mapping'],
    colors: ['#009420', '#E22222', '#0090FF'], // Customize colors for each radial bar
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ['#009420', '#E22222', '#0090FF'], // Set custom marker colors
      },
      labels: {
        colors: '#fff',  // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  const options2 = {
    chart: {
      type: 'radialBar',
      height: 350,
      background: 'transparent', // Set chart background to transparent
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '60%',
          background: 'transparent', // Make hollow background transparent
          strokeCap: 'round',
          rounded: true,
        },
        track: {
          background: 'transparent', // Make track background transparent
          strokeWidth: '97%',
          margin: 6,
          strokeCap: 'round',
        },
        dataLabels: {
          total: {
            show: true,
            label: 'Total',
            fontFamily: 'Mona-Sans',
            color: '#fff', // Ensure label text is white
            offsetY: -10,
            formatter: function (w) {
              return iso27001Control
            }
          },
          value: {
            fontSize: '30px',
            color: '#fff', // Ensure value text is white
            fontFamily: 'Mona-Sans',
          },
          name: {
            fontSize: '20px',
            color: '#fff', // Ensure name text is white
            fontFamily: 'Mona-Sans',
          },
        },
        rounded: true,
      }
    },
    labels: ['Passed', 'Failed Controls', 'Mapping'],
    colors: ['#009420', '#E22222', '#0090FF'], // Customize colors for each radial bar
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ['#009420', '#E22222', '#0090FF'], // Set custom marker colors
      },
      labels: {
        colors: '#fff',  // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  const options3 = {
    chart: {
      type: 'radialBar',
      height: 350,
      background: 'transparent', // Set chart background to transparent
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '60%',
          background: 'transparent', // Make hollow background transparent
          strokeCap: 'round',
          rounded: true,
        },
        track: {
          background: 'transparent', // Make track background transparent
          strokeWidth: '97%',
          margin: 6,
          strokeCap: 'round',
        },
        dataLabels: {
          total: {
            show: true,
            label: 'Total',
            fontFamily: 'Mona-Sans',
            color: '#fff', // Ensure label text is white
            offsetY: -10,
            formatter: function (w) {
              return nist
            }
          },
          value: {
            fontSize: '30px',
            color: '#fff', // Ensure value text is white
            fontFamily: 'Mona-Sans',
          },
          name: {
            fontSize: '20px',
            color: '#fff', // Ensure name text is white
            fontFamily: 'Mona-Sans',
          },
        },
        rounded: true,
      }
    },
    labels: ['Passed', 'Failed Controls', 'Mapping'],
    colors: ['#009420', '#E22222', '#0090FF'], // Customize colors for each radial bar
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ['#009420', '#E22222', '#0090FF'], // Set custom marker colors
      },
      labels: {
        colors: '#fff',  // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  }

  // Series data for each concentric radial bar
  const gdprseries = [gdprPassedQuestions, gdprFailedQuestions, gdprTotalQuestions];
  const nistSeries = [nistPassedQuestions, nistFailedQuestions, nistTotalQuestions];
  const ISOseries = [iso27001ControlPassedQuestions, iso27001ControlFailedQuestions, iso27001ControlTotalQuestions];

  const getCompplianceMapping = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPLIANCE_MAPPING}?domain=${domainName}&assessmentId=${assessmentId}`
      )
        .then((res) => {
          const result = res.data.result;

          setGdprTotalQuestions(result.gdprTotalQuestionsPercentage || 0);
          setIso27001ControlTotalQuestions(result.iso27001ControlTotalQuestionsPercentage || 0);
          setNistTotalQuestions(result.nistTotalQuestionsPercentage || 0);

          setGdprFailedQuestions(result.gdprFailedQuestionsPercentage || 0);
          setIso27001ControlFailedQuestions(result.iso27001ControlFailedQuestionsPercentage || 0)
          setNistFailedQuestions(result.nistFailedQuestionsPercentage || 0);

          setGdprPassedQuestions(result.gdprPassedQuestionsPercentage || 0);
          setIso27001ControlPassedQuestions(result.iso27001ControlPassedQuestionsPercentage || 0);
          setNistPassedQuestions(result.nistPassedQuestionsPercentage || 0);

          setGdpr(result.gdprTotalQuestions || 0);
          setIso27001Control(result.iso27001ControlTotalQuestions || 0);
          setNist(result.nistTotalQuestions || 0);


          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };

  return (
    <>


      <h3 className="category-score-text-heading" style={{ marginTop: "0px", }}>Mapping</h3>
      <div className="flex-compliance-mapping" style={{ marginTop: "0px", marginBottom: "20px", }}>
        <div className="gdpr-box-mappingtab">
          <h3>GDPR</h3>
          {gdpr ?
            <ReactApexChart options={options1} series={gdprseries} type="radialBar" height={350} />
            :
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "190px", width: "100%" }}>
              <CircularProgress className="loader-custom-class-new"/>
            </Box>
          }
        </div>
        <div className="gdpr-box-mappingtab">
          <h3>ISO 27001</h3>
          {iso27001Control ?
            <ReactApexChart options={options2} series={ISOseries} type="radialBar" height={350} />
            :
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "190px", width: "100%" }}>
              <CircularProgress className="loader-custom-class-new" />
            </Box>
          }
          {/* <ReactApexChart options={options2} series={ISOseries} type="radialBar" height={350} /> */}
        </div>
        <div className="gdpr-box-mappingtab">
          <h3>NIST</h3>
          {
            nist ?
              <ReactApexChart options={options3} series={nistSeries} type="radialBar" height={350} />
              :
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "190px", width: "100%" }}>
                <CircularProgress className="loader-custom-class-new"/>
              </Box>
          }
        </div>
      </div>
    </>
  );
}

export default ComplianceMappingPage;
