import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        className="circularprogress"
        size="9rem"
        thickness={4.4} 
        style={{
          zIndex: "9",
          color:
            props.value >= 0 && props.value < 33
              ? "#FF0000"
              : props.value >= 33 && props.value < 66
              ? "#FFD700"
              : props.value >= 66 && props.value < 101
              ? "#008000"
              : "white",
        }}
        variant="determinate"
        {...props}
      />
      <CircularProgress
        className="bottom-circle"
        size="9rem"
        variant="determinate"
        value={100}
        thickness={4.4} 
        style={{ color: "#2C225E" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          className="security-text"
        >
          {`${Math.round(props.value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularProgressBar({ percent }) {
  const [progress, setProgress] = React.useState(percent);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return <CircularProgressWithLabel value={progress} />;
}
