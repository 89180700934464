import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Container,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import NewHeader from "../../layouts/dashboard/header/NewHeader";
import GeneralUnique from "../../assets/general-unique.svg";
import ArrowRightShape from "../../assets/arrow-right-profilerisk.svg";
import { useLocation } from "react-router-dom";
import GetRequest from "../../components/apiConnections/getRequest";
import { useSnackbar } from "notistack";
import PostRequest from "../../components/apiConnections/postRequest";
import Breadcrumb from "./Breadcrumb";
import Tooltip from '@mui/material/Tooltip';
import BackArrow from '../../assets/back-arrow.svg';
import AssessmentImage from '../../assets/assesment-icon-image.svg';
import StatusImage from '../../assets/status-icon-image.svg';
import SeverityImage from '../../assets/serverity-icon.svg';
function ControlsDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { Data: Data } = location?.state || props;
  const { vendorUrl } = location?.state;
  const [impactOnVendor, setImpactOnVendor] = useState(Data?.que?.impactOnVendor);
  const [impactOnClient, setimpactOnClient] = useState(Data?.que?.impactOnClient);
  const [showLoader, setShowLoader] = useState(false);
  const [mappingData, setMappingData] = useState({
    iso27001Control: [],
    gdpr: [],
    nist: [],
  })
  useEffect(() => {
    getMappingDataAPI()
  }, [])
  const getMappingDataAPI = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_QUESTIONNAIRE_MAPPING_DATA}`,
          {}
        )
          .then((res) => {
            let data = res.data.data;
            setShowLoader(false);
            setMappingData(data)
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  }

  const getGdprMappingDetails = () => {
    return mappingData?.gdpr?.find(gdpr => gdpr?.article === Data?.que?.gdpr)?.articleTitle;
  }
  const getNistMappingDetails = () => {
    return mappingData?.nist?.find(nist => nist?.controlIdentifier === Data?.que?.nist)?.controlName
  }
  const getIso27001ControlMappingDetails = () => {
    return mappingData?.iso27001Control?.find(iso27001Control => iso27001Control?.controlId === Data?.que?.iso27001Control)?.description
  }
  const breadcrumbs = [
    { label: 'Ecosystem', path: '/dashboard/vendors' },
    { label: 'Security', path: `/dashboard/vendorDetails/${vendorUrl}`,mainTabValue: 4 },
    { label: 'Controls Detail page' }
  ];
  return (
    <>
      <Helmet>
        <title>Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Security Profile" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="security-breadcum">
              <div>
                {(vendorUrl) ? 
                <>
                  <p>
                    <Breadcrumb crumbs={breadcrumbs} />
                  </p>
                  
                  <span className='backlink category-score-text-heading' style={{marginTop:"5px", marginBottom:"0px",paddingLeft:"0" }}>
                  <img src={BackArrow} onClick={()=>
                    navigate(`/dashboard/vendorDetails/${vendorUrl}`,{state:{mainTabValue: 4}})}alt='icon' />
                  Control Details
                </span>
                </>
                : 
                <span className='backlink category-score-text-heading' style={{marginTop:"5px", marginBottom:"0px",paddingLeft:"0" }}>
                  <img src={BackArrow} onClick={()=>navigate(-1)}alt='icon' />
                  Control Details
                </span>
                }  
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div className="bg-failed-control">
              <div className="general-id-box">
                <div className="ico-unique">
                  <img src={GeneralUnique} alt="icon" />
                  <div>
                    <p style={{ fontSize: "15px" }}>{Data?.que?.uniqueId || "N/A"}</p> <span>ID</span>
                  </div>
                </div>
                <div className="ico-unique">
                  <img src={AssessmentImage} alt="icon" />
                  <div>
                       <Tooltip title={Data?.set} placement="top" followCursor>
                        <div>
                          <p className="assesment-ellips-text">{Data?.set|| "-"}</p>
                          <span>Assessment</span>
                        </div>
                      </Tooltip>
                  </div>
                </div>
                <div className="ico-unique">
                  <img src={StatusImage} alt="icon" />
                  <div>
                    <p><span className={`tagClass ${Data?.score}`}>{Data?.score||"-"}</span></p> <span>Status</span>
                  </div>
                </div>
                <div className="ico-unique">
                  <img src={SeverityImage} alt="icon" />
                  <div>
                    <p>{Data?.riskMapping ? (
                      <span className={`tagClass ${Data?.riskMapping}`}>
                        {Data?.riskMapping||"-"}
                      </span>
                    ) : (
                      "-"
                    )}</p><span>Severity</span>
                  </div>
                </div>
              </div>
              <div className="flez-inline">
                <div className="failed-qeustions">
                  <h3>Question</h3>
                  <p>{Data?.que?.text}</p>
                </div>
                <div className="failed-qeustions response-box">
                  <h3>Response</h3>
                  <p>{Data?.remark || "-"}</p>
                </div>
              </div>
              <div className="failed-flexi">
                <div className="type-inline-controls">
                  <div className="failed-qeustions response-box">
                    <h3>Type</h3>
                    <p>{Data?.category||"-"}</p>
                  </div>
                  <div className="failed-qeustions response-box">
                    <h3>Risk</h3>
                    <p>{Data?.que?.riskCategory||"-"}</p>
                  </div>
                </div>
                <div className="impact-on-vendor-fl">
                  <div className="failed-qeustions response-box">
                    <h3>Impact on Vendor</h3>
                    {impactOnVendor?.map((key) => (
                      <div className="financial-loss" item={key}>{key}</div>
                    ))}
                    <img
                      className="arrow-right-image"
                      src={ArrowRightShape}
                      width={30}
                      height={43}
                      alt="img"
                    />
                  </div>

                  <div className="failed-qeustions response-box">
                    <h3>Impact on Business Name</h3>
                    {impactOnClient?.map((key) => (
                      <div className="financial-loss" item={key}>{key}</div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="data-table-failed-full">
                <h3>Compliance Mapping</h3>
                <TableContainer
                  sx={{ p: 1 }}
                  className="failed-control-datatable"
                >
                  <Table
                    sx={{ minWidth: 100 }}
                    aria-label="simple table"
                    className=""
                  >
                    <TableHead sx={{ borderRadius: "10px 10px 0 0" }}>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>GDPR</TableCell>
                        <TableCell>{Data?.que?.gdpr||"-"}</TableCell>
                        <TableCell>
                          {getGdprMappingDetails()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ISO 27001</TableCell>
                        <TableCell>{Data?.que?.iso27001Control||"-"}</TableCell>
                        <TableCell>
                          {getIso27001ControlMappingDetails()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>NIST</TableCell>
                        <TableCell>{Data?.que?.nist||"-"}</TableCell>
                        <TableCell>
                          {getNistMappingDetails()}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ControlsDetails;
