import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./themeSlice";
import unreadMessagesReducer from "./unreadMessagesSlice";
import activeTabReducer from "./activeTabSlice";
import permissionListReducer from './permissionListSlice';
const store = configureStore({
  reducer: {
    theme: themeReducer,
    unreadMessages: unreadMessagesReducer,
    activeTab: activeTabReducer,
    permissionList: permissionListReducer
  },
});

export default store;