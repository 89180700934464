import { useState, useEffect, useRef } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, CircularProgress } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import { Link } from 'react-router-dom';
import './header.css';
import NotificationIcon from '../../../assets/notification-icon.svg';
import Clock from '../../../assets/clock-time.svg';
import PostRequest from '../../../components/apiConnections/postRequest';
import GetRequest from '../../../components/apiConnections/getRequest';
import { useSnackbar } from "notistack";
import UserProfile from "../../../assets/user-profile.svg";




const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  // },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    to: localStorage.getItem("roleId") != 1 ? "../profile" : '/dashboard/profile-detail'
  },
  {
    label: 'Change Password',
    icon: 'eva:briefcase-outline',
    to: '../change-password'
  }
  // {
  //   label: 'Request Url',
  //   icon: 'eva:link-2-outline',
  //   to: './dashboard/requestUrl'
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   to: './setting'

  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [notificationOpen, setNotificationOpen] = useState(null);
  const [theme, setTheme] = useState('Dark');
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const divRef = useRef(null);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationNotFound, setNotificationNotFound] = useState('');
  let roleId = parseInt(window.localStorage.getItem('roleId'));
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
 
  const handleClose = () => {
    setOpen(null);
  };
  const logout = () => {
    window.localStorage.removeItem('roleId');
    window.localStorage.removeItem('R1');
    window.localStorage.removeItem('R2');
    window.localStorage.removeItem('usertime');
    window.localStorage.removeItem('userDetails');
    window.localStorage.removeItem('deleteAssessment');
    window.localStorage.removeItem('deleteRemediation');
    window.localStorage.removeItem('deleteFunction');



  };

  useEffect(() => {
    if(roleId===1){
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getUnreadNotificationCount();
    }, 30000);  // Poll every 5 seconds
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    getUnreadNotificationCount();
    setInterval(() => {
      const themeName = localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'Dark';
      setTheme(themeName);
    }, 3000)
  }, [])

  const handleClickOutside = (event) => {
    if (!divRef?.current?.contains(event.target)) {
      setShowNotification(false)
    }
  };

  const showHideNotification = () => {
    if (!showNotification) {
      getNotificationList()
    }
    setShowNotification(!showNotification)
    updateNotificationStatus()
  }

  const getUnreadNotificationCount = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UNREAD_NOTIFICATION_COUNT}`,
          {}
        )
          .then((res) => {
            let data = res.data.data;
            setNotificationCount(data.unreadNotificationCount)
            setShowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  }

  const getNotificationList = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_NOTIFICATION_LIST}`,
          {}
        )
          .then((res) => {
            let data = res.data.data;
            setNotificationList(data);
            if (!data.length) {
              setNotificationNotFound('No Notification Found!');
            }
            setShowLoader(false);

          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  }

  const updateNotificationStatus = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_READ_NOTIFICATION}`,
          {}
        )
          .then((res) => {
            setNotificationCount(0);
            setShowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  }

  return (
    <>
      {/* <div className='add-vendor-button-header'>
        <img src={PlusVendor} alt='icon'/> Add Vendor</div>
        <div className='relative-search'>
         <input className='input-header-search' type='text' placeholder='Search'/>
         <img src={HeaderIcon} alt='icon'/>
        </div> */}

      {/* <IconButton
        className='profile-bg-grey'
        onClick={handleNotificationOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
        title="Notifications"
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton> */}

     {roleId === 1 && <div ref={divRef} className='notification-popups' onClick={showHideNotification}>
      <img src={NotificationIcon} width={25} alt='icon' style={{cursor:"pointer"}} />
        {notificationCount ? <span className='pop-notification-number'>{notificationCount}</span> : null}
        {showNotification ? <div className='notification-text-data'>
        {notificationList.length ? notificationList.map((item, index) => (
            <div key={index} className='time-clock-notify'>
              <span><img src={Clock} width={18} /> {item.time_ago}</span>
              <p>{item.message}</p>
            </div>
          )) :
            <h3>{notificationNotFound}</h3>}
        </div> : null}
      </div>}


      <IconButton
        className='profile-bg-grey'
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
        title="Profile Setting"
      >
        <Avatar src={account.photoURL} alt="photoURL"   onError={(e) => e.target.src = UserProfile} />
        <span className='same-as-username'>{account.displayName}</span>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option, index) => (
            <Link to={option.to} key={index} style={{ textDecoration: "none", color: (theme === 'Dark') ? 'White' : 'Black', padding: '2px', fontSize: '500' }}>
              <MenuItem key={index + '-' + option.label} >
                {option.label}
              </MenuItem>
            </Link>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed', color:"#fff" }} />
        <Link to="/" style={{color:'#fff'}}>
          <MenuItem onClick={logout} sx={{ m: 1 }} >
            Logout
          </MenuItem>
        </Link>
      </Popover>
    </>
  );
}
