import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {  useLocation } from "react-router-dom";
import {
  Grid,
  Container,
  Tabs
} from "@mui/material";
import Tab from "@mui/material/Tab";
import "./css/Vendors.css";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import RemediationForIssue from "./vendor/RemediationForIssue.jsx";
import RemediationForFailedControl from "./vendor/RemediationForFailedControl.jsx";
// ----------------------------------------------------------------------
export default function VendorRemediationPage() {
  const location = useLocation();
  const [mainTabValue, setMainTabValue] = useState(location.state?.mainTabValue || 0);
  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };
   return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
       </Helmet>
      
       <Container maxWidth={false} className="max-w-left">
       <NewHeader titleName="Remediation" />
        <Grid container spacing={3} className="ipad-space">
         <Grid item xs={12} md={12} lg={12}>
          <Tabs
            value={mainTabValue}
            onChange={handleMainTabChange}
            className="tab-main-heading margin-mui-tabfix"
            aria-label="basic tabs example"
          >
            <Tab className="active-color-remove" label="Issue" />
            <Tab className="active-color-remove" label="Failed Control" />
          </Tabs>

            {mainTabValue === 0 && <div><RemediationForIssue/></div>}
            {mainTabValue === 1 && <div><RemediationForFailedControl/> </div>}
            </Grid>
        </Grid>
     </Container >
    </>
  );
}
