import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../css/Vendors.css";
// @mui
import {
  Button, Card, Container, TextField,Grid, InputAdornment, Box, IconButton, Menu, MenuItem, FormControl, Select, Typography
} from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Delete from "../../assets/delete.svg";
import DeleteRequest from "../../components/apiConnections/deleteRequest.js";
import GetRequest from "../../components/apiConnections/getRequest.js";
import PostRequest from "../../components/apiConnections/postRequest";
import ShowCompliance from "../../components/modal/showCompliance.jsx";
import ModalDialog from "../ModalDialog.js";
import NewHeader from "../../layouts/dashboard/header/NewHeader.js";
import Add from '../../assets/plus-vendor.svg';
import SearchIcon from '../../assets/searchico.svg';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EyeMenu from '../../assets/eye-menu.svg';
import Trash from '../../assets/trahs.svg';
import EditIcon from '../../assets/edit-icon-2.svg';
import { CustomNoRowsOverlay } from "../Assessment.js"

export default function ComplianceList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteComplianceModal, setShowDeleteComplianceModal] =
    useState(false);
  const [activeCompliance, setActiveCompliance] = useState({});
  const [showComplianceModal, setShowComplianceModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [pageSize, setPageSize] = useState(5);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [columns, setColumns] = useState([
    { field: "id", headerName: "No.", editable: false, minWidth: 50, flex: 1 },
    { field: "name", headerName: "Name", editable: false, minWidth: 150, flex: 1, headerAlign: 'center', align: 'center' },
    {
      field: "complianceType",
      headerName: "Type",
      editable: false,
      minWidth: 100,
      flex: 1, headerAlign: 'center', align: 'center'
    },
    {
      field: "issuerName",
      headerName: "Issuer Name",
      editable: false,
      minWidth: 150,
      flex: 1, headerAlign: 'center', align: 'center'
    },
    {
      field: "issueDate",
      headerName: "Issue Date",
      editable: false,
      minWidth: 150,
      flex: 1, headerAlign: 'center', align: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        let firstDate = moment(v1, "DD/MM/YYYY").valueOf();
        let secondDate = moment(v2, "DD/MM/YYYY").valueOf();
        return firstDate - secondDate;
      },
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      editable: false,
      minWidth: 150,
      flex: 1, headerAlign: 'center', align: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        let firstDate = moment(v1, "DD/MM/YYYY").valueOf();
        let secondDate = moment(v2, "DD/MM/YYYY").valueOf();
        return firstDate - secondDate;
      },
    },
    {
      field: 'menu1', headerName: '',
      flex: 0.3,
      minWidth: 50,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <RowMenu
          handleRemove={() => {
            setActiveCompliance(params.row);
            setShowDeleteComplianceModal(true);
          }}
          handleView={() => {
            handleRowClick(params)
          }}
          handleEdit={()=>{
            navigate(`/vendor/compliance-card/${params.row._id}`)
          }}
        />

      }
    }
  ]);
  // Menu component for the three-dot menu
  function RowMenu({ handleRemove, handleView ,handleEdit}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={handleClick} className='three-dots-btn'>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className='rowmenu'
        >
          <MenuItem onClick={handleView}><img src={EyeMenu} /> View</MenuItem>
          <MenuItem onClick={handleEdit}><img src={EditIcon} /> Edit</MenuItem>
          <MenuItem onClick={handleRemove}><img src={Trash} /> Remove</MenuItem>
        </Menu>
      </>
    );
  }
  const handleRowClick = (params) => {
    setActiveCompliance(params.row);
    setShowComplianceModal(true);
  };
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  useEffect(() => {
    getComplianceList();
  }, []);

  const getComplianceList = async () => {
    setShowLoader(true);
    const endpoint = process.env.REACT_APP_GET_COMPLIANCE_LIST_BY_VENDOR;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setRows(
              arrResponse?.length
                ? [
                  ...arrResponse?.map((d, i) => ({
                    ...d,
                    id: i + 1,
                    issueDate: moment(d.issueDate).format("DD-MM-YYYY"),
                    expiryDate: moment(d.expiryDate).format("DD-MM-YYYY"),
                  })),
                ]
                : []
            );
            setShowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const removeCompliance = async () => {
    setShowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = process.env.REACT_APP_DELETE_COMPLIANCE_CARD;
      await DeleteRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        { complianceId: activeCompliance._id }
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          setShowDeleteComplianceModal(false);
          getComplianceList();
          setShowLoader(false);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setShowLoader(false);
        });
    }
  };
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    const filtered = rows.filter((row) =>
      Object.values(row).some((field) =>
        String(field).toLowerCase().includes(value)
      )
    );
    setFilteredRows(filtered);
  };
  const deleteModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to remove compliance ?
        </h2>
      </Typography>
    );
  };

  return (
    <>
      <Helmet>
        <title> Vendors | Genesis Platform</title>
      </Helmet>
       <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Compliance" />
        <Grid container spacing={3} className="ipad-space">
        <Grid item xs={12} md={12} lg={12}>
         <Card className="full-coverdesign margin-questionnaire">
          <div className="cover-table">
            <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '6px', left: '8px' }}>
              <FormControl className='select-entries' variant="outlined" size="small">
                <Select
                  value={paginationModel.pageSize}
                  onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                </Select>
              </FormControl>
              <span className='entries-per'>entries per page</span>
            </div>
            <div className='question-buttons'>
              <Button
                sx={{ marginRight: "0px" }}
                variant="contained"
                className="add-questionnaire-button"
                onClick={() => navigate(`/vendor/compliance-card`)}
              >
                <img src={Add} alt='icon' style={{ marginRight: "13px" }} />  Add Compliance
              </Button>
            </div>
            <Box
              sx={{
                width: '100%',
                '& .MuiDataGrid-columnHeaders': {
                  minWidth: '100%',
                  overflowX: 'hidden',
                },
                '& .MuiDataGrid-virtualScroller': {
                  minWidth: '100%',
                  overflowX: 'auto',
                },
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Search…"
                value={searchText}
                onChange={handleSearch}
                className='searh-text-field'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={SearchIcon} alt='icon' />
                    </InputAdornment>
                  ),
                }}
              />
              <DataGrid
                loading={showLoader}
                sx={{ border: "none", height: "409px", width: "100%" }}
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowHeight={70}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                paginationMode="client"
                disableSelectionOnClick
                autoHeight
                className='datagrid-table'
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 20]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
              }}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
              />
            </Box>
          </div>
          </Card>
          </Grid>
          </Grid>
      </Container>
      <ModalDialog
        handleClose={() => setShowDeleteComplianceModal(false)}
        open={showDeleteComplianceModal}
        onSubmit={removeCompliance}
        contentData={deleteModalContent}
      />
      <ShowCompliance
        handleClose={() => setShowComplianceModal(false)}
        open={showComplianceModal}
        contentData={activeCompliance}
      />

    </>
  );
}
