import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowRight from "../../assets/breadcum-arrow.svg";

const Breadcrumb = ({ crumbs }) => {
  const navigate=useNavigate();
  return (
    <nav aria-label="breadcrumb">
      <ul style={{ listStyle: 'none', display: 'flex', padding: '0px' }}>
        {crumbs.map((crumb, index) => (
          <li key={index} style={{ marginRight: '5px' }}>
            {crumb.path ? (
              <span 
                style={{ color: "#809FB8", cursor: 'pointer' }} 
                onClick={() => {
                  navigate(crumb.path, { state: { mainTabValue: crumb.mainTabValue } });
                }}
              >
                {crumb.label}
              </span>
            ) : (
              <span>{crumb.label}</span>
            )}
            {index < crumbs.length - 1 && (
              <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                <img style={{ verticalAlign: 'middle' }} src={ArrowRight} alt='icon' />
              </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;