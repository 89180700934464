// FaildeControl.js
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { InputAdornment, Select, Dialog } from "@mui/material";
import { CustomNoRowsOverlay } from "../Assessment.js"
import {
  Box,
  Button,
  Card,
  CircularProgress,
  MenuItem,
  Modal,
  Grid,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import GetRequest from "../../components/apiConnections/getRequest.js";
import PostRequest from "../../components/apiConnections/postRequest.js";
import CalendarTodayIcon from '../../assets/calender-icon-select.svg';
import Markdown from 'marked-react';

import AILoader from "../../AILoader.js";
import Loader from "../../Loader.js";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "solid #0000",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "15px",
  p: 1,
  height: "568px",
  overflowY: "auto",
};

const AiAssessments = (prop ) => {

  console.log("prop 123", prop);

  const data = useParams();
  const domainName = prop.domainName;
  const navigate = useNavigate();
  const [aiAssessmentsData, setAiAssessmentsData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [loading, setLoading] = useState(false);
  const [sendloading, setSendLoading] = useState(false);
  const [modelOpen, setModelOpen] = useState(null);
  const [generateRemedyLoader, setRemediationLoader] = useState(false);
  const theme = useTheme();
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [contactPersonList, setContactPersonList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [deadline, setDeadline] = useState("");
  const [remediation, setRemediation] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState();
  // const [openCreateRemediation, setOpenCreateRemediation] = React.useState(false);
  // const handleOpenCreateRemediation = () => setOpenCreateRemediation(true);
  // const handleCloseCreateRemediation = () => setOpenCreateRemediation(false);
  const [uniqueId, setUniqueId] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    getAiAssessments();
    getVendorContactPerson();
  }, []);

  useEffect(() => {
    if (aiAssessmentsData && aiAssessmentsData.length > 0) {
      console.log("aiAssessmentsData useEffect", aiAssessmentsData);

      let recommendations = aiAssessmentsData[0].detailInfo;
      console.log("recommendations", recommendations);
      // let recommendationsRegex = /#### Recommendations([\s\S]+?)(?=\n####|\n$)/;
      let recommendationsRegex = /####\s*Recommendations[\s\S]*?(?=\n####|$)/;;


      // Extracting the Recommendations section
      let recommendationsMatch = recommendations?.match(recommendationsRegex);
      console.log("recommendationsMatch", recommendationsMatch)

      if (recommendationsMatch) {
        console.log(recommendationsMatch[0].trim());
        setRemediation(recommendationsMatch[0].trim())
      } else {
        console.log('Recommendations section not found.');
        setRemediation("Recommendations section not found.")
      }
    }
  }, [aiAssessmentsData, modelOpen])


  const getAiAssessments = async () => {
    setshowLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_AIASSESSMENTS}?vendorDomain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.aiAssessments;
          setAiAssessmentsData(obj2);
          setshowLoader(false);
        })
        .catch((err) => {
          setshowLoader(false);
          setLoading(false);
          return false;
        });
    } catch (error) {
      setshowLoader(false);
      return false;
    }
  };

  const getVendorContactPerson = async () => {
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setContactPersonList(obj2);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Failed Questions not find", { variant: "error" });
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Failed Questions not find", { variant: "error" });
      return false;
    }
  };

  const sendRemediation = () => {
    const contactPerson = JSON.parse(selectedContactPerson || "{}");

    if (deadline === "") {
      enqueueSnackbar("Please select deadline.", { variant: "error" });
      return;
    }
    if (!contactPerson?.email) {
      enqueueSnackbar("Please select contact person.", { variant: "error" });
      return;
    }

    setSendLoading(true);

    const payload = {
      // remediationPlan: modelOpen,
      remediationPlan: remediation,
      firstName: contactPerson?.firstName,
      lastName: contactPerson?.lastName,
      email: contactPerson?.email,
      deadline,
      createdBy: localStorage.getItem("userEmail"),
      url: contactPerson?.vendorDomain,
      remediationType: "forGaps",
      uniqueId: uniqueId
    };

    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_REMEDIATION_BY_USER}`;

    PostRequest(url, payload)
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: "success" });
        setLoading(false);
        setModelOpen(null);
        setSendLoading(false)
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
      });
  };

  const handleContactPersonChange = (e) => {
    setSelectedContactPerson(e.target.value);
  };

  const handleRowClick = (params) => {
    console.log("params...", params.row.detailInfo);
    let detailInfo = params.row.detailInfo;

    // Remove the text like【1:1†vendorDocumentFiles_1.pdf】 and 【4:4†source】
    let cleanedText = detailInfo?.replace(/【\d+:\d+†vendorDocumentFiles_\d+\.pdf】/g, '');

    // Replace the **Vendor Name** field with the provided vendor name
    let updatedString = cleanedText.replace(/(\*\*(?:Vendor|Organization) Name\*\*:)(.*?)(?=\n)/, `$1 ${domainName}`);

    setDialogData(updatedString);
    setDialogOpen(true);
  };
  const filteredRows =
    (!aiAssessmentsData || !aiAssessmentsData.length) ? [] :
      aiAssessmentsData
        ?.map((val, i) => ({
          ...val,
          id: i + 1,
          uniqueId: val?.uniqueId,
          type: val?.type,
        }));
  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.2,
      className: "serial-number",
      disableColumnMenu: true,
    },
    {
      field: "uniqueId",
      headerName: "ID",
      flex: 1.1,
      minWidth: 170,
      disableColumnMenu: true,
    },


    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: "updatedAt",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: "createRemedation",
      headerName: "",
      flex: 1.1,
      minWidth: 100,
      renderCell: (params) => {
        console.log("params", params.row)
        return (
          <>
            {params?.row?.detailInfo ? (
              <span onClick={(e) => {
                e.stopPropagation();
                setModelOpen(params?.row.detailInfo)
                setUniqueId(params?.row?.uniqueId)
                setSelectedQuestion(params?.row)
              }}
                style={{ fontSize: "13px", width: "100%", padding: "15px 6px" }}
                className="create-remediation-button">
                {" "}
                + Remediation
              </span>
            ) : <span className="no-remediation-button" style={{
              backgroundColor: "gray"
            }}>
              --
            </span>}
          </>
        );
      },
    },
  ];
  const getCurrentDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      {generateRemedyLoader && (
        <AILoader show={generateRemedyLoader} />
      )}
      <Card className="full-coverdesign margin-questionnaire">
        <div className="cover-table">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              top: "0px",
              left: "8px",
            }}
          >
            <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
                value={paginationModel.pageSize}
                onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <span className="entries-per">entries per page</span>{" "}
          </div>

          <div className="issues-tab-table">
            <Box
              sx={{
                width: "100%",
                "& .MuiDataGrid-columnHeaders": {
                  minWidth: "100%",
                  overflowX: "hidden",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minWidth: "100%",
                  overflowX: "auto",
                },
              }}
            >

              <DataGrid
                loading={showLoader}
                rows={filteredRows}
                columns={columns}
                rowHeight={70}
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                components={{
                  NoRowsOverlay: () => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      No data
                    </Box>
                  ),
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  columns: ["id", "firstName", "lastName"],
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 25]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                paginationMode="client"
                disableSelectionOnClick
                autoHeight
                className="datagrid-table click-hover-datagrid"
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    minWidth: "100%", // Column headers take up full width
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minWidth: "100%", // Rows take up full width
                  },
                }}
                onRowClick={handleRowClick}
              />
            </Box>
          </div>
        </div>
      </Card>
      <Modal
        open={modelOpen !== null}
        onClose={() => setModelOpen(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          theme === "Dark"
            ? "dark-attack-modal modal-popup"
            : "light-attack-modal modal-popup"
        }
      >
        <Box sx={style} className="black-bg openai-response popup-remiadation scroll-design">
          <CloseIcon
            onClick={() => setModelOpen(null)}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              cursor: "pointer",
            }}
          />

          <form onSubmit={(e) => false} className="full-form">
            <Grid container spacing={3}>
              <Grid item xs={6} md={6} lg={6}>
                <h3 className="relation" style={{ color: "#fff" }}>
                  Vendor's Contact Person
                </h3>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {!selectedContactPerson ? "Select Person" : ""}
                  </InputLabel>
                  <TextField
                    labelId="demo-simple-select-label"
                    select
                    defaultValue=""
                    value={selectedContactPerson || ""}
                    onChange={handleContactPersonChange}
                  >
                    {contactPersonList && contactPersonList.length >= 0 && contactPersonList.map((item) => (
                      <MenuItem key={item._id} value={JSON.stringify(item)}>
                        {item.firstName} {item.lastName} {`(${item.email})`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <h3 className="relation" style={{ color: "#fff" }}>
                  Deadline
                </h3>
                <FormControl fullWidth>
                  <TextField
                    style={{ color: "#ffff", fontWeight: 600 }}
                    type="date"
                    defaultValue=""
                    name="deadLine"
                    onChange={(e) => setDeadline(e.target.value)}
                    inputProps={{
                      min: getCurrentDate(new Date()),
                      onKeyDown: (e) => e.preventDefault(),
                      // Remove the default calendar icon from the input field
                      style: {
                        WebkitAppearance: 'none', // Remove on Chrome/Safari/Edge
                        MozAppearance: 'textfield', // Remove on Firefox
                        appearance: 'none', // General appearance removal
                        position: 'relative',
                      },
                    }}
                    sx={{ color: "white" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* {/ Custom image for opening the date picker /} */}
                          <img
                            src={CalendarTodayIcon}
                            alt="calendar icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              document.getElementsByName('deadLine')[0].showPicker();
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className="fialed-control-poup">
              <h3 className="relation" style={{ color: "#fff" }}>
                Remediation
              </h3>
              <Markdown>{remediation}</Markdown>
            </div>

            <div className="remedy-send-btn" style={{ marginTop: "40px" }}>
              <Button
                variant="contained"
                className="executive-report-btn  Send-send-assessment"
                onClick={() => sendRemediation()}
              >
                Send
                {sendloading ? (
                  <CircularProgress size={12} sx={{ marginLeft: "0px" }} />
                ) : null}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <div>
        <Dialog className='gif-boxes'
          open={dialogOpen}
          // onClick={handleOutsideClick}
          onClose={() => setDialogOpen(false)}
        >
          <div className='assessing-overall-ai'>
            <div className="show-complaince-popup remove-line-type" style={{ margin: "0px", padding: "0px" }}>
              <h3></h3>
              <CloseIcon onClick={() => setDialogOpen(false)} style={{ cursor: "pointer" }} />
            </div>

            <div className="typing-effect">
              <Markdown>{dialogData}</Markdown>
            </div>

          </div>
        </Dialog>
      </div>
    </>
  );
};

export default AiAssessments;
