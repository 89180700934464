import React, { useEffect, useState } from 'react'
import SemiChart from "react-apexcharts";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Box } from "@mui/material";
import GaugeComponent from 'react-gauge-component';
import DNS from '../../assets/dns-image.svg';
import Chart from '../../components/chart';
import Carousel from 'react-material-ui-carousel';
import CircularProgress from "./../CircularProgress.js";
import { useSnackbar } from 'notistack';
import colorsNew from "../../utils/colorcode.js";

function OverviewHomeAdmin({ data, totalCritical, totalHigh, totalLow, totalMedium, complianceCardData, rows, totalScore, riskScore }) {
  // console.log("data", data)
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [domainName, setDomainName] = useState("");
  const [latestScanDate, setLatestScanDate] = useState();

  useEffect(() => {
    setLogo(
      data?.vendorData?.vendorInfo?.logo ||
      "/assets/images/avatars/user.png"
    );
  }, [data]);

  // useEffect(() => {
  //   if (domainName) {
  //     // getChartData();
  //     // getVendorOverAllRiskScore();
  //   }
  // }, [domainName]);

  const chartSeries2 = [totalCritical || 0, totalHigh || 0, totalMedium || 0, totalLow || 0];
  /*Close first chart*/
  const getEmptyDonutOptions = (baseOptions) => ({
    ...baseOptions,
    colors: ['#292154', '#292154', '#292154', '#292154'], // Light color to show "empty" donut
    plotOptions: {
      pie: {
        donut: {
          ...baseOptions.plotOptions.pie.donut,
          labels: {
            show: true,
            total: {
              show: true,
              label: 'No Data',
              fontSize: '16px',
              color: '#fff', // Muted color for "No Data" text
              fontFamily: 'Mona-Sans, sans-serif',
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff', // Muted color for total value
              },
              formatter: () => '0', // Show "0" for total when no data
            },
            value: {
              show: true,
              fontSize: '28x', // Font size for the total value
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff', // Total value color
              offsetY: 20 // Moved the total value upwards
            }
          },
        }
      }
    },
    tooltip: {
      enabled: false, // Disable tooltip on empty donut
    }
  });
  const chartOptions2 = {
    chart: {
      type: 'donut',
      width: "250",
      height: '250',
      dropShadow: {
        enabled: true,
        color: '#000', // Shadow color
        top: 2, // Vertical offset
        left: 0, // Horizontal offset
        blur: 3, // Blur radius
        opacity: 0.25 // Opacity of the shadow
      }
    },
    labels: [`Critical ${totalCritical || 0}`, `High ${totalHigh || 0}`, `Medium ${totalMedium || 0}`, `Low ${totalLow || 0}`],
    colors: [colorsNew.RED, colorsNew.ORANGE, colorsNew.YELLOW, colorsNew.GREEN,],
    stroke: {
      show: false, // Disable the outline/stroke
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      },
      breakpoint: 1200,
      options: {
        chart: {
          width: 300
        },

      }
    }],
    plotOptions: {
      pie: {
        donut: {
          size: '78%',
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 30,  // Move the name (category) label down
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Mona-Sans, sans-serif',
              color: '#fff',
              offsetY: -15, // Move the value label up
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '16px',
              color: '#fff',
              fontFamily: 'Mona-Sans, sans-serif',
              formatter: () => totalCritical + totalMedium + totalHigh + totalLow,
              style: {
                fontFamily: 'Mona-Sans, sans-serif',
                fontSize: '28px',
                color: '#fff',
              },
              offsetY: 50, // Move total label down
            },
          }
        }
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '13px',
        fontFamily: 'Mona-Sans, sans-serif',
        colors: ['#fff']
      }
    },
    legend: {
      position: 'right',
      offsetY: 40,
      height: 150,
      markers: {
        width: 11,           // Width of the square marker
        height: 12,          // Height of the square marker
        shape: 'square',     // Set the shape to square
        radius: 0,           // No border-radius for square
        offsetX: -5,
      }
    }
  };

  const donutoptions = {
    chart: {
      height: 100,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -140,
        endAngle: 140,
        hollow: {
          size: "65%",
        },
        track: {
          background: "#332961", // Track background color
          strokeWidth: "100%", // Track thickness
          margin: 0, // Space between bar and track
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "16px",
          },
          value: {
            show: true,
            fontSize: "16px",
            color: "#fff",
            offsetY: -10, // Center the value vertically
            formatter: function (val) {
              return val + "";
            },
          },
        },
        rounded: false, // Disable rounded corners

      },
    },
    colors: ["#533460"], // Single color for the segment
    stroke: {
      lineCap: "",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#E6171D"],
        inverseColors: false,
        stops: [0, 100],
      },
    },
    labels: [""],
  };

  return (
    <div className='top-first-tab'>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={8}>
          <div className='overall-risk-overview-page overall-risk-overview-page-admin'>
            <div className='flex-graph-half-donut'>
              <div className='graph-risk'>
                <p className='mtop-0 heading-new'>Overall Risk</p>
                <div className='graph-design-donut'>
                  <div className='gauge-semicircle gauge-container' style={{ width: '390px', height: '250px' }}>
                    {/* <span className="number-up" style={{ marginLeft: "67%", top: "90px" }}>
                        (<span className="number-up">
                          <img src={UpIcon} alt="up icon" />
                           {Math.round(riskScore)}%
                        </span>) :                       
                    </span> */}
                    <GaugeComponent
                      type="semicircle" // Using a semicircular gauge
                      arc={{
                        colorArray: [colorsNew.RED, colorsNew.ORANGE, colorsNew.YELLOW, colorsNew.GREEN,],
                        padding: 0.06, // Adjust padding for smoother arc transitions
                        subArcs: [
                          { limit: 25 },  // 25% of the total gauge
                          { limit: 50 },  // Next 25%
                          { limit: 75 },  // Next 25%
                          { limit: 100 }, // Last 25%
                        ],
                        arcWidth: 0.8, // Adjust arc thickness
                      }}

                      pointer={{ type: 'blob', animationDelay: 0, color: "#ffffff" }}
                      value={riskScore !== null ? Math.round(riskScore) : 0}
                      label={{
                        display: true,
                        text: `${Math.round(riskScore)}/100`,
                        style: {
                          fontSize: '25px',
                          fontWeight: 'bold',
                          fill: '#ffffff',
                          fontFamily: 'Mona-Sans',
                        },
                      }}
                    />
                    {latestScanDate && <p className='last-scan' style={{ textAlign: 'center', color: '#fff', marginTop: '0px' }}>
                      {latestScanDate}
                    </p>}
                  </div>
                </div>
              </div>

              <div className='inside-scan-div'>
                {/* <p className='outside-side-scan mb-12-side'>Inside Out - Scan</p> */}
                <div className="fullcover-width">
                  {/* <CircularProgress percent={sumScore} /> */}
                </div>
                <div className="fullcover-width">
                  {riskScore && <CircularProgress percent={riskScore} />}
                </div>
                <p className='outside-side-scan'>Outside in - Scan</p>
                <div className='Relationship Risk'>
                  <div className='ranking-total'>
                    <h3>{totalScore}</h3>
                    <p>Total Findings  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <div className='same-base-bg'>
            <div className="top-heading-dashboard">
              <h2 style={{ margin: "13px 0px 0px" }}>Issue Distribution</h2>
              <div className="legend-text-issue-legend top-space-middle-chart">
                {(totalCritical + totalMedium + totalHigh + totalLow) > 0 ? (
                  <Chart
                    options={chartOptions2}
                    series={chartSeries2}
                    type="donut"
                  />
                ) : (
                  <Chart
                    options={getEmptyDonutOptions(chartOptions2)}
                    series={[1]}
                    type="donut"
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>

        {/* compliances card */}
        <Grid item xs={12} md={6} lg={6}>
          <div className='same-base-bg carousel-box-inline'>
            <h2>Compliance</h2>
            {complianceCardData?.length ? (
              <Box className="Carousel-vertical-align-middle">
                <Carousel
                  navButtonsAlwaysVisible={true}
                  autoPlay={false}
                  indicators={false}
                >
                  {complianceCardData?.map((card, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {card.length
                          ? card?.map((item, j) => {
                            return (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_DOCUMENT}/${item.document}`}
                              >
                                <Box
                                  sx={{ marginX: "10px" }}
                                  key={j}
                                >
                                  <h3>{item?.name}</h3>
                                  <img
                                    src={`${process.env
                                      .REACT_APP_BACKEND_BASE_URL
                                      }${item.complianceType ==
                                        "Others"
                                        ? process.env
                                          .REACT_APP_STATIC_PATH_COMPLIANCE_CARD_LOGO
                                        : process.env
                                          .REACT_APP_STATIC_PATH_ASSETS
                                      }/${item.logo}`}
                                    className="iso-image"
                                    alt="logo"
                                  />
                                </Box>
                              </a>
                            );
                          })
                          : null}
                      </Box>
                    );
                  })}
                </Carousel>
              </Box>
            ) : (
              <div className="no-card-data">
                <p>No Compliance Data Found</p>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <div className='same-base-bg' style={{ height: "59% !important" }}>
            <h2>Outside In Score</h2>
            {rows ?
              (<TableContainer className='tablesame-outsidescore'>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Home</TableCell>
                      <TableCell>Grade</TableCell>
                      <TableCell>Risk Score</TableCell>
                      <TableCell>Issues</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow>
                        <TableCell sx={{
                          display: "flex",
                          alignItems: "center", gap: "10px", paddingBottom: "6.2%"
                        }}><img src={DNS} />{row.category}</TableCell>
                        <TableCell>
                          <span className={`gradClass ${row.grade}`}>
                            {row.grade}
                          </span>
                        </TableCell>
                        <TableCell className="relative radialBar-new">
                          {row.score ? <div className='radialBar mr-side0'>
                            <SemiChart id="radialBar-new-overviewpage" className="" options={{
                              ...donutoptions,
                              fill: {
                                ...donutoptions.fill,
                                gradient: {
                                  ...donutoptions.fill.gradient,
                                  gradientToColors: [row?.score <= 25 ? "#E6171D" : row?.score <= 50 ? "#B35405" : row?.score <= 75 ? "#B7AF08" : "#4FE71CB2"]
                                }
                              }
                            }} series={[row?.score]} type="radialBar" height={93} />
                            {/* {Number(row?.score) !== Number(row?.oldScore) && <span className={`number-up ${Number(row?.score) < Number(row?.oldScore) ? "number-down" : null}`}>
                              {(Number(row?.score) && Number(row?.oldScore)) ? <> <img src={
                                Number(row?.score) < Number(row?.oldScore) ? DownIcon : UpIcon} alt="icon" />{
                                  ((Math.abs(Number(row?.score) - Number(row?.oldScore)) * 100) / Number(row?.oldScore))?.toFixed(2) || 0}% </> : "0%"}
                            </span>} */}
                          </div> : "-"}

                        </TableCell>
                        <TableCell>
                          {row.issue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>) :
              (<Box sx={{ display: "flex", justifyContent: "center", minHeight: "190px", alignItems: "center", width: "100%" }}>
                <CircularProgress />
              </Box>
              )}

          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default OverviewHomeAdmin