import React, { useState } from "react";
import GetRequest from "../components/apiConnections/getRequest";
import { useSnackbar } from "notistack";
import GenAi from "../assets/gen-ai.svg";
export default function AiEvidence({ domain, assessmentId, getQuestionnaire, setLoader }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const getAiEvidence = () => {
    setLoading(true);
    setLoader(true);
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_ASSESSMENT_EVIDENCE_MAPPING}?assessmentId=${assessmentId}&domain=${domain}`;

    GetRequest(url, {})
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: "success" });
        setLoading(false);
        setLoader(false )
        setData(response?.data?.data);
        getQuestionnaire();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
        setLoader(false )
      });
  };

  return (
    <>
      <div className="flex-ai-animation">
          <div className="animation-ai-round" onClick={() => getAiEvidence()}>
            {" "}AI Verification
          </div>
        </div>
    </>
  );
}
