import GetRequest from "../components/apiConnections/getRequest";
import PostRequest from "../components/apiConnections/postRequest";

export const GetPermissionList = async () => {
    console.log("insdiesssssss")
    let resObj = { error: true, message: "unable to get permission" }
    try {
        const refreshToken = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            {},
            {},
            "refreshToken"
        );
        console.log(refreshToken, "refreshToken")
        if (refreshToken) {
            await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_PERMISSION_List}`,
                {}
            )
                .then((resChart) => {
                    let data = resChart.data.data;
                    resObj = { error: false, message: null, data: data }
                })
                .catch((errChart) => {
                    resObj = { error: true, message: errChart.data?.message || errChart.message }
                })
        }
    }
    catch (error) {
        console.log(error)
    }
    return resObj;
}





