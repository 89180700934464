import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import PostRequest from "../../components/apiConnections/postRequest.js";
import SendArrow from '../../assets/top-arrow-btn.svg';
import VendorDetailAnswer from "./VendorDetailAnswer.js";
import ComplianceBarChart from "./ComplianceBarChart.js";
import colors from "../../utils/colorcode.js";
import Delayed from '../../assets/delayed.svg.svg';
import ModalDialog from "../ModalDialog.js";
import ExtendDeadlineModel from "../models/ExtendDeadlineModel.jsx";
import { useSelector } from "react-redux";
import moment from "moment";
import Markcomplete from '../../assets/mark-complete.svg';
import Trash from '../../assets/trahs.svg';
import Delete from "../../assets/delete.svg";
import ExtendDeadline from '../../assets/extend-deadline.svg';
function Compliance({
  complainceData,getAssessment
}) {
  const data = useParams();
  let selector = useSelector((state) => state.permissionList.permissionList);
  const navigate = useNavigate();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const { enqueueSnackbar } = useSnackbar();
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [deadline, setDeadline] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeAssessment, setActiveAssessment] = useState({});
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showExtendsDeadlineModal, setShowExtendsDeadlineModal] = useState(false);
  const [showDeleteAssessmentModal, setShowDeleteAssessmentModal] = useState(false);
  const [updateAssessmentStatus, setUpdateAssessmentStatus] = useState('');
  const [extendDeadline, setExtendDeadline] = useState('');
  const [columns, setColumns] = useState([
    { field: "uid", headerName: "#", editable: false, maxWidth: 60, disableColumnMenu: true, },
    { field: "set", headerName: "Set", editable: false, minWidth: 130, headerAlign: 'left', align: 'left', flex: 1.2, disableColumnMenu: true, },
    { field: "firstName", headerName: "Name", editable: false, headerAlign: 'left', align: 'left', flex: 1, disableColumnMenu: true, },
    {
      field: "score",
      headerName: "Score",
      editable: false,
      sortable: true,
      minWidth: 150,
      headerAlign: 'left', align: 'left', flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {

        let { totalAnswereGiven, totalAnswerePass, totalQuestionsInAssessment } = params.row;
        if (!totalQuestionsInAssessment || totalQuestionsInAssessment === 0) return <>-</>
        const score = (totalAnswerePass / totalQuestionsInAssessment) * 100
        let color = '';
        if (score <= 25) {
          color = colors.RED;
        } else if (25 < score && score <= 50) {
          color = colors.ORANGE;
        } else if (50 < score && score <= 75) {
          color = colors.YELLOW;
        } else {
          color = colors.GREEN;
        }

        let chartInfo = {
          fillPercentage: parseInt(score),
          color: color
        };
        return <ComplianceBarChart fillPercentage {...chartInfo} />
        // return <> { Math.trunc(score)} /100 </> 
      },
    },
    {
      field: "completion",
      headerName: "Completion",
      editable: false,
      sortable: true,
      minWidth: 70,
      headerAlign: 'center', align: 'center', flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        let { totalAnswereGiven, totalAnswerePass, totalQuestionsInAssessment } = params.row;
        if (!totalQuestionsInAssessment || totalQuestionsInAssessment === 0) return <>-</>
        const score = (totalAnswereGiven / totalQuestionsInAssessment) * 100
        return <>{Math.trunc(score)}% </>
      },
    },
    {
      field: "startDate",
      headerName: "Sent Date",
      editable: false,
      sortable: true,
      minWidth: 100,
      headerAlign: 'center', align: 'center', flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "deadline",
      headerName: "Deadline",
      editable: false,
      sortable: true,
      minWidth: 100,
      headerAlign: 'center', align: 'center', flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'assessmentStatus',
      headerName: 'Status',
      editable: false,
      minWidth: 100,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        let status = params.value;
        let deadline = params.row?.deadline;
        const [day, month, year] = deadline.split('/');
        const formattedDeadline = `${year}-${month}-${day}`;
        const dead = new Date(formattedDeadline);
        const currentDate = new Date();
        const result = Math.sign(dead - currentDate);

        return (((status == "Sent") || (status == "In Progress")) ?
          <>
            <span className={`statusClass ${status}  `}>{status}</span>
            {result < 0 && <img src={Delayed} className="deadline-icon" />}
          </>
          :
          <>
            <span className={`statusClass ${status}`}>{status}</span>
            {/* {result < 0 && <img src={Delayed} className="deadline-icon" />} */}
          </>
        )
      },
    },
    {
      field: "action",
      headerName: "",
      minWidth: 40,
      sortable: false,
      headerAlign: 'center', align: 'center', flex: 0.2,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={(event) => handleActionClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ]);
  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector.includes(value)) return true;
    else return false;
  }
  const handleActionClick = (event, row) => {
    event.stopPropagation();
    const [day, month, year] = row.deadline.split('/');
    const formattedDeadline = `${year}-${month}-${day}`;
    setDeadline(formattedDeadline);
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = (event) => {
    event.stopPropagation();
    setSelectedRow(null);
    setDeadline(null);
    setExtendDeadline(null);
    setAnchorEl(null);
  };
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };


  useEffect(() => {

  }, []);
  
  const updateAssessment = async (status) => {
    const payload = {
      assessment_id: activeAssessment._id,
      assessmentStatus: updateAssessmentStatus
    };
    if(status){
      payload.assessmentStatus = status;
    }
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_ASSESSMENT_STATUS}`,
          payload,
          {}
        )
          .then((res) => {
            // let data = resChart.data.data;

            setShowLoader(false);
            enqueueSnackbar(res.data?.message, { variant: "success" });
            getAssessment();
          })
          .catch((err) => {
            enqueueSnackbar(err?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
    // setShowAcceptDeclineModal(false)
    setShowCompleteModal(false)
  }
  const deleteAssessmentAPI = async () => {
    setShowLoader(true);
    const payload = {
      assessmentId: activeAssessment._id,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DELETE_ASSESSMENT}`,
          payload,
          {}
        )
          .then((resChart) => {
            setShowLoader(false);
            setShowDeleteAssessmentModal(false);
            enqueueSnackbar(resChart.data?.message, { variant: "success" });
            getAssessment();
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };
  const extendsDeadlineDateAPI = async (e) => {
    if (!extendDeadline) {
      enqueueSnackbar('Please select the extend deadline date', { variant: 'error' })
      return false;
    }
    if (extendDeadline <= deadline) {
      enqueueSnackbar('Extend deadline date should be greater then the deadline date', { variant: 'error' })
      return false;
    }
    // Convert the date strings to Unix timestamps
    const deadlineUnix = moment(deadline, "YYYY-MM-DD").unix();
    const extendDeadlineUnix = moment(extendDeadline, "YYYY-MM-DD").unix();
    const payload = {
      assessment_id: activeAssessment._id,
      deadline: extendDeadlineUnix,
      extendedDeadlineCount: activeAssessment.extendedDeadlineCount ? (activeAssessment.extendedDeadlineCount + 1) : 1
    };
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_ASSESSMENT_DEADLINE}`,
          // `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"update-assessment-deadline"}`,
          payload,
          {}
        )
          .then((res) => {
            // let data = resChart.data.data;
            setShowLoader(false);
            setShowExtendsDeadlineModal(false)
            enqueueSnackbar(res.data?.message, { variant: "success" });
            getAssessment();
          })
          .catch((err) => {
            enqueueSnackbar(err.data?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  }
  const ModalContentCompleteAssessment = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Markcomplete} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to mark this assessment as complete ?
        </h2>
      </Typography>
    );
  };
  const ModalContentAssessment = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to remove this assessment ?
        </h2>
      </Typography>
    );
  };
  const handleExtendDeadlineChange = (event) => {
    setExtendDeadline(event.target.value);
  };
  // Menu component for the three-dot menu
  const handleRowClick = (params) => {
    console.log(params)
    navigate(`/dashboard/vendor-answer/${params._id}`, { state: { data: params, email: params?.email, prevUrl: '/dashboard/assessment', mainTabValue: 2 } });
  };
  const getCurrentDate = (date) => {
    let parseDate = date
    if (date !== deadline) parseDate = moment(parseDate).add(1, 'day')
    let currentDate = new Date(parseDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <div className="cover-table">
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <FormControl className='select-entries topmove-form' variant="outlined" size="small">
            <Select
              value={paginationModel.pageSize}
              onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <span className='entries-per topmove-entries'>entries per page</span>
          <Button
            variant="contained"
            className="send-assessment"
            onClick={() => navigate(`../vendor-information/${vendorUrl}`, { state: `vendorDetails/${vendorUrl}` })}
          >
            <img src={SendArrow} className="send-arrow-icon" /> Send Assessment
          </Button>
        </div>

        <Box
          sx={{
            width: '100%',
            '& .MuiDataGrid-columnHeaders': {
              minWidth: '100%',
              overflowX: 'hidden',
            },
            '& .MuiDataGrid-virtualScroller': {
              minWidth: '100%',
              overflowX: 'auto',
            },
          }}
        >
          <DataGrid
            loading={showLoader}
            rows={complainceData} // Ensure you're using the filtered rows
            columns={columns}
            pageSize={pageSize}
            rowHeight={70}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            onPaginationModelChange={handlePaginationModelChange}
            paginationModel={paginationModel}
            paginationMode="client"
            autoHeight
            className='datagrid-table click-hover-datagrid'
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                minWidth: '100%', // Column headers take up full width
              },
              '& .MuiDataGrid-virtualScroller': {
                minWidth: '100%', // Rows take up full width
              },
            }}
            onRowClick={(params) => handleRowClick(params.row)}
          />
        </Box>
        {selectedRow && (
          <Menu
            id="actions-menu"
            className='rowmenu assessment-send'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {(checkPermission("Edit Assessment") &&
              (selectedRow.assessmentStatus === "Submitted" || selectedRow.assessmentStatus === "Engagement")) ?
              <MenuItem
                onClick={() => {
                  setActiveAssessment(selectedRow);
                  setShowCompleteModal(true);
                }}>
                <img src={Markcomplete} /> Mark as complete
              </MenuItem> : null}

            {(checkPermission("Edit Assessment") &&
              (selectedRow.assessmentStatus === "In Progress" || selectedRow.assessmentStatus === "Extended Deadline" || selectedRow.assessmentStatus === "Sent") && selectedRow.extendedDeadlineCount < 2 ) ?
              <MenuItem
                onClick={() => {
                  setActiveAssessment(selectedRow);
                  setShowExtendsDeadlineModal(true);
                }}
              >
                <img src={ExtendDeadline} />
                Extend Deadline
              </MenuItem> : null}
            {checkPermission("Delete Assessment") ?
              <MenuItem
                onClick={() => {
                  setActiveAssessment(selectedRow);
                  setShowDeleteAssessmentModal(true);
                }}
              >
                <img src={Trash} />
                Remove
              </MenuItem>
              : null}
          </Menu>
        )}
      </div>


      {openSidebar &&
        <VendorDetailAnswer
          handleClose={() => setOpenSidebar(false)}
          open={openSidebar}
        />
      }
      <ModalDialog
        handleClose={() => setShowDeleteAssessmentModal(false)}
        open={showDeleteAssessmentModal}
        onSubmit={() => deleteAssessmentAPI()}
        contentData={ModalContentAssessment()}
        loader={showLoader}
      />
      <ModalDialog
        handleClose={() => setShowCompleteModal(false)}
        open={showCompleteModal}
        onSubmit={() => {
          // setUpdateAssessmentStatus()
          updateAssessment("Completed")
        }
        }
        contentData={ModalContentCompleteAssessment ()}
      />
      <ExtendDeadlineModel

        handleClose={() => setShowExtendsDeadlineModal(false)}
        open={showExtendsDeadlineModal}
        onSubmit={() => extendsDeadlineDateAPI()}
        deadline={deadline}
        // contentData={ModalContentExtendDeadlineDate(deadline)}
        handleExtendDeadlineChange={handleExtendDeadlineChange}
        getCurrentDate={getCurrentDate}
      />

    </>
  );
}

export default Compliance;
