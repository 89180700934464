import React, { useEffect, useState } from "react";
import AddVendor from "./add-client/addVendor";
import { useSnackbar } from "notistack";
import PostRequest from "../components/apiConnections/postRequest";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import GetRequest from "../components/apiConnections/getRequest";
import "./css/addClient.css";
import StepperComponent, { AddEditClientScreenTypes } from "./add-client/StepperComponent";
import Loader from "../Loader";
import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import UpdateProfile from "./add-client/updateProfile";
import NewHeader from "../layouts/dashboard/header/NewHeader";

const UpdateClientProfile = () => {

  const location = useLocation();
  const clientDetail = location.state;

  console.log('clientDetail on update',clientDetail)
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeComponent, setActiveComponent] = useState(
    AddEditClientScreenTypes.Details
  );
  const [showLoader, setShowLoader] = useState(false);
  const [client, setClient] = useState({
    clientId: id || null,
    logo: null,
    company: "",
    websites: [],
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    twoFAMethod: "email",
    numberOfVendors: 700,
    numberOfUsers: 20,
    numberOfWebsites: 0,
    isQuestionniareEnabled: 1,
    numberOfQuestionniare: 30,
    isAttackRequestEnabled: 1,
    numberOfAttackRequests: 10,
    isAssessmentEnabled: 1,
    numberOfAssessments: 5,
    selectedTab: 0,
    vendorDomainName: "",
    vendorDomain: [],
    filename: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const isUpdateMode = !!id;

  useEffect(() => {
    if (id) getClientData();
  }, []);

  const addClientDetails = (data) => {
    setClient({ ...client, ...data });
  };
  const changeActiveComponent = (componentName) => {
    if (
      isUpdateMode &&
      (componentName === AddEditClientScreenTypes.Subscriptions ||
        componentName === AddEditClientScreenTypes.AddVendors)
    ) {
      setActiveStep((prevStep) => prevStep + 1);
      setActiveComponent((prevComponent) => prevComponent + 1);
    } else {
      setActiveComponent(componentName);
      setActiveStep(componentName - 1);
    }
  };

  const getClientData = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_DATA}?clientId=${id}`
        )
          .then((resChart) => {
            let userDetail = resChart.data?.data;
            // setShowLoader(false);

            if (userDetail?._id) {
              let obj = {
                ...userDetail,
                logo: userDetail.profilePic,
                email: userDetail.emailId,
              };
              if (userDetail.clientDetail) {
                obj = { ...obj, ...userDetail.clientDetail };
                delete obj.clientDetail;
              }
              setClient({ ...obj });
            }
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
            // setShowLoader(false);
          });
      } else {
        // setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      // setShowLoader(false);
    }
  };

  const submitData = async (data) => {
    setShowLoader(true);
    let payload = new FormData();
    if (id) payload.append("clientId", id);
    payload.append("firstName", data.firstName || client.firstName);
    payload.append("lastName", data.lastName || client.lastName);
    payload.append("company", data.company || client.company);
    payload.append("email", data.email || client.email);
    payload.append("mobile", data.mobile || client.mobile);
    payload.append("twoFAMethod", data.twoFAMethod || client.twoFAMethod);
    payload.append("logo", data.logo || client.logo);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_MODIFY_CLIENT}`,
          payload
        )
          .then((resChart) => {
            enqueueSnackbar(resChart.data?.message, { variant: "success" });
            setShowLoader(false);
            setClient({});
            navigate("/admin/clients-management");
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

    return (
        <>
        <Helmet>
            <title> Profile | Genesis Platform</title>
        </Helmet>
        <NewHeader titleName="Update Profile" />
        <Container maxWidth={false}>
            <UpdateProfile
                props={{
                client: clientDetail,
                addClientDetails: addClientDetails,
                changeActiveComponent: changeActiveComponent,
                submitData: submitData,
                }}
                stepper={<StepperComponent activeStep={activeStep} />}
            />
        </Container>
        </>
    );      
  
};

export default UpdateClientProfile;
