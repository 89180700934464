import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import './css/Vendors.css';
// @mui
import {
  Card,
  MenuItem,
  Container,
  Button,
  Select,
  FormControl,
  Box,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material';
import USERLIST from '../_mock/user';
import PostRequest from "../components/apiConnections/postRequest";
import moment from 'moment';
import { useSnackbar } from 'notistack';
import UploadVendorJSON from '../components/modal/UploadVendorJSON';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import NewHeader from '../layouts/dashboard/header/NewHeader';
import { DataGrid } from '@mui/x-data-grid';
import defaultLogo from '../assets/companyLogo.jpg';
import { CustomNoRowsOverlay } from './Assessment';
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RequestsPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState();
  const [rows, setRows] = useState([]);
  // const [vendors, setVendors] = useState({});
  const [vendorsData, setVendorsData] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [showUploadJsonModal, setShowUploadJsonModal] = useState(false);
  const [activeUrl, setActiveUrl] = useState("")
  const { enqueueSnackbar } = useSnackbar();
  const [pageSize, setPageSize] = useState(5);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows);
  const handleCloseMenu = () => {
    setOpen(null);
  };

  useEffect(() => {
    getRequestsforVendors();
  }, []);

  const getRequestsforVendors = async () => {
    setshowLoader(true);
    const endpoint = process.env.REACT_APP_ALL_VENDORS_REQUESTS;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            // setisNotFound(!(resVendors.data.data.length > 0));
            console.log("arrResponse:", arrResponse);
            setRows(
              arrResponse.length
                ? [
                  ...arrResponse?.map((d, i) => ({
                    ...d,
                    id: i + 1,
                    requestUrl: d.requestUrl,
                    requestDate: moment(d.requestDate).format("DD/MM/YYYY"),
                    status: d.status === 1
                      ? "Fulfiled"
                      : "Awaiting"
                  })),

                ]
                : []
            );
            setVendorsData(arrResponse);
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: 'error' });
            setshowLoader(false);
          })
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setshowLoader(false);

    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  const handleRowClick = (params) => {
    console.log(params)
  };
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    const filtered = rows.filter((row) =>
      Object.values(row).some((field) =>
        String(field).toLowerCase().includes(value)
      )
    );
    setFilteredRows(filtered);
  };
  const [columns, setColumns] = useState([
    { field: "id", headerName: "#", editable: false, maxWidth: 90, disableColumnMenu: true, },
    {
      field: "requestUrl",
      headerName: "URL",
      editable: false,
      flex: 1.4,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <span className="logo-image-vendor">
            <img className="attack-surface-list-logo" src={
              `${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${params.value}`} alt="Figma" onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultLogo;
              }} />
          </span>
          <span style={{ display: 'flex', gap: '6px' }}>
            {params.value}
          </span>
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date Time",
      editable: false,
      headerAlign: 'left',
      align: 'left',
      flex: 1.4,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return moment(params.value).format("DD MMM YYYY");
      }
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        let status = params.value;
        return (status === "Awaiting" ?
          <span className={`statusClass Deboarding`}>{status}</span>
          :
          <span className={`statusClass Onboarding`}>{status}</span>
        );
      }
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      headerAlign: 'center', align: 'right', flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button onClick={() => { setShowUploadJsonModal(true); setActiveUrl(params.row.requestUrl) }}><UploadFileIcon /></Button>
        );
      },
    },
  ]);
  return (
    <>
      <Helmet>
        <title> Requests | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
      <NewHeader titleName="All vendors requests" />
        {/* <div style={{
              fontFamily: "Mona-Sans, sans-serif",
              fontSize: "22px",
              fontWeight: "600",
              lineHeight: "21.27px",
              textAlign: "left",
              paddingTop: "6px",
              paddingBottom: "10px",
              marginBottom: "11px",
              marginLeft:"2%"
        }}>All vendors requests</div> */}
        {/* <Grid container spacing={3} sx={{ mb: 3 }} className="ipad-space"> */}
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <Card className="full-coverdesign margin-questionnaire">
              <div className="cover-table">
                <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            top: "4px",
                            left: "5px",

                        }}
                    >
                  <FormControl className='select-entries' variant="outlined" size="small">
                    <Select
                      value={paginationModel.pageSize}
                      onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                  <span className='entries-per'>entries per page</span>
                </div>

                <div className="issues-tab-table">
                        <Box
                            sx={{
                                width: "100%",
                                "& .MuiDataGrid-columnHeaders": {
                                    minWidth: "100%",
                                    overflowX: "hidden",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    minWidth: "100%",
                                    overflowX: "auto",
                                },
                            }}
                        >
                  <DataGrid
                    loading={showLoader}
                    rows={rows} // Ensure you're using the filtered rows
                    columns={columns}
                    pageSize={pageSize}
                    rowHeight={70}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onPaginationModelChange={handlePaginationModelChange}
                    paginationModel={paginationModel}
                    paginationMode="client"
                    autoHeight
                    className='datagrid-table'
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    autosizeOptions={{
                      columns: ["id", "firstName", "lastName"],
                      includeOutliers: true,
                      includeHeaders: false,
                    }}
                    pageSizeOptions={[5, 10, 25, 50]}
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        minWidth: '100%', // Column headers take up full width
                      },
                      '& .MuiDataGrid-virtualScroller': {
                        minWidth: '100%', // Rows take up full width
                      },
                    }}
                    onRowClick={(params) => handleRowClick(params.row)}
                  />
                </Box>
                </div>
                </div>
            </Card>
          </Grid>
        </Grid>
              {/* <Card className='full-coverdesign'>
          <div className='cover-table'>
            <h3 className='vendor-head'>All vendors requests</h3>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table checkboxSelection={false} className='table-vendor' >
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                      const { id, requestUrl, requestDate, status } = row;
                      const selectedUser = selected.indexOf(requestUrl) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} selected={selectedUser}>

                          <TableCell component="th" scope="row" align="center">
                            <Typography variant="subtitle2" noWrap>
                              {requestUrl}
                            </Typography>
                          </TableCell>



                          <TableCell align="center">{
                            moment(new Date(requestDate * 1000)).format("MMMM Do YYYY, h:mm:ss a")
                          }</TableCell>

                          <TableCell align="center" className='not-third'>
                            <Label color={(status === 0 ? 'error' : 'success')}>{status === 0 ? 'Awaiting' : 'Fulfilled'}</Label>

                          </TableCell>

                          <TableCell align="center">
                            <Button onClick={() => { setShowUploadJsonModal(true); setActiveUrl(requestUrl) }}><UploadFileIcon /></Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={vendorsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Card> */}
          </Container >

            {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Remove
        </MenuItem>
      </Popover> */}

            < UploadVendorJSON
              open={showUploadJsonModal}
              handleClose={() => setShowUploadJsonModal(false)
              }
              activeUrl={activeUrl}
              handleSuccess={() => { setShowUploadJsonModal(false); getRequestsforVendors() }}
            />
          </>
          );
}
