import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import {
  Stack,
  Box,
  Alert,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import PostRequest from "../components/apiConnections/postRequest";
import Iconify from "../components/iconify";
import fullDark from "../assets/full-dark.png";
import fullLight from "../assets/full-light.png";
import { Icon } from '@iconify/react';

import "./css/LoginPage.css";
const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function ClientConfirmAccountPage() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const token = params.token;
  const navigate = useNavigate();
  const theme = localStorage.getItem("theme") || "Dark";
  const [alertData, setAlertData] = useState({
    showalert: true,
    message: "",
    alerttype: "error",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (alertData.showalert) {
      setTimeout(() => {
        setAlertData({});
      }, 3000);
    }
  }, [alertData]);

  const confirmAccount = (e) => {
    e.preventDefault();
    setShowLoader(true);
    const payload = {
      token,
      password,
      confirmPassword,
    };
    const userResult = PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_ACTIVATE_ACCOUNT}`,
      payload
    );
    userResult
      .then((res) => {
        if (res.data.code === 200) {
          setShowLoader(false);
          enqueueSnackbar("Account activate successfully.", {
            variant: "success",
          });
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 2000);
        } else {
          setShowLoader(false);
          enqueueSnackbar(`${res.data.message || "something went wrong"}`, {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        setShowLoader(false);
        enqueueSnackbar(`${err.message || "Account not activated."}`, {
          variant: "error",
        });
      });
  };
  return (
    <>
      <Helmet>
        <title> Account Confirm | Genesis Platform </title>
      </Helmet>
      <div
        className={
          `login-screen ` + (theme === "Dark" ? "body-dark" : "body-light")
        }
      >
        <Container maxWidth={false} className="login-right-padding">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <StyledRoot>
                <StyledContent>
                  <Typography variant="h4" gutterBottom>
                    <img
                      src={theme === "Dark" ? fullDark : fullLight}
                      className="logo-size"
                      alt="logo"
                    />
                    <br />
                  </Typography>
                  <h2>Change Your Password</h2>
                  <form
                    onSubmit={(e) => confirmAccount(e)}
                    className="relative active-account"
                  >
                    <Stack spacing={3}>
                      <div className="inputfield">
                        <TextField
                          name="password"
                          label="Password"
                          placeholder="Password"
                          className="inputfield"
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" className="eye-icon">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? <Icon icon="mdi:eye-outline" style={{ width: 20, height: 18 }} /> : <Icon icon="solar:eye-closed-bold" style={{ width: 20, height: 18 }} />}
                                </IconButton>
                              </InputAdornment>  
                            ),
                          }}
                        />
                      </div>
                      <div className="inputfield">
                        <TextField
                          name="confirmPassword"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          className="inputfield"
                          type={showConfirmPassword ? "text" : "password"}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                                  
                                  <InputAdornment position="end" className="eye-icon">
                                  <IconButton
                                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    edge="end"
                                
                                >
                                    {showConfirmPassword ? <Icon icon="mdi:eye-outline" style={{ width: 20, height: 18 }} /> : <Icon icon="solar:eye-closed-bold" style={{ width: 20, height: 18 }} />}
                                  </IconButton>
                                </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Stack>
                    <LoadingButton
                      loading={showLoader}
                      loadingIndicator={
                        <CircularProgress
                          size={12}
                          sx={{ marginRight: "80px", color: "white" }}
                        />
                      }
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{ marginBottom: "30px" }}
                      className='login-buttton-gradient'
                    >
                      <span>Confirm</span>
                    </LoadingButton>
                  </form>
                </StyledContent>
              </StyledRoot>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <div className="bg-login-new">
                <div className="fit-login-area">
                  <h1>
                    To secure your account, please set a new password.
                  </h1>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
