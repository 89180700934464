import { useState } from 'react';
import {
    Grid, CircularProgress, Modal, Box, Button,
    TextField, Typography, InputAdornment, IconButton
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import Iconify from '../iconify/Iconify';
import { useSnackbar } from "notistack";
import PostRequest from '../apiConnections/postRequest';
import PutRequest from '../apiConnections/putRequest';

export default function ModalDialog({
    handleClose,
    open,
    userDetail,
    handleSuccess
}) {
    const { enqueueSnackbar } = useSnackbar();
    const theme = localStorage.getItem("theme");
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [loader, setLoader] = useState(false)

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "#120A3A",
        boxShadow: 24,
        borderRadius: "15px",
    };

    const changeUserPasswordAPI = async () => {
        if (password?.length < 8) {
            enqueueSnackbar("password must contain minimum 8 character", { variant: "error" });
            return;
        }
        if (password !== confirmPassword) {
            enqueueSnackbar("password and confirm password not matched", { variant: "error" });
            return;
        }
        setLoader(true);
        const payload = {
            clientUserId: userDetail._id,
            password,
            confirmPassword
        };
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RESET_CLIENT_USER_PASSWORD}`,
                    payload,
                    {}
                )
                    .then((resChart) => {
                        setLoader(false);
                        enqueueSnackbar(resChart.message || resChart.data?.message, { variant: "success" });
                        setPassword("")
                        setConfirmPassword("")
                        setShowPassword(false)
                        setShowConfirmPassword(false)
                        handleSuccess()
                    })
                    .catch((errChart) => {
                        enqueueSnackbar(errChart.message || errChart.data?.message, { variant: "error" });
                        setLoader(false);
                    });
            } else {
                setLoader(false);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setLoader(false);
        }
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme === "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
                }
            >
                <Box sx={style}>
                    <Button onClick={handleClose} sx={{ float: "right", color: "white", mt: 1 }}>
                        <CloseIcon />
                    </Button>
                    <Typography variant="h3" sx={{ m: 2 }}>Change User Password </Typography>
                    <Grid item xs={12} md={12} lg={12} sx={{ my: 2 }}>
                        <Grid item xs={12} md={6} lg={6} pb={1} sx={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
                            <TextField
                                name="firstName"
                                value={password || ""}
                                label="New Password"
                                inputProps={{ maxLength: 15 }}
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className="eye-icon">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                <Iconify
                                                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                                                    sx={{ color: "white" }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => setPassword(e.target.value)}
                                sx={{ width: "90%" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} pb={1} sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <TextField
                                name="lastName"
                                label="Confirm New Password"
                                value={confirmPassword || ""}
                                inputProps={{ maxLength: 15 }}
                                type={showConfirmPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className="eye-icon">
                                            <IconButton
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                <Iconify
                                                    icon={showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                                                    sx={{ color: "white" }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                sx={{ width: "90%" }}
                            />
                        </Grid>
                        <Box sx={{ float: "right", my: 2, marginRight: "20px" }}>
                            <Button
                                variant="contained"
                                className="click-to-begin m-l cancel-as-well edit-myaccount-button"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <LoadingButton loading={loader}
                                loadingIndicator={<CircularProgress size={12} sx={{ marginRight: "85px", color: "white" }} />}
                                size="large"
                                type="submit"
                                onClick={() => changeUserPasswordAPI()}
                                variant="contained" className='edit-myaccount-button' >
                                <span>Confirm</span>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}