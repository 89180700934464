import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import { breadcrumbsClasses, Grid, Tab, Tabs, TextField, Typography, Link } from "@mui/material";
import { CustomTabPanel, a11yProps } from "./add-client/addVendor";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { useSnackbar } from "notistack";

export default function VendorUploadModal({
  handleClose,
  open,
  handleBulkSubmit,
  loader,
  handleIndividualSubmit,
  bulkSubmitLoader
}) {
  const [formData, setFormData] = useState({
    vendorDomainName: "",
    vendorDomain: [],
    selectedTab: 0,
    filename: "",
  });
  const [vendorStatus, setVendorStatus] = useState("Onboarding")
  const { enqueueSnackbar } = useSnackbar();

  const resetState = () => {
    console.log("resetState")
    setFormData({
      vendorDomainName: "",
      vendorDomain: [],
      selectedTab: 0,
      filename: "",
    })
  }
  const handleTabChange = (event, newValue) => {
    console.log("handleTabchange")
    setFormData((prev) => ({
      ...prev,
      vendorDomainName: "",
      vendorDomain: [],
      selectedTab: newValue,
      filename: "",
    }));
  };

  const handleInputChange = (e) => {
    console.log("handleInputChange")
    setFormData((prev) => ({
      ...prev,
      vendorDomainName: e.target.value,
      vendorDomain: [e.target.value],
    }));
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const fileName = file.name;
    const fileType = file.type;

    if (
      fileType !== "text/csv" &&
      fileType !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      enqueueSnackbar("Only CSV and XLSX files are supported.", {
        variant: "warning",
      });
      return;
    }

    if (fileType === "text/csv") {
      handleCSVFile(file, fileName);
    } else if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      handleXLSXFile(file, fileName);
    }
    event.target.value = null;
  };

  const isValidDomain = (domain) => {
    const domainRegex =
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
    return domainRegex.test(domain);
  };

  const handleCSVFile = (file, fileName) => {
    console.log("inside csv")
    Papa.parse(file, {
      download: true,
      header: false,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        console.log("results", results)
        const validRows = results.data.filter((row, i) => i !== 0);
        const flattenedRows = validRows.map((row) => ({ domain: row[0], vendorStatus: row[1] }))?.filter(item => item.domain && item.vendorStatus);
        console.log(flattenedRows, "flatted")
        if (!flattenedRows.length) {
          enqueueSnackbar(`Invalid file`, {
            variant: "error",
          });
          return;
        }
        let invalid = false;
        if (flattenedRows && Array.isArray(flattenedRows)) {
          for (let [index, item] of Object.entries(flattenedRows)) {
            if (!isValidDomain(item.domain)) {
              enqueueSnackbar(`Invalid domain at row: ${Number(index) + 2}`, {
                variant: "error",
              });
              invalid = true;
              break;
            } else if (!["New", "Existing"].includes(item.vendorStatus)) {
              enqueueSnackbar(`Status should be New/Existing at row: ${Number(index) + 2}`, {
                variant: "error",
              });
              invalid = true;
              break;
            }
          }
        }
        if (invalid) {
          setFormData({ ...formData, vendorDomain: [], filename: "" })
        } else {
          setFormData((prev) => ({
            ...prev,
            vendorDomain: flattenedRows,
            filename: fileName,
          }));
        }
      },
    });
  };

  const handleXLSXFile = (file, fileName) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const validRows = rows.filter((row, i) => row.length === 2 && i !== 0);
      const flattenedRows = validRows.map((row) => ({ domain: row[0], vendorStatus: row[1] }));
      if (!flattenedRows.length) {
        enqueueSnackbar(`Invalid file`, {
          variant: "error",
        });
        return;
      }
      let invalid = false;
      if (flattenedRows && Array.isArray(flattenedRows)) {
        for (let [index, item] of Object.entries(flattenedRows)) {
          if (!isValidDomain(item.domain)) {
            enqueueSnackbar(`Invalid domain at row: ${Number(index) + 2}`, {
              variant: "error",
            });
            invalid = true;
            break;
          } else if (!["New", "Existing"].includes(item.vendorStatus)) {
            enqueueSnackbar(`Status should be New/Existing at row: ${Number(index) + 2}`, {
              variant: "error",
            });
            invalid = true;
            break;
          }
        }
      }
      if (invalid) {
        setFormData({ ...formData, vendorDomain: [], filename: "" })
      } else {
        setFormData((prev) => ({
          ...prev,
          vendorDomain: flattenedRows,
          filename: fileName,
        }));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#120A3A",
    boxShadow: 24,
    textAlign: "center",
    borderRadius: "15px",
    p: 4,
  };

  const handleNext = async () => {
    if (formData.selectedTab == 1) {
      console.log(formData.vendorDomain)
      if (!formData.vendorDomain || !formData.vendorDomain?.length) return null;
      let submit = await handleBulkSubmit(formData.vendorDomain)
      if (submit) setFormData({
        vendorDomainName: "",
        vendorDomain: [],
        selectedTab: 0,
        filename: "",
      })
    } else {
      if (!new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(formData.vendorDomainName)) {
        enqueueSnackbar(`Invalid domain`, {
          variant: "error",
        });
        return;
      }
      handleIndividualSubmit({ domain: formData.vendorDomainName, vendorStatus: vendorStatus })
    }
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={() => { resetState(); handleClose() }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Box className="add-vendor-tabs">
                <Tabs
                  value={formData.selectedTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Individual" {...a11yProps(0)} />
                  <Tab label="Bulk" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <CustomTabPanel value={formData.selectedTab} index={0}>
                <div className='flex-uploadbtn-flex margin-domain-spaces top-reduces' >
                  <div className='radio-type-design' onChange={(e) => setVendorStatus(e.target.value)}>
                    <input type="radio" id="test1" value="Onboarding" name="radio-group" checked={vendorStatus === "Onboarding"} />
                    <label for="test1">New Vendor</label>
                  </div>
                  <div className='radio-type-design' onChange={(e) => setVendorStatus(e.target.value)}>
                    <input type="radio" id="test2" value="Monitoring" name="radio-group" checked={vendorStatus === "Monitoring"} />
                    <label for="test2">Existing Vendor</label>
                  </div>
                </div>
                <TextField
                  size="large"
                  placeholder="Enter Domain Here..."
                  name="vendorDomainName"
                  value={formData.vendorDomainName}
                  onChange={handleInputChange}
                  sx={{ marginTop: "20px" }}
                />
                <div>
                  <h4 className="text-add-vendo-popup-valid">
                    Please Enter the valid domain of an individual vendor to
                    connect with the Client. If you want to connect with
                    multiple vendors, click on "Bulk" to upload a CSV file.
                  </h4>
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={formData.selectedTab} index={1}>
                <Box
                  sx={{
                    border: "2px dashed #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloudUploadIcon fontSize="large" />
                  <Typography variant="h6">
                    Please choose a CSV file containing the list of
                    domains
                  </Typography>
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload">
                    <Button variant="contained" component="span" sx={{ mt: 2 }}>
                      Choose File
                    </Button>
                  </label>
                  {formData?.filename && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      {formData?.filename}
                    </Typography>
                  )}
                  <br />
                  <br />
                  <Link
                    href={`${process.env.REACT_APP_BACKEND_BASE_URL}/assets/demo-domains.xlsx`}
                    download="demo-domains.xlsx"
                    underline="none"
                  >
                    Download demo file
                  </Link>
                </Box>

              </CustomTabPanel>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12} mt={2}>
            <div style={{display:"flex", alignItems:"center", gap:"23px", justifyContent:"center"}}>
            <Button
              variant="contained"
              className="upload-new m-auto-align-center"
              onClick={() => { resetState(); handleClose() }}
            >
              Cancel
            </Button>
            <Button
              disabled={bulkSubmitLoader}
              variant="contained"
              className="upload-new m-auto-align-center"
              onClick={() => handleNext()}
            >
              {formData.selectedTab == 1 ? "Submit" : "Next"}
            </Button>
            </div>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
