import React, { useState, useEffect } from 'react';
import { Box, Modal, Card, Button } from "@mui/material";
import HeaderIcon from '../assets/search-icons.svg';
import SparkleIcon from '../assets/Sparkle.png';
import AIChat from '../assets/ai-chat-icon.svg';
import GenesisAI from '../assets/genesis-logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import Markdown from 'marked-react';
import GetRequest from '../components/apiConnections/getRequest';
import './css/globalSearchBar.css';
// import CircularProgress from '@handleIconClickmui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from 'react-router-dom';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "#120A3A",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    height: "80vh",
    overflowY: "auto",
};

export default function GlobalSearchData({ data, isFullPage }) {
    // console.log("isFullPage", data)
    return (
        <>
            {(data)&& <Markdown value={data} />}
        </>
    );
}
