import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import "./css/Vendors.css";
// @mui
import {
  Card,
  Popover,
  MenuItem,
  Container,
  FormControl,
  Select,
  Box,
  Switch,Grid,
} from "@mui/material";
import * as React from "react";
import Iconify from "../components/iconify";
import PostRequest from "../components/apiConnections/postRequest";
import { useSnackbar } from "notistack";
import NewHeader from "../layouts/dashboard/header/NewHeader";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "./Assessment.js"
import { useNavigate } from "react-router-dom";
import moment from "moment";
import defaultLogo from '../assets/companyLogo.jpg';
// ----------------------------------------------------------------------
export default function RequestsPage() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(null);
  const [vendorsData, setVendorsData] = useState([]);
  const [loader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  useEffect(() => {
    getRequestsforVendors();
  }, []);

  useEffect(() => {
  }, [vendorsData]);

  const getRequestsforVendors = async () => {
    setshowLoader(true);
    const endpoint = process.env.REACT_APP_GET_ALL_ATTACK_REQUESTS;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            arrResponse.sort((a, b) =>
              a.requestDate > b.requestDate ? -1 : 1
            );
            setVendorsData(arrResponse);
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }
  };
  const changeAttackRequestStatus = async (event, param1, param2, param3) => {
    setshowLoader(true);
  try {
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );

    if (refreshToken) {
      let endPoint = "attack-request-status-update";
      const payload = {
        requestedUsername: param1,
        requestAttackUrl: param2,
        status: param3,
      };

      const uploadJson = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
        payload
      );
      if (uploadJson && uploadJson.status === 200) {
        if (uploadJson.data.code === 200) {
         await getRequestsforVendors();
          enqueueSnackbar("Successfully Status Changed.", {
            variant: "success",
          });
          setshowLoader(false);
        } else {
          enqueueSnackbar("Error in request.", { variant: "error" });
          setshowLoader(false);
        }
      } else {
        enqueueSnackbar("Error in request.", { variant: "error" });
        setshowLoader(false);
      }
    }
  } catch (e) {
    enqueueSnackbar("Error in request.", { variant: "error" });
    setshowLoader(false);
  }
};

  const filteredRows =
    (!vendorsData || !vendorsData.length) ? [] :
      vendorsData
        ?.map((val, i) => ({
          ...val,
          id: i + 1,
          status: val?.status === 0 ? "Awaiting" : "Fulfilled",
        })
        );

  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.5,
      className: "serial-number",
      disableColumnMenu: true,
    },
    {
      field: "requestAttackUrl",
      headerName: "URL",
      flex: 1.5,
      minWidth: 150,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <span className="logo-image-vendor">
            <img className="attack-surface-list-logo" src={params.row?.logo ? params.row?.logo :
              `${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${params.row.target}`} alt="Figma" onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultLogo;
              }} />
          </span>
          <span style={{ display: 'flex', gap: '6px' }}>
            {params.value}
          </span>
        </div>
      ),
    },
    {
      field: "username",
      headerName: "Requested BY",
      flex: 1.5,
      minWidth: 125,
      disableColumnMenu: true,
    },
    {
      field: "requestDate",
      headerName: "Date Time",
      flex: 1.5,
      minWidth: 140,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return moment(new Date(params.value * 1000)).format("MMMM Do YYYY, h:mm:ss a")
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 130,
      disableColumnMenu: true,
      renderCell: (params) => {
        let status = params.value;
        return (status === "Awaiting" ?
          <span className={`statusClass Deboarding`}>{status}</span>
          :
          <span className={`statusClass Onboarding`}>{status}</span>
        );
      },
    },
    {
      field: "action",
      headerName: "Active",
      flex: 1.1,
      align: "right",
      headerAlign: "right",
      minWidth: 50,
      renderCell: (params) => {
        return <Switch
          checked={params.row?.userFileStatus === 0 ? false : true}
          onChange={(event) =>
            changeAttackRequestStatus(
              event,
              params.row?.username,
              params.row?.requestAttackUrl,
              params.row?.userFileStatus === 0 ? 1 : 0
            )
          }
        />
      }
    }
  ];
  return (
    <>
      <Helmet>
        <title> Requests | Genesis Platform</title>
      </Helmet>
 
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="All Attack Requests " />
         <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
        {/* <div style={{
              fontFamily: "Mona-Sans, sans-serif",
              fontSize: "22px",
              fontWeight: "600",
              lineHeight: "21.27px",
              textAlign: "left",
              paddingTop: "6px",
              paddingBottom: "10px",
              marginBottom: "11px",
              marginLeft:"2%"
        }}>
          All vendors requests
          </div> */}
        <Card className="full-coverdesign margin-questionnaire">
          <div className="cover-table">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                top: "4px",
                left: "5px",
              }}
            >
              <FormControl
                className="select-entries"
                variant="outlined"
                size="small"
              >
                <Select
                  value={paginationModel.pageSize}
                  onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <span className="entries-per">entries per page</span>{" "}
            </div>
            <div className="issues-tab-table">
              <Box
                sx={{
                  width: "100%",
                  "& .MuiDataGrid-columnHeaders": {
                    minWidth: "100%",
                    overflowX: "hidden",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minWidth: "100%",
                    overflowX: "auto",
                  },
                }}
              >

                <DataGrid
                  loading={loader}
                  rows={filteredRows}
                  columns={columns}
                  rowHeight={70}
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  components={{
                    NoRowsOverlay: () => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        No data
                      </Box>
                    ),
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [],
                      },
                    },
                  }}
                  autosizeOptions={{
                    columns: ["id", "firstName", "lastName"],
                    includeOutliers: true,
                    includeHeaders: false,
                  }}
                  pageSizeOptions={[5, 10, 25, 50]}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                  paginationMode="client"
                  disableSelectionOnClick
                  autoHeight
                  className="datagrid-table"
                  sx={{
                    "& .MuiDataGrid-columnHeaders": {
                      minWidth: "100%", // Column headers take up full width
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      minWidth: "100%", // Rows take up full width
                    },
                  }}
                />
              </Box>
            </div>
          </div>
        </Card>
        {/* <Card className="full-coverdesign">
          <div className="cover-table">
            <h3 className="vendor-head">All vendors requests</h3>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table checkboxSelection={false} className="table-vendor">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          _id,
                          username,
                          requestAttackUrl,
                          requestDate,
                          status,
                          userFileStatus
                        } = row;
                        const selectedUser =
                          selected.indexOf(requestAttackUrl) !== -1;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            selected={selectedUser}
                          >
                          
                            <TableCell component="th" scope="row" align="center" >
    
                                <Typography variant="subtitle2" noWrap>
                                  {requestAttackUrl}
                                </Typography>

                            </TableCell>

                            <TableCell align="center">{username}</TableCell>

                            <TableCell align="center">
                              {moment(new Date(requestDate * 1000)).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Label color={status === 0 ? "error" : "success"}>
                                {status === 0 ? "Awaiting" : "Fulfilled"}
                              </Label>
                            </TableCell>

                            <TableCell align="center">
                              <Switch
                                checked={userFileStatus === 0 ? false : true}
                                onChange={(event) =>
                                  changeAttackRequestStatus(
                                    event,
                                    username,
                                    requestAttackUrl,
                                    userFileStatus === 0 ? 1 : 0
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete
                              words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={vendorsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Card> */}
        </Grid>
        </Grid>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Remove
        </MenuItem>
      </Popover>
    </>
  );
}
