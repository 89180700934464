import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import UserProfile from '../../assets/user-profile.svg';
import EmailIcon from "../../assets/email-icon.svg";
import CloseIcon from '@mui/icons-material/Close';

export default function ClientListModal({
    handleClose,
    open,
    clients,
    onSubmit,
    loader
}) {
    const [theme, setTheme] = useState(localStorage.getItem("theme"));
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        boxShadow: 24,
        textAlign: "center",
        borderRadius: "15px",
        p: 4,
    };
    useEffect(() => {
        setInterval(() => {
            const themeName =
                localStorage.getItem("theme") !== null
                    ? localStorage.getItem("theme")
                    : "Dark";
            setTheme(themeName);
        }, 2000);
    }, []);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme === "Dark" ? "dark-modal modal-popup bgmodalpop" : "light-modal modal-popup"
                }
            >
                <Box sx={style}>
                    <Button onClick={handleClose} sx={{ float: "right", color: "white",minHeight:"50px",minWidth:"50px" }}>
                        <CloseIcon />
                    </Button>
                    <Box className="notification-client-list">
                        {clients && Array.isArray(clients) ? clients.map((item, i) => (
                            <div className="client">
                                <Grid container spacing={0.5}  >
                                    <Grid item xs={3} sm={3} md={3} lg={3} className="profile-pic" >
                                        <img src={
                                            process.env.REACT_APP_BACKEND_BASE_URL +
                                            process.env.REACT_APP_STATIC_PATH +                                            
                                            item.profilePic}
                                        onError={(e) => e.target.src = UserProfile}
                                        />
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} lg={9}>
                                        <Box className="client-name" >
                                            {item.firstName} {item.lastName}
                                        </Box>
                                        <Box className="client-email" sx={{}}>
                                            <img src={EmailIcon} /> {item.emailId}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        )) : null}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
